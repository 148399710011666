import React, { useEffect, useState } from 'react';
import './css/PurchaseHistoryDialogStyles.scss';
import Dialog from "@material-ui/core/Dialog/Dialog";
import useTranslations from "../../../../translations/useTranslations";
import { DialogActions, Fade } from "@material-ui/core";
import { Clear, Info } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { LightTooltip } from 'component/LightTooltip';
import { formatDateWithMonthShort } from 'utils/AuxiliarFunctions';
import { TASA_PESO } from "../../../../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export const PurchaseHistoryDialog = (props) => {
  const data = props.data;
  const offers = data.offersList;
  const talonEffect = data.talonEffectsById || [];
  const translation = useTranslations();
  const [fullScreenSize, setFullScreenSize] = useState(false);

  useEffect(() => {
    handleFormAddAddressFullScreen();
    window.addEventListener('resize', handleFormAddAddressFullScreen);
    // eslint-disable-next-line
  }, []);

  const handleFormAddAddressFullScreen = () => {
    const width = window.innerWidth;
    const fullScreen = width < 768;
    setFullScreenSize(fullScreen);
  };

  const closeDialog = () => {
    props.hide();
  };

  // const resendOrder = () => {
  //   props.resend();
  // };

  return (
    <Dialog fullScreen={fullScreenSize} fullWidth={true} maxWidth="xs" TransitionComponent={Transition}
      scroll="body" open={props.show} onClose={closeDialog} className="purchase-history-dialog">
      <DialogTitle className="phd-header">
        <div className="phd-logo">
          <img src={"./images/picture/logo/mercadovip.png"} alt="Mercado Vip" />
          <button className="phd-close" onClick={closeDialog}>
            <Clear component="svg" />
          </button>
        </div>
        <div className="phd-title">
          {translation.PurchaseHistory.fullOrderDialogTitle}
          <div className="phd-divider-white" />
        </div>
      </DialogTitle>
      <div className="phd-order-type-icon">
        <img src={"./images/icons/orderType/delivery.png"} alt="Delivery" />
      </div>
      <DialogContent className="phd-body">
        <div className="phd-rest-info">
          <h3>{data.restaurantName}</h3>
          <p>
            {/* {data.status && data.status.id === 3 ? translation.PurchaseHistory.delivered : translation.PurchaseHistory.created} */}
            {data.status && data.status.id === 3 ? translation.PurchaseHistory.delivered : translation.PurchaseHistory.deliveryDate}
            {`: ${formatDateWithMonthShort(data.deliveryDateReal)}`}
          </p>
        </div>
        <h2 className="phd-total-price">
          {translation.PurchaseHistory.total}
          <span>{data.paymentType && data.paymentType.id === 6 ?
            data.totalCost ? (data.totalCost * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
            data.totalCost ? "$" + data.totalCost.toFixed(2) : "$" + (0).toFixed(2)}</span>
        </h2>
        <div className="phd-offers">
          <ul>
            {offers && offers.length > 0 ? offers.map((item, k) => {
              return (
                <li key={k}>
                  <span className="phd-offer-quantity">{item.count}</span>
                  <div className="phd-offer-detail">
                    <p className="main">{item.offer.name}</p>
                    {item.offer.variants && item.offer.variants.length > 0 ?
                      <p className="secondary">
                        ({item.offer.variants.map(({ nameEs }) => `${nameEs}`).join(", ")})
                      </p> : null}
                    {item.offer.offerAdds && item.offer.offerAdds.length > 0 ?
                      <p className="tertiary">
                        ({item.offer.offerAdds.map(({ name }) => `${name}`).join(", ")})
                      </p> : null}
                  </div>
                  <p className="phd-offer-price">
                    {data.paymentType && data.paymentType.id === 6 ?
                      item.offer.price ? (item.offer.price * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
                      item.offer.price ? "$" + item.offer.price.toFixed(2) : "$" + (0).toFixed(2)}
                  </p>
                </li>
              )
            }) : null}
          </ul>
        </div>
        <div className="phd-line" />
        <div className="phd-cost-resume">
          <div className="item">
            <p>{translation.Cart.subtotal}</p>
            <p>{data.paymentType && data.paymentType.id === 6 ?
              data.subtotal ? (data.subtotal * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
              data.subtotal ? "$" + data.subtotal.toFixed(2) : "$" + (0).toFixed(2)}
            </p>
          </div>
          <div className="item">
            <p>{translation.Cart.shipping}</p>
            <p>{data.paymentType && data.paymentType.id === 6 ?
              data.shipping ? (data.shipping * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
              data.shipping ? "$" + data.shipping.toFixed(2) : "$" + (0).toFixed(2)}
            </p>
          </div>
          <div className="item">
            <p style={{ display: 'flex' }}>
              {translation.Cart.serviceTax}
              <LightTooltip title={translation.Cart.serviceTaxInfo} placement="bottom">
                <Info style={{ fontSize: 20, margin: '1px 0 0 5px' }} />
              </LightTooltip>
            </p>
            <p>{data.paymentType && data.paymentType.id === 6 ?
              data.taxes ? (data.taxes * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
              data.taxes ? "$" + data.taxes.toFixed(2) : "$" + (0).toFixed(2)}
            </p>
          </div>

          {talonEffect.map((effect, k) => {
            let TALON_ROW = null;
            if (effect.talonFunctionId === 1) {
              TALON_ROW = (
                <div className="item" key={k}>
                  <p>{effect.name ? `${effect.name}` : `${translation.Payment.bonus}`}</p>
                  <p style={{ color: '#00d200' }}>
                    {data.paymentType && data.paymentType.id === 6 ?
                      effect.value ? (effect.value * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
                      effect.value ? "$" + effect.value.toFixed(2) : "$" + (0).toFixed(2)}
                  </p>
                </div>
              )
            }
            return TALON_ROW;
          }
          )}

          <div className="item strong">
            <p>{translation.Cart.total}</p>
            <p>{data.paymentType && data.paymentType.id === 6 ?
              data.totalCost ? (data.totalCost * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
              data.totalCost ? "$" + data.totalCost.toFixed(2) : "$" + (0).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="phd-line" />
        {data.paymentType && data.paymentType.id ?
          <div className="phd-pay-method">
            <span className="strong">{translation.PurchaseHistory.orderPayType}</span>
            <span className="light">{data.paymentType.name}</span>
          </div> : null}
      </DialogContent>
      {/*{data.paymentType && data.paymentType.id ? <div className="phd-line"/> : null}*/}
      <DialogActions className="phd-footer">
        {/*<button className="button--primary" onClick={() => resendOrder()}>*/}
        {/*    {translation.PurchaseHistory.resendOrder}*/}
        {/*</button>*/}
        <button className="button--primary button--large" onClick={() => closeDialog()}>
          {translation.Actions.close}
        </button>
      </DialogActions>
    </Dialog>
  )
};