/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-elastic-carousel";

import './css/PromotionsCarouselStyles.scss';

import useTranslations from "translations/useTranslations";

import { selectFilters } from 'store/filter/filter.reducers';
import { loadGeneralPromotions } from "./store/promotions-carousel.actions";
import { GeneralPromotionsList } from "./store/promotions-carousel.reducers";


const PromotionsCarousel = () => {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const filters = useSelector(selectFilters);
  const promotionsSelector = useSelector(GeneralPromotionsList);
  const [promotions, setPromotions] = useState([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState(null);

  useEffect(() => {
    if (promotionsSelector.length > 0) {
      setPromotions(promotionsSelector);
    } else {
      setPromotions([]);
    }
  }, [promotionsSelector]);

  useEffect(() => {
    const { provinceid } = filters;
    if (selectedProvinceId !== provinceid) {
      dispatch(loadGeneralPromotions(provinceid));
      setSelectedProvinceId(provinceid);
    }
  }, [filters]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 768, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1024, itemsToShow: 3, itemsToScroll: 3 }
  ];

  const redirectTo = (URL) => {
    window.location = URL;
  };

  let interval = null
  const msSpeed = 5000
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element) {
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
        })
      )
    );
  }

  useEffect(() => {
    return () => {
      clearInterval(interval)
    };
  });

  return (
    promotions && promotions.length > 0 ?
      <div className="promotion-carousel">
        {promotions.length === 1 ?
          promotions.map((data, index) => (
            <div key={index} onClick={() => redirectTo(data.url)} className="promotion-item">
              {data.imgLg ? <img src={data.imgLg} alt={data.name} className="promotion-image" />
                : <img src={"./images/picture/no-image.png"} alt={data.name} className="no-image" />}
              <div className="promotion-info">
                <div className="promotion-caption">
                  <h4 className="promotion-title">{data.title}</h4>
                  <p className="promotion-description">{data.description}</p>
                </div>
                <span className="promotion-button">
                  {data.labelButton ? data.labelButton : translation.General.seeMore}
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </span>
              </div>
            </div>
          ))
          :
          <Carousel breakPoints={breakPoints} showArrows={false} enableAutoPlay={true} autoPlaySpeed={msSpeed} enableSwipe={true} enableMouseSwipe={true} preventDefaultTouchmoveEvent={true}
            onChange={(currentItemObject, currentPageIndex) => {
              return (currentPageIndex + 1) === (promotions.length) ?
                interval = setInterval(() => {
                  simulateMouseClick(document.querySelector('.rec-dot'))
                }, msSpeed)
                :
                clearInterval(interval)
            }}
                /*onResize={(currentBreakPoint) => {
                  console.log(currentBreakPoint)
                }}*/>
            {promotions.map((data, index) => (
              <div key={index} onClick={() => redirectTo(data.url)} className="promotion-item">
                {data.imgLg ? <img src={data.imgLg} alt={data.name} className="promotion-image" />
                  : <img src={"./images/picture/no-image.png"} alt={data.name} className="no-image" />}
                <div className="promotion-info">
                  <div className="promotion-caption">
                    <h4 className="promotion-title">{data.title}</h4>
                    <p className="promotion-description">{data.description}</p>
                  </div>
                  <span className="promotion-button">
                    {data.labelButton ? data.labelButton : translation.General.seeMore}
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </span>
                </div>
              </div>
            ))}
          </Carousel>
        }
      </div>
      : null
  )
};

export default PromotionsCarousel;