import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { simpleGet, simplePost } from "core/services";
import { notifyError } from "modules/notification/store/notification.actions";
import { browserHistory } from "../../../../utils/History";
import { doFetchClientToken } from "../braintree/braintree.actions";

export const CLEAR_CLL2_PAYMENTS_METHODS = "CLEAR_CLL2_PAYMENTS_METHODS";
export const FETCH_CLL2_PAYMENT_METHODS = "FETCH_CLL2_PAYMENT_METHODS";
export const FETCHED_CLL2_PAYMENT_METHODS = "FETCHED_CLL2_PAYMENT_METHODS";
export const DONE_CLL2_PAYMENT = "DONE_CLL2_PAYMENT";
export const SET_CLL2_ERROR_ADD_PAYMENT_METHOD =
  "SET_CLL2_ERROR_ADD_PAYMENT_METHOD";
export const CLEAR_CLL2_ERROR_ADD_PAYMENT_METHOD =
  "CLEAR_CLL2_ERROR_ADD_PAYMENT_METHOD";
export const CLEAR_CLL2_PAYMENTS = "CLEAR_CLL2_PAYMENTS";
export const FETCHED_CLL2_CLIENT_TOKEN = "FETCHED_CLL2_CLIENT_TOKEN";

const baseUrl = "payment/cll2";
const history = browserHistory;

function doneFetchCll2ClientToken(clientToken) {
  return {
    type: FETCHED_CLL2_CLIENT_TOKEN,
    clientToken,
  };
}

function clearCll2PaymentsMethods() {
  return {
    type: CLEAR_CLL2_PAYMENTS_METHODS,
  };
}

function doneFetchCll2PaymentMethods(paymentMethods) {
  return {
    type: FETCHED_CLL2_PAYMENT_METHODS,
    paymentMethods,
  };
}

function doneCll2Payment(data) {
  return {
    type: DONE_CLL2_PAYMENT,
    data,
  };
}

function setCLL2ErrorAddPaymentMethod(err) {
  return {
    type: SET_CLL2_ERROR_ADD_PAYMENT_METHOD,
    err,
  };
}

export function clearErrorAddPaymentMethod() {
  return { type: CLEAR_CLL2_ERROR_ADD_PAYMENT_METHOD };
}

export function clearCll2Payments() {
  return { type: CLEAR_CLL2_PAYMENTS };
}

export function fetchCll2PaymentMethods(cb = null) {
  return async (dispatch) => {
    try {
      const paymentMethods = await simpleGet(
        `${baseUrl}/customer_payment_methods`
      ).then((resp) => resp.data);
      dispatch(doneFetchCll2PaymentMethods(paymentMethods));
      if (cb) {
        cb(paymentMethods);
      }
    } catch (error) {
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
    }
  };
}

export function doFetchCll2ClientToken() {
  return async (dispatch) => {
    try {
      const { data } = await simpleGet(`${baseUrl}/client-token`);
      dispatch(doneFetchCll2ClientToken(data));
    } catch (error) {
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
    }
  };
}

export function chargeCll2Payment(
  gateway,
  amount,
  paymentMethodId,
  nonce,
  paymentRequest,
  newPaymentMethod = null
) {
  return async (dispatch) => {
    let newCard = null;
    // is a new payment method added directly from dialog card
    if (newPaymentMethod && !paymentMethodId) {
      const { number, expirationDate, cvv, zip } = newPaymentMethod;
      newCard = {
        accountId: "",
        card: {
          expirationMonth: expirationDate.split("/")[0],
          expirationYear: `20${expirationDate.split("/")[1]}`,
          number: number.replaceAll(" ", ""),
          cvv,
          postalCode: zip,
        },
      };
    }
    const body = newCard
      ? { ...paymentRequest, newCard }
      : { ...paymentRequest, paymentMethodId, nonce };
    try {
      const response = await simplePost(`${baseUrl}/charge?amount=${amount}`, {
        ...body,
        gateway,
      });
      await dispatch(doneCll2Payment(response));
      history.push({ pathname: "/prv/pay_success" });
      dispatch(setLoadingMaskStatus(false));
    } catch ({ response }) {
      const { status, data } = response;
      if (status === 422) {
        // Bambora error, try Braintree
        dispatch(doFetchClientToken());
        history.push({
          pathname: "/prv/3ds",
          state: {
            paymentMethodId,
            nonce: data,
            amount,
            paymentRequest,
          },
        });
      } else {
        if (data) {
          const message = typeof data === "string" ? data : data?.message;
          // ADD CARD ERROR
          if (message?.startsWith("CARD ERROR:")) {
            dispatch(setLoadingMaskStatus(false));
            dispatch(
              setCLL2ErrorAddPaymentMethod(
                message.replaceAll("CARD ERROR:", "") ||
                  "Ha ocurrido un error al tratar de añadir su tarjeta"
              )
            );
          } else {
            // PAYMENT ERROR
            history.push({
              pathname: "/prv/pay_error",
              state: {
                error:
                  message.replaceAll("PAYMENT ERROR:", "") ||
                  "TRANSACTION_NOT_ALLOWED",
              },
            });
            dispatch(setLoadingMaskStatus(false));
          }
        }
      }
    }
  };
}

export function addCll2NewPaymentMethod(
  { number, expirationDate, cvv, zip },
  cb
) {
  return async (dispatch) => {
    try {
      const card = await simplePost(`${baseUrl}/add_customer_payment_methods`, {
        accountId: "",
        card: {
          expirationMonth: expirationDate.split("/")[0],
          expirationYear: `20${expirationDate.split("/")[1]}`,
          number: number.replaceAll(" ", ""),
          cvv,
          postalCode: zip,
        },
      }).then(({ data }) => data);
      cb(card);
    } catch (error) {
      let err =
        error.response && error.response.data
          ? error.response.data
          : error.response;

      dispatch(
        setCLL2ErrorAddPaymentMethod(
          err || "Ha ocurrido un error al tratar de añadir su tarjeta"
        )
      );
    }
  };
}

export function doDeleteCll2PaymentMethod(id) {
  return async (dispatch) => {
    dispatch(clearCll2PaymentsMethods());
    try {
      await simplePost(`${baseUrl}/delete_customer_payment_methods`, { id });
      dispatch(fetchCll2PaymentMethods());
    } catch (error) {
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
    }
  };
}
