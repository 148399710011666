import React, { useEffect, useState } from "react";
import CustomInput from "../../component/CustomInput/CustomInput";
import CustomInputPasswordStrengthMeter
  from "../../component/CustomInputPasswordStrengthMeter/CustomInputPasswordStrengthMeter";
import "./css/RegisterStyles.scss";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/styles";
import { doVerifyCode, backToValidatePhone, setForm, doValidatePhone } from "./store/register.actions";
import { selectVerifyError, selectForm } from "./store/register.reducers";
import { InputAdornment, Button, Paper, Typography, Grid } from "@material-ui/core";
import CustomInputTextMask from "../../component/CustomInputTextMask/CustomInputTextMask";
import { validateFieldByArray } from "../../utils/AuxiliarFunctions";
import * as _ from "lodash";

const colorBoringGreen = "#2c2c2c";

const useStyles = makeStyles(() => ({
  textField: {
    flexBasis: 50,
    width: "100%",
    border: "none",
    margin: "none"
  },
  input: {
    fontSize: ".8em",
    backgroundColor: "#fff !important"
  },
  helper: {
    fontSize: ".8em",
    lineHeight: ".8",
    fontStyle: "italic"
  },
  button: {
    text: {
      fontSize: ".8em"
    },
    color: colorBoringGreen
  }
}));

const InputAdornmentButton = withStyles({
  root: {
    fontSize: ".7em",
    textTransform: "none",
    color: colorBoringGreen,
    fontWeight: 400
  }
})(Button);

export default function VerifyCode({ translation }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const verifyError = useSelector(selectVerifyError);
  const { phone, email, pin, password } = useSelector(selectForm);
  const [errors, setErrors] = useState({});
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    validateOfferForm();
    // eslint-disable-next-line
  }, [phone, email, pin, password]);

  const handleOnChange = (field, value) => dispatch(setForm({ [field]: value }));

  const handleCreateAccount = () => {
    let data = {
      appName: "DELIVERY",
      channel: "ANDROID",
      deviceId: "0008cccc-eeeeeeee-vvvvvvvv-gggggggg",
      username: phone,
      email,
      password,
      pin,
      verify: true
    };
    dispatch(doVerifyCode(data));
  };

  const handleEditPhone = () => dispatch(backToValidatePhone());

  const resendSmsCode = () => {
    let data = {
      appName: "DELIVERY ",
      channel: "ANDROID",
      deviceId: "0008cccc-eeeeeeee-vvvvvvvv-gggggggg",
      typeOfPinShipping: "SMS",
      username: phone,
      verify: false,
    };
    dispatch(doValidatePhone(data));
  };

  const getCodeByCall = () => {
    let data = {
      appName: "DELIVERY ",
      channel: "ANDROID",
      deviceId: "0008cccc-eeeeeeee-vvvvvvvv-gggggggg",
      typeOfPinShipping: "CALL",
      username: phone,
      verify: false,
    };
    dispatch(doValidatePhone(data));
  };

  const validateOfferForm = () => {
    let errors = {};
    let pinValidations = validateFieldByArray(pin, ["required"], translation.Validations["required"]);
    if (pinValidations) {
      errors.pin = pinValidations;
    }
    let passwordValidations = validateFieldByArray(password, ["required"], translation.Validations["required"]);
    if (passwordValidations) {
      errors.password = passwordValidations;
    }
    let emailValidations = validateFieldByArray(email, ["required", "email"], translation.Validations["required"]);
    if (emailValidations) {
      errors.email = emailValidations;
    }
    let valid = _.isEmpty(errors);
    setErrors(errors);
    setValidForm(valid);
  };

  return (
    <div>
      <Paper className="form">
        <Typography className="title" component={"p"}>
          {translation.Register.createCuballamaAccount}
        </Typography>
        <h2>
          <div className="divider" />
        </h2>
        <Typography className="subtitle" component={"p"}>
          {translation.Register.info2}
        </Typography>
        <div className="fields">
          <Grid container item md={9} sm={8} xs={12} spacing={3} component={"div"}>
            <Grid item xs={12} component={"div"}>
              <CustomInputTextMask
                required
                id="phone"
                fieldLabel={translation.Register.phoneField}
                value={phone}
                innerProps={{ readOnly: true, classes: { input: classes.input } }}
                endAdornment={
                  <InputAdornment position="end">
                    <InputAdornmentButton className={classes.button} onClick={() => handleEditPhone()}>
                      {translation.Register.modifyAction}
                    </InputAdornmentButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} component={"div"}>
              <CustomInput
                id="pin"
                fieldLabel={`${translation.Register.pinReceived}*`}
                value={pin}
                onChange={event => handleOnChange("pin", event.target.value)}
              />
              <span className="label-error">{errors["pin"]}</span>
            </Grid>
            <Grid item xs={12} component={"div"}>
              <CustomInputPasswordStrengthMeter
                id="password"
                fieldLabel={translation.Register.password}
                value={password}
                onChange={event => handleOnChange("password", event.target.value)}
              />
              <span className="label-error">{errors["password"]}</span>
            </Grid>
            <Grid item xs={12} component={"div"}>
              <CustomInput
                id="email"
                type="email"
                fieldLabel={translation.Register.email}
                value={email}
                onChange={event => handleOnChange("email", event.target.value)}
              />
              <span className="label-error">{errors["email"]}</span>
            </Grid>
            <Grid item xs={12} component={"div"}>
              {verifyError && verifyError.code ? (
                <span className="label-error">{verifyError.description}</span>
              ) : null}
            </Grid>
            <Grid container item xs={12} spacing={1} className="register-link" component={"div"}>
              <Typography component={"p"}>
                {translation.Register.conditionPart1}{"\u00A0"}
                <a href="https://www.cuballama.com/legal" style={{ color: "#428d44", cursor: "pointer" }}>
                  {translation.Register.conditionPart2}
                </a>
                {"\u00A0"}{translation.Register.conditionPart3}{"\u00A0"}
                <a href="https://www.cuballama.com/privacy-policy" style={{ color: "#428d44", cursor: "pointer" }}>
                  {translation.Register.conditionPart4}
                </a>
                {"\u00A0"}{translation.Register.conditionPart5}.
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={1} className="action" component={"div"}>
              <button
                onClick={handleCreateAccount}
                disabled={!validForm}
                className="button--primary button--max"
              >
                {translation.Register.registerAction}
              </button>
            </Grid>
            <Grid container item xs={12} spacing={1} className="register-link" component={"div"}>
              <Typography component={"p"}>{translation.Register.alreadyRegistered}</Typography>
              <a href={"./login"} style={{ color: "#428d44" }}>
                {translation.Login.loginAction}
              </a>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper className="resend_code_form">
        <Typography className="resend_code_title" component={"p"}>
          {translation.Register.resendCodeTitle}
        </Typography>
        <Grid container xs={12} component={"div"}>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code" onClick={resendSmsCode}>
              {translation.Register.receivePinAgain}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code" onClick={getCodeByCall}>
              {translation.Register.receivePinPerCall}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code">
              <a href="https://www.cuballama.com/informacion/contacto">
                {translation.Register.talkToAnAgent}
              </a>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}