import { Grid } from "@material-ui/core";
import React, { useState } from "react";

import * as moment from 'moment';

import { checkCreditCard } from 'utils/creditcard.js';
import CustomInputTextMask from "component/CustomInputTextMask/CustomInputTextMask";

import useTranslations from "translations";
import CustomInput from "component/CustomInput/CustomInput";

export default function UpsertCreditCard({ card = {}, handleSubmit, feedbackError, origen = "CARD_MANAGMENT" }) {
  const translation = useTranslations();
  const [cardInfo, setCardInfo] = useState({
    number: card ? card.number : '',
    cvv: card ? card.cvv : '',
    zip: card ? card.zip : '',
    expirationDate: card ? card.expirationDate : '',
    nonce: null
  });
  const [errors, setErrors] = useState({});

  const handleCreditCardValidation = (e) => {
    const number = e.target.value;
    // eslint-disable-next-line no-undef
    const result = checkCreditCard(number);
    setErrors({ ...errors, number: "" });
    if (result === true) {
      setCardInfo({ ...cardInfo, number })
    } else {
      setErrors({ ...errors, number: translateError(result) });
    }
  }

  const validateExpiration = (expirationDate) => {
    if (expirationDate && expirationDate.length === 5) {
      const [month, year] = expirationDate.split('/');
      try {
        const dateMoment = moment();
        const cvvMoment = moment(`20${year}-${month}`);
        return dateMoment.isBefore(cvvMoment);
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  const handleExpirationDateValidation = (e) => {
    const expirationDate = e.target.value;
    setErrors({ ...errors, expirationDate: "" });
    if (validateExpiration(expirationDate)) {
      setCardInfo({ ...cardInfo, expirationDate })
    } else {
      setErrors({ ...errors, expirationDate: translateError('no valid date') });
    }
  }

  const validateCVV = (cvv) => ((/^\d{3}|\d{4}$/).test(cvv));
  // const validateZip = (zip) => ((/^\d{5}$/).test(zip));

  const handleCVVValidation = (e) => {
    const cvv = e.target.value;
    setErrors({ ...errors, cvv: "" });
    if (validateCVV(cvv)) {
      setCardInfo({ ...cardInfo, cvv })
    } else {
      setErrors({ ...errors, cvv: translateError('no valid value') });
    }
  }

  // const handleZipValidation = (e) => {
  //   const zip = e.target.value;
  //   setErrors({ ...errors, zip: "" });
  //   if (validateZip(zip)) {
  //     setCardInfo({ ...cardInfo, zip })
  //   } else {
  //     setErrors({ ...errors, zip: translateError('no valid value') });
  //   }
  // }

  const validateForm = () => {
    const { number, cvv, expirationDate } = cardInfo;
    return checkCreditCard(number) !== true ||
      validateExpiration(expirationDate) !== true ||
      validateCVV(cvv) !== true;
  }

  const trySubmit = () => {
    const { number, cvv, expirationDate, zip } = cardInfo;
    if (!number || !cvv || !expirationDate || !zip) return;
    handleSubmit(cardInfo);
  };

  const translateError = (errorCode) => {
    let codes = errorCode.split('.').map(item => item.trim()).filter(item => item && item.length > 3);
    const errors = translation.Payment.errors;
    let translatedError = '';
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        let translation = Object.keys(errors).includes(`${code}.`) ? errors[`${code}.`] : code;
        translatedError = `${translatedError}${translatedError.length > 0 ? '.\t' : ''}${translation}`;
      });
    } else {
      let translation = Object.keys(errors).includes(errorCode) ? errors[errorCode] : errorCode;
      translatedError = `${translatedError}${translatedError.length > 0 ? '.\t' : ''}${translation}`;
    }
    return translatedError;
  }

  // <input id="ccn" type="tel" inputmode="numeric" pattern="[0-9\s]{13,19}" autocomplete="cc-number" maxlength="19" placeholder="xxxx xxxx xxxx xxxx"></input>
  return <Grid container spacing={2}>
    <Grid item md={12} sm={12} xs={12}>
      <CustomInputTextMask
        required
        id="number"
        innerProps={{
          // type: "tel",
          inputmode: "numeric",
          autoComplete: "cc-number",
          maxlength: "19",
          placeholder: "xxxx xxxx xxxx xxxx"
        }}
        fieldLabel={`${translation.Payment.creditCard}`}
        value={cardInfo.number}
        onChange={e => setCardInfo({ ...cardInfo, number: e.target.value })}
        onBlur={handleCreditCardValidation}
        mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
        guide={false}
        errorMessage={errors["number"]}
      />
    </Grid>
    <Grid item md={6} sm={6} xs={6}>
      <CustomInputTextMask
        required
        id="exp"
        fieldLabel={`${translation.Payment.expirationDate}`}
        value={cardInfo.expirationDate}
        onChange={e => setCardInfo({ ...cardInfo, expirationDate: e.target.value })}
        onBlur={handleExpirationDateValidation}
        mask={[/\d/, /\d/, "/", /\d/, /\d/]}
        guide={false}
        errorMessage={errors["expirationDate"]}
      />
    </Grid>
    <Grid item md={6} sm={6} xs={6}>
      <CustomInputTextMask
        required
        id="cvv"
        fieldLabel="CVV"
        value={cardInfo.cvv}
        onChange={e => setCardInfo({ ...cardInfo, cvv: e.target.value })}
        onBlur={handleCVVValidation}
        mask={[/\d/, /\d/, /\d/, /\d/]}
        guide={false}
        errorMessage={errors["cvv"]}
      />
    </Grid>
    <Grid item md={6} sm={6} xs={6}>
      <CustomInput
        required
        id="zip"
        fieldLabel="C&oacute;digo Postal"
        value={cardInfo.zip}
        onChange={e => setCardInfo({ ...cardInfo, zip: e.target.value })}
        // onBlur={handleZipValidation}
        guide={false}
        errorMessage={errors["zip"]}
      />
    </Grid>

    <label style={{ color: 'red', marginBottom: '1em' }}>
      {feedbackError ? translateError(feedbackError) : null}
    </label>

    <div style={{ borderBottom: "1px solid lightgray", width: "100%", margin: "0 5px 15px" }}></div>

    <button
      className="button--primary button--max button--plus"
      style={{ height: "54px", fontSize: "1.2em", marginBottom: "15px" }}
      disabled={validateForm()}
      onClick={trySubmit}
    >
      {origen === "PAY" ? translation.Payment.addCard : translation.CardManagement.buttonAddLabel}
    </button>

  </Grid>
};
