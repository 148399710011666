import React, { useContext } from "react";
import { languages, TranslationsContext } from "../../translations";
import { ListItemText, ListItemIcon, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import PdfIcon from "../../component/Icon/PdfIcon";
import { withStyles } from "@material-ui/styles";
import { setLanguage } from "./store/language-picker.actions";

const StyledMenuItem = withStyles(() => ({
  root: {
    "&:focus": {
      backgroundColor: "#2c2c2c",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#fff"
      }
    }
  }
}))(MenuItem);

const LanguagePicker = ({ handleClose }) => {
  const dispatch = useDispatch();
  const translationsContext = useContext(TranslationsContext);

  const chooseLanguage = (val) => {
    dispatch(setLanguage(val));
    translationsContext.setLanguage(val);
    handleClose();
  };

  return (
    <div>
      {languages.map((language, index) => (
        <StyledMenuItem key={index} onClick={() => chooseLanguage(language.value)}>
          <ListItemIcon>
            <PdfIcon width={16} icon={`lang/${language.flag}`} alt="" ext="svg" />
          </ListItemIcon>
          <ListItemText primary={language.name} />
        </StyledMenuItem>
      ))}
    </div>
  );
};

export default LanguagePicker;