import React, { useRef } from "react";
import "./UberModalStyles.scss";
import { setTimeout } from "timers";
import { Clear } from "@material-ui/icons";

const UberModalSize = {
  SMALLER: 'smaller',
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
};

const UberModalImageSize = {
  NO_SIZE: 0,
  MID: 1,
  FULL: 2
}

const UberModal = (props) => {
  const onClose = props.onClose;
  const scrollTarget = useRef();
  // const sizingFactor = 150; // px on which image will grow
  // const imageSize = props.imageSize || 0; // 0: No size, 1: Mid-size, 2: Full
  // const autoHeight = props.autoHeight || false; // fit content 
  const smallModal = props.smallModal || false; // fit content 
  const height = props.height || 'big';
  const width = props.width;
  const required = props.required || false;//hide close button

  const getHeight = () => {
    switch (height) {
      case UberModalSize.BIG:
        return '60vh'
      case UberModalSize.MEDIUM:
        return '50vh'
      case UberModalSize.SMALL:
        return '40vh'
      case UberModalSize.SMALLER:
        return '20vh'
      default:
        return '60vh';
    }
  }

  setTimeout(() => {
    if (scrollTarget && scrollTarget.current) {
      scrollTarget.current.addEventListener('scroll', () => {
        const scrollRect = scrollTarget.current.getBoundingClientRect();
        const titleDiv = document.getElementsByClassName('title').item(0);
        const titleHoldDiv = document.getElementsByClassName('mydialog__dialog__header__hold').item(0);
        const closeBtn = document.getElementsByClassName('close').item(0);
        const stickyRect = titleDiv.getBoundingClientRect();
        if (stickyRect.y < scrollRect.top + 5) {
          titleDiv.classList.add('title-sticky');
          titleHoldDiv.classList.add('mydialog__dialog__header__hold-sticky');
          if (closeBtn) {
            closeBtn.classList.add('close-topped');
          }
        } else {
          titleDiv.classList.remove('title-sticky');
          titleHoldDiv.classList.add('mydialog__dialog__header__hold-sticky');
          if (closeBtn) {
            closeBtn.classList.remove('close-topped');
          }
        }
      });
    }
  }, 1000);

  return (
    <div data-focus-lock-disabled="false" className="mydialog">
      <div className="mydialog__container" ref={scrollTarget}>
        <div className="mydialog__wrapper">
          <div className="mydialog__placeholder" />
          <div className="mydialog__dialog" role="dialog">
            <div className="mydialog__dialog__header__hold" />
            <div />
            <div className="mydialog__dialog__header__inner">
              <div className="title">
                {props.title && <div className="title__text">{props.title}</div>}
              </div>
              {required ? null : <button className="close button--transparent button--round" onClick={onClose}>
                <Clear component={"svg"} />
              </button>}
            </div>

            <div className="mydialog__dialog__content" style={{ width: width || smallModal ? '550px' : '650px' }}>
              <div className="mydialog__dialog__content__inner" style={{ minHeight: getHeight() }}>
                {props.image && <div className="mydialog__dialog__content__image"
                  style={{
                    backgroundImage: `url(${props.image.src || "./images/picture/no-image.png"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    // height: sizingFactor * imageSize,
                  }} />}
                {(props.subtitle || props.description) && <div className="mydialog__dialog__content__caption">
                  {props.subtitle}
                  {props.description && <p className="mydialog__dialog__content__description">{props.description}</p>}
                </div>}

                {props.content && <div className="">
                  {props.content}
                </div>}
              </div>

              <hr className="divition" />

              {props.actions && <div className="mydialog__dialog__footer">
                {props.actions}
              </div>}
              {props.extra}
            </div>
          </div>
        </div>
      </div>
    </div>)
}

UberModal.sizes = UberModalSize;
UberModal.imageSizes = UberModalImageSize;

export default UberModal;