import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { simplePost } from "core/services";
import { browserHistory } from "../../../utils/History";
import useTranslations from "translations";

export const PAY_ORDER_SUCCESS = "PAY_ORDER_SUCCESS";
export const PAY_ORDER_FAILURE = "PAY_ORDER_FAILURE";
export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";
export const CLEAR_PAY = "CLEAR_PAY";

export function payOrderSucess(response) {
  return {
    type: PAY_ORDER_SUCCESS,
    response,
  };
}

export function payOrderFailure(response) {
  return {
    type: PAY_ORDER_FAILURE,
    response,
  };
}

export function clearPaymentStore() {
  return {
    type: CLEAR_PAY,
  };
}

function sendAvailabilityError(response) {
  return {
    type: AVAILABILITY_ERROR,
    response,
  };
}

export function payOrder(DATA) {
  return (dispatch) => {
    const translation = useTranslations();
    dispatch(
      setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage)
    );
    simplePost(`checkout/payment`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(payOrderSucess(response));
          browserHistory.push({ pathname: "/prv/pay_success" });
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        dispatch(payOrderFailure(response));
        dispatch(setLoadingMaskStatus(false));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
