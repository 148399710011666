import React from "react";
import useTranslations from "translations";
import UpsertCreditCard from "./UpsertCreditCard";
import UberModal from "component/UberModal/UberModal";

const AddCardModal = ({ show, onClose, handleSubmit, handleError }) => {
  const translation = useTranslations();

  return (
    show && <UberModal onClose={() => onClose()} height={UberModal.sizes.SMALLER}
      smallModal={true}
      title={<h4>{translation.Payment.newPaymentMethod}</h4>}
      subtitle={<h1>{translation.Payment.newPaymentMethod}</h1>}
      // image={{ src: "./images/picture/addresses.jpg" }}
      content={
        <div style={{ padding: '24px 24px 10px' }}>
          <UpsertCreditCard handleSubmit={handleSubmit} feedbackError={handleError} />
        </div>
      }
    />
  );
}

export default AddCardModal;