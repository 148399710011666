import React from "react";
import useTranslations from "translations";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";
import { selectBrowserOrder } from "../store/checkout.reducers";
import { selectBDCart } from "modules/cart/store/cart.reducers";
import { LightTooltip } from "component/LightTooltip";
import { Info } from "@material-ui/icons";


const Voucher = ({ edit, browserOrder }) => {
  const translation = useTranslations();
  const cartBD = useSelector(selectBDCart);

  return (
    <div className="cart-resume">
      <div className="cart-resume__container">
        {/* Selected Offers */}
        <div className="cart-resume__list">
          {cartBD?.items?.map((item, index) => (
            <div className="cart-resume__item" key={index}>
              <div className="cart-resume__item__info">
                <span className="cart-resume__item__info__count">{item.offerCount}</span>
                <div>
                  <span className="title">{item.name}</span>
                  <br />
                  {item.variantSelected?.length > 0 ?
                    <span className="variant">
                      {item.variantSelected.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")}
                    </span> : null}
                  {item.additionsSelected?.length > 0 ?
                    <span className="details">
                      {` - ` + item.additionsSelected.map(({ name }) => `${name}`).join(", ")}
                    </span> : null}
                </div>
              </div>
              <div className="cart-resume__item__price">
                <span className="price">{'$' + item?.price?.toFixed(2)}</span>
              </div>
            </div>
          ))}
          <button onClick={edit} className="button--secondary" style={{ marginTop: "16px" }}>
            <span style={{ fontSize: "19px", fontWeight: 600, lineHeight: "23px", margin: "-4px 8px 0 0" }}>+</span> Añadir platos
          </button>
        </div>
        <div className="spacer" />

        {/* Cost Overview */}
        {browserOrder?.subtotal !== undefined
          ? <div className="subtotal">
            <span>{translation.Payment.subtotal}</span>
            <span>{browserOrder?.subtotal > 0 ? `$${browserOrder?.subtotal?.toFixed(2)}` : "-"}</span>
          </div>
          : null
        }

        {browserOrder?.deliveryOrderType === "DELIVERY"
          ? browserOrder?.shipping !== undefined
            ? <div className="subtotal">
              <span>{translation.Payment.shipping}</span>
              <span>
                {browserOrder?.shipping > 0
                  ? "$" + browserOrder?.shipping.toFixed(2)
                  : (browserOrder?.shipping === 0 || browserOrder?.shipping === 0.0)
                    ? translation.Payment.freeShipping
                    : translation.Payment.pending}
                {(browserOrder?.shipping > 0) && (browserOrder?.deliveryDays && browserOrder?.deliveryDays > 1)
                  ? " ($" + (browserOrder?.shipping / browserOrder?.deliveryDays).toFixed(2) + " " + translation.Payment.daily + ")"
                  : null}
              </span>
            </div>
            : <div className="cost" style={{ margin: "4px 0 0" }}>
              <Skeleton variant="rect" width={150} height={18} animation="wave" />
              <Skeleton variant="rect" width={50} height={18} animation="wave" />
            </div>
          : null
        }

        {browserOrder?.deliveryOrderType
          ? browserOrder?.taxes !== undefined
            ? <div className="subtotal">
              <span style={{ display: 'flex' }}>
                {translation.Payment.taxes}
                <LightTooltip title={translation.Cart.serviceTaxInfo} placement="bottom">
                  <Info style={{ fontSize: 20, margin: '1px 0 0 5px' }} />
                </LightTooltip>
              </span>
              <span>
                ${browserOrder?.taxes && browserOrder?.taxes ? browserOrder?.taxes.toFixed(2) : (0).toFixed(2)}
              </span>
            </div>
            : <div className="cost" style={{ margin: "4px 0 0" }}>
              <Skeleton variant="rect" width={150} height={18} animation="wave" />
              <Skeleton variant="rect" width={50} height={18} animation="wave" />
            </div>
          : null
        }

        {browserOrder?.discountByTalon
          ? browserOrder?.discountByTalon.map((effect, k) => {
            let TALON_ROW = null;
            TALON_ROW = (
              <div className="cost" key={k}>
                <span>{effect.name ? `${effect.name}` : `${translation.Payment.bonus}`}</span>
                <span style={{ color: '#00d200' }}>
                  {effect.value > 0 ? `- $${effect.value.toFixed(2)}` : `$${(0).toFixed(2)}`}
                </span>
              </div>
            )
            return TALON_ROW;
          })
          : null
        }

        {browserOrder?.discountByCoupon && browserOrder?.discountByCoupon > 0
          ? <div className="cost">
            <span>{translation.Payment.discount}</span>
            <span style={{ color: '#00d200' }}>
              {browserOrder?.discountByCoupon > 0 ? `-$${browserOrder?.discountByCoupon?.toFixed(2)}` : "-"}
            </span>
          </div>
          : null
        }

        {browserOrder?.total !== undefined
          ? <div className="total">
            <span>{translation.Payment.total}</span>
            <span>{browserOrder?.total && browserOrder?.total > 0 ? `$${browserOrder?.total?.toFixed(2)}` : ` $${(0).toFixed(2)}`}</span>
          </div>
          : null
        }
      </div>
    </div>
  )
};
export default Voucher;