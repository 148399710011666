import React, { useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Topbar from "../modules/Topbar/Topbar";
import Footer from "../component/Footer/Footer";
import Register from "../modules/register/Register";
import BusinessDetail from "../modules/BusinessDetail/BusinessDetail";
import Mask from "../component/Mask/Mask";
import { Login } from "../modules/login/Login";
import { browserHistory } from "../utils/History";
import { Pin } from "../modules/login/components/Pin/Pin";
import { RecoverPassword } from "../modules/recoverPassword/RecoverPassword";
import { TermsAndConditions } from "../modules/legal/TermsAndConditions";
import { Privacy } from "../modules/privacy/Privacy";
import ErrorBoundary from "../modules/error/ErrorBoundary";
import { HomeBusinessList } from "../modules/HomeBusinessList/HomeBusinessList";
import CustomerSatisfaction from "../modules/customer_satisfaction/CustomerSatisfaction";
import { EmailForm } from "../modules/login/components/EmailForm/EmailForm";
import UserProfile from "../modules/profile/UserProfile";
import UserAddress from "../modules/profile/UserAddress";
import PurchaseHistory from "../modules/purchase_history/PurchaseHistory";
import PaymentProcess from "../modules/payment/PaymentProcess";
import PaymentProcess3DSecure from "../modules/payment/PaymentProcess3DSecure";
import PaymentProcessSuccess from "../modules/payment/PaymentProcessSuccess";
import PaymentProcessError from "../modules/payment/PaymentProcessError";
import { Helmet } from "react-helmet";
import { EmailVerification } from "modules/login/components/EmailVerification/EmailVerification";
import CheckoutOrder from "modules/Checkout/CheckoutOrder";
import Pay from "modules/Pay/Pay";
import CardManagement from "modules/CardManagement/CardManagement";
import { isAuth } from "App";

export const PUBLIC = "PUBLIC";
export const PRIVATE = "PRIVATE";

const defaultTitle = "Comida a domicilio en Cuba y mucho más!";

export const IndexRoutes = () => {
  const [meta, setMeta] = useState({ title: defaultTitle });

  const getMetaContent = (path) => {
    const generalPaths = generalRoutes.map(r => r.path);
    let title = 'Mercado Vip'
    if (path !== '/' && !generalPaths.includes(path)) {
      const matches = path.matchAll(/(\w+[^\-\d])/g);
      const name = Array.from(matches).map(m => m[1]).map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join(" ");
      title = `${title} | ${name}`;
    } else {
      title = `${defaultTitle} | ${title}`;
    }
    return { title };
  }

  window.lastPath = undefined;
  browserHistory.listen(async (location, action) => {
    if (!(isAuth()) && location.pathname.includes('/prv/')) {
      console.log('Not Logged In so redirect to Login');
      browserHistory.push({ pathname: '/login' });
    }

    const path = location.pathname;
    const { title } = getMetaContent(path);
    setMeta({ title });
    if (path !== browserHistory.prevLocation) {
      browserHistory.prevLocation = path;
      // segment.page({
      //   path: `/restaurantes${path}`,
      //   title,
      //   url: `https://www.cuballama.com/restaurantes${path}`
      // });
    }
  });

  const routes = appRoutes.map((route, key) => {
    switch (route.type) {
      case PRIVATE:
        return <PrivateRoute exact={true} path={route.path} key={key} component={route.component} />
      case PUBLIC:
      default:
        return <Route exact={true} path={route.path} key={key} component={route.component} />
    }
  });

  return (
    <>
      <Helmet>
        <title>
          Comida a domicilio en Cuba y mucho más! | Mercado Vip
        </title>
        <meta
          name="description"
          content="La mejor comida a domicilio en Cuba y mucho más. Comida cubana e internacional, combos de aseo, regalos, y más!"
        />
      </Helmet>
      <Topbar history={browserHistory} />
      <div id="main">
        <Mask />
        <Router history={browserHistory} >
          <ErrorBoundary>
            <Switch>
              {routes}
            </Switch>
          </ErrorBoundary>
        </Router>
      </div>
      <Footer />
    </>
  );
};

const generalRoutes = [
  { type: PUBLIC, path: "/register", name: "Register", component: Register },
  { type: PUBLIC, path: "/login", name: "Login", component: Login },
  { type: PUBLIC, path: "/pin", name: "Pin Verification", component: Pin },
  { type: PUBLIC, path: "/email", name: "Email Form", component: EmailForm },
  { type: PUBLIC, path: "/verification/email", name: "Email Form", component: EmailVerification },
  { type: PUBLIC, path: "/recover", name: "Recover password", component: RecoverPassword },
  { type: PUBLIC, path: "/legal", name: "Terms And Conditions", component: TermsAndConditions },
  { type: PUBLIC, path: "/privacidad", name: "Privacidad", component: Privacy },
  { type: PUBLIC, path: "/customer/satisfaction/:order", name: "Customer Satisfaction", component: CustomerSatisfaction },

  { type: PRIVATE, path: "/prv/profile", name: "Profile", component: UserProfile },
  { type: PRIVATE, path: "/prv/user_address", name: "UserAddress", component: UserAddress },
  { type: PRIVATE, path: "/prv/purchase_history", name: "PurchaseHistory", component: PurchaseHistory },
  { type: PRIVATE, path: "/prv/card_management", name: "CardManagement", component: CardManagement },
  { type: PRIVATE, path: "/prv/checkout", name: "Checkout", component: CheckoutOrder },
  { type: PRIVATE, path: "/prv/pay", name: "Payment", component: Pay },
  { type: PRIVATE, path: "/prv/3ds", name: "Payment", component: PaymentProcess3DSecure },
  { type: PRIVATE, path: "/prv/pay_success", name: "PaymentSuccess", component: PaymentProcessSuccess },
  { type: PRIVATE, path: "/prv/pay_cancel", name: "PaymentCancel", component: PaymentProcess },
  { type: PRIVATE, path: "/prv/pay_error", name: "PaymentError", component: PaymentProcessError },
];
const appRoutes = [
  ...generalRoutes,

  // special routes for old links
  { type: PUBLIC, path: "/restaurants/:slug", name: "Detail", component: BusinessDetail },
  { type: PUBLIC, path: "/restaurants/:slug/:offerSlug", name: "Detail", component: BusinessDetail },
  { type: PUBLIC, path: "/", name: "Home", component: HomeBusinessList },

  // {type: PUBLIC, path: "/:businessCategorySlug", name: "List", component: HomeBusinessList},
  { type: PUBLIC, path: "/:slug", name: "Detail", component: BusinessDetail },
  { type: PUBLIC, path: "/:slug/:offerSlug", name: "Detail", component: BusinessDetail },
];