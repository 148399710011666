import { Translation } from "./types";
import { TASA_PESO } from "../../constants";

export default {
  Code: "EN",
  ConnectedTo: "Connected to id",
  Validations: {
    fieldRequired: "Field Required",
    required: "Required field",
    integer: "Not a valid number",
    number: "Not valid number",
    email: "Wrong email",
    password: "Password must be 6 characters or more",
    us_phone_number: "Invalid phone number",
    date: "Invalid date",
    length: "Minimum characters must be above of",
    maxLength: "You have reached the maximum number of characters allowed",
    special_characters: "Special characters not allowed",
    form: "There are validation errors in the form",
    incomplete_phone_number: "Incomplete phone number",
    login: "Invalid credentials",
  },
  Actions: {
    new: "New",
    close: "Close",
    cancel: "Cancel",
    accept: "Accept",
    apply: "Apply",
    save: "Save",
    continue: "Continue",
  },
  General: {
    seeMore: "See more",
    viewAll: "View all",
    viewRestaurants: "View Restaurants",
    readFullArticle: "Read the full article",
    notAvailable: "Not available",
    noMatchesFoundFor: "No matches found for",
    tryAnotherSearchCriteria: "try another search criteria",
    noResultList: "No results to show",
    defaultAlertTitle: "Alert",
  },
  Pagination: {
    labelRowsPerPage: "Rows Per Page",
  },
  TopBar: {
    linkDelivery: "Delivery",
    linkHelp: "Help",
    linkCll: "Cuballama",
    linkHome: "Home",
    linkRestaurant: "Restaurant",
    linkReserve: "Reserve",
    btnLogin: "Sign up or sign in",
    btnLogout: "Logout",
    btnProfile: "My Account",
    linkPersonalData: "Personal data",
    linkPreferredAddresses: "Preferred addresses",
    linkCardManagement: "Cards Management",
    linkPurchaseHistory: "Purchase history",
    btnRegister: "Create Account",
    btnMyProfile: "My Profile",
    optionDelivery: "Delivery",
    optionPickup: "Pickup",
    deliveryModeDetails: "Delivery Details",
    searchPlaceholder: "Search...",
    search: "Search",
    cart: "Cart",
  },
  Address: {
    selectAddress: "Set you address",
    deliverIn: "Deliver In",
    deliverAt: "Deliver at",
  },
  Home: {
    labelSelectMunicipality: "Where do you want it delivered",
    sectionBusinessCategoryTitle: "Find everything you need",
    sectionBusinessCategorySubtitle:
      "Click on the store you want and start shopping",
    sectionHeaderMobileTitle: "Food delivery in Cuba and more",
    tabTitleDefault: "Mercado Vip | Everything you need in Cuba at a click",
    tabTitleRestaurant: "Food delivery in Cuba and much more! | Mercado Vip",
    tabTitleSupermarket: "Mercado Vip | Supermarket | Combos and more!",
    metaDefault:
      "Mercado Vip. Restaurants, Supermarket and Stores with everything your relatives need in Cuba with the best offers.",
    metaRestaurant:
      "With Mercado Vip the best Cuban dishes, now delivered to your doorstep. The best food delivery in Cuba.",
    metaSupermarket:
      "Supermercado de Mercado Vip. Combos and much more !. Delivery in 1 or 2 days!",
    sectionHeaderTitleDefault: "Mercado Vip",
    sectionHeaderTitleRestaurant:
      "Mercado Vip: The best food delivery in Cuba and much more.",
    sectionHeaderTitleSupermarket: "Sumpermarket.",
    sectionHeaderTitle1: "Choose what you want to eat today, ",
    sectionHeaderTitle2: "we will take it",
    sectionHeaderSubtitle1: "Select restaurant",
    sectionHeaderSubtitle2: "Create the order",
    sectionHeaderSubtitle3: "Receive at home",
    sectionHeaderHomeDeliveryOption: "Home Delivery",
    sectionHeaderPickOrderOption: "Pick Order",
    sectionHeaderSearchButton: "Search",
    sectionFoodCategoryTitle: "What kind of food are you looking for?",
    sectionPopularsTitle: "The most popular",
    sectionTrendingTitle: "Trends in Havana",
    sectionSubscriptionTitle: "Don't miss anything!",
    sectionSubscriptionSubtitle:
      "Subscribe to our newsletter to keep up to date with the best promotions.",
    sectionSubscriptionButton: "I want to subscribe",
    sectionSubscriptionInputPlaceholder: "Email",
    sectionPressTitle: "Mercado Vip in the press",
    defaultPageMessageTitle:
      "Home delivery of food in Cuba and everything for your family",
    defaultPageMessageContent:
      "Mercado Vip is an all-terrain solution to take care of your family with food delivery at home in Cuba even on the same day. Food... and much more: in addition to food combos, dinners, and farm products, we have electrical appliances, toiletries, hygiene, and household items. To fight from cravings to blackout when they want it. When you're in a hurry, order food delivery in Cuba, and if not, too. Mercado Vip, a single Cuban family.",
  },
  OfferCarousel: {
    title: "Special offers",
    seeAll: "See all",
  },
  RestaurantCarousel: {
    since: "Since",
    minute: "Min",
    title: "Food delivery in Havana",
    seeAll: "See all",
  },
  PromotionsCarousel: {
    carouselTitle: "Today's Deals",
  },
  RestaurantList: {
    sectionFilterButton: "Filter",
    filterDialogHeader: "Filter",
    filterTitle: "You are filtering by",
    eraseAll: "Erase all",
    noResult: "No results to show",
    title: "Home delivery",
    restaurant: "restaurants",
  },
  Filter: {
    sort: "Sort",
    atHome: "At home",
    pickup: "Pickup",
    promotion: "Promotion",
    dietIntolerance: "Diet/Intolerance",
    cuisineType: "Cuisine type",
    eraseAll: "Erase all",
    apply: "Apply",
    deliverAt: "Deliver at",
    restaurantName: "Restaurant name",
    deliverTime: "Deliver time",
    bestScore: "Best score",
    averagePrice: "Average price",
  },
  Restaurant: {
    hours: "Hours",
    menuOfDay: "Menu of the day",
    back: "Back",
    atHome: "At home",
    pickup: "Pickup",
    thisIsYourRequest: "This is your request",
    viewMyRequest: "View my request",
    backToMenu: "Back to Menu",
    completeRequest: "Complete Request",
    evaluations: "evaluations",
    more: "More",
  },
  Login: {
    info: "Enter to your account or create a new one so you can finish your payment.",
    welcome: "Access your Account",
    indentifyWithCuballama:
      "To access your account or create a new one provide your phone number.",
    header:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    emailField: "Email",
    emailHeader: "Customer email",
    emailSubHeader: "Please enter a valid email address",
    backToLogin: "Go back to start",
    needToLogin: "You need to sign into the system to execute this action",
    phoneNumberField: "Phone",
    passwordField: "Password",
    pinHeader: "Validate phone number",
    pinSubHeader: "Introduce the validation code that we sent to your phone",
    pin: "Pin",
    pinAction: "Validate",
    pinMsg: "a PIN number has been sent to your phone",
    pinNotReceived: "Didn't you get the code?",
    pinHelper: "Only 4 characters",
    verificationHeader: "Complete the verification",
    verificationSubHeaderPart1: "We have sent you an email to ",
    verificationSubHeaderPart2:
      "Follow the instructions in the email to verify your account.",
    btnSuccessfulVerification: "I have verified",
    btnEmailNotReachable: "I don't have access to email",
    alertVerificationTitle: "Please, validate your email",
    alertVerificationMessage:
      "For your security we have sent you an email to verify that it is you. Please check your inbox and click on the attached link. Once you do, return to this page and press continue to complete your access.",
    rememberMe: "Remember me",
    haveYouForgotPassword: "Have you forgotten your password?",
    loginAction: "Continue",
    registerInvitation: "We will send you an SMS to verify your phone",
    createItHere: "Create it here",
    phonePlaceHolder: "Phone with prefix",
    wait: "Wait",
    seconds: "seconds",
    resendCode: "Resend code",
  },
  Register: {
    createCuballamaAccount: "Create a Mercado Vip Account",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    info2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    phoneField: "Phone",
    validateAction: "Validate Phone",
    alreadyRegistered: "Already registered in Mercado Vip?",
    introPinOrGetACall:
      "Enter the PIN that we have sent to you via SMS o call to",
    modifyAction: "Modify",
    pinReceived: "Pin received",
    password: "Password",
    email: "Email",
    thisDataIsOptional: "This data is optional",
    conditionPart1: "Creating this account means that you accept",
    conditionPart2: "legal conditions",
    conditionPart3: "and the",
    conditionPart4: "policy of privacy",
    conditionPart5: "of Mercado Vip",
    registerAction: "Create Account",
    resendCodeTitle: "You have not received the PIN?",
    receivePinAgain: "Choose how do you wish to receive the code",
    receivePinPerCall: "By call",
    receivePinBySms: "By SMS",
    receivePinByWhatsApp: "By WhatsApp",
    talkToAnAgent: "Talk to an agent",
    cancel: "Cancel",
  },
  RecoverPassword: {
    title1: "Identify with a Mercado Vip account",
    title2: "Identify with a Mercado Vip account",
    helperText:
      "In a few seconds you will receive your PIN. If you have used a mobile phone you will receive it in an SMS, otherwise by a call",
    goBackToLogin: "Do you already remember the password?",
    sendPin: "Send PIN",
    phoneField: "Phone",
    pinReceived: "Pin received",
    password: "Password",
    usernameField: "Telephone",
    modifyAction: "Modify",
    setPasswordAction: "Change Password",
    resendCodeTitle: "You have not received the PIN?",
    getPinBySms: "Receive PIN per SMS",
    getPinByCall: "Receive PIN per call",
    talkToAnAgent: "Talk to an agent",
  },
  Payment: {
    codes: {
      TRANSACTION_NOT_ALLOWED: "Transaction failed, please try again",
      DECLINED: "Transaction Declined, please contact your Bank",
      PROCESSOR_DECLINED: "Declined transaction, please contact your Bank",
      EXPIRED_CARD: "Expired Card, please check your payment method.",
      INVALID_TRANSACTION: "Invalid transaction, please try again",
      GATEWAY_REJECTED: "Transaction declined by Bank authorization",
    },
    failureTitle: "Payment could not be processed",
    failureSubtitle: "Please try again or select another payment method.",
    successTitle: "Payment has been completed successfully",
    successSubtitle: "Below we show the details of the purchase.",
    viewMyRequest: "View my request",
    thisIsMyRequest: "This is my request",
    haveAnyDiscount: "Do you have any discount?",
    couponApplied: "Discount coupon applied",
    invalidCoupon: "Invalid coupon",
    discountCode: "Discount code",
    apply: "Apply",
    clearCoupon: "Remove",
    subtotal: "Subtotal",
    shipping: "Shipping",
    freeShipping: "Free",
    taxes: "Taxes",
    discount: "Discount (Coupon)",
    bonus: "Discount (Bonus)",
    coupon: "Coupon",
    total: "Total",
    pending: "To calculate",
    daily: "Daily",
    modify: "Modify",
    modifyRequest: "Modify Request",
    continueCheckout: "Continue to Checkout",
    deliveryData: "Delivery Data",
    deliveryDate: "Delivery",
    pay: "Pay",
    addCard: "Save credit card",
    addAndPay: "Save credit card and pay",
    evaluations: "evaluations",
    addressNotAvailable: "Address not available.",
    validatingAddress: "Validating address . . .",
    invalidDeliveryAddress:
      "The selected restaurant does not ship to this address.",
    validDeliveryAddress: "This is a valid address for this delivery.",
    setYourAddress: "Tell us your address",
    addressNewAddress: "Add new address",
    addDeliveryAddress: "Add delivery address",
    setDeliveryAddress: "Set delivery address",
    deliveryAddress: "Delivery address",
    address: "Address",
    deliveryAddressHint:
      "Include your street name and number, as well as your neighborhood or area",
    municipality: "Municipality",
    city: "City",
    name: "Name",
    phone: "Phone",
    date: "Date",
    time: "Time",
    labelPickup: "pick up",
    labelDelivery: "delivery",
    labelDateAndTimeOf: "Date and time of ",
    clickToSelectDates: "Select the delivery date",
    deliveryWarning:
      "Make sure that there will be a person to receive your request at the selected date and time.",
    confirmDateDialogTitle: "This order will be delivered on ",
    confirmDateDialogMessage: "Do you wish to continue?",
    confirmDateDialogButtonOk: "",
    confirmDateDialogButtonCancel: "",
    confirm: "Confirm",
    confirmAndPay: "Confirm and pay",
    paymentInProgressMessage:
      "We are processing your payment. This process can take up to 1 minute. Do not close this screen until you get an answer.",
    availableCredit: "Mercado Vip Credit",
    cashPaymentOption: "Cash",
    cashPaymentOptionLabel: "Pay cash on delivery",
    makeYourPaymentsWithCash: `This payment will be made in the local currency CUP at the exchange rate ${TASA_PESO} CUP x 1`,
    addCreditCard: "Add Credit Card",
    goBack: "Back",
    asap: "As soon as posible",
    contactData: "Contact data",
    discountedBalance: "Discounted balance",
    remainingBalance: "Remaining Balance",
    transactionId: "Transaction ID",
    creditCard: "Credit Card",
    paypalAccount: "Paypal Account",
    expirationDate: "Expiration",
    cart: "Cart",
    paymentId: "Payment ID",
    paymentDoneMessage: "Your payment is done",
    paymentErrorMessage:
      "Your payment did not finish because an error during the transaction",
    paymentCancelMessage:
      "You cancel this payment transaction, you can go back to the initial payment, clicking the button down below",
    message: "Message",
    restaurant: "Restaurant",
    estimatedDeliveryTime: "Delivery ~ ",
    acceptTerms:
      "By continuing, you UNDERSTAND and ACCEPT that it is not possible to CANCEL or MODIFY your order.",
    estimatedTime: " Min approximate",
    delivery: "Delivery",
    pickUp: "Pick up",
    prices: "Prices",
    estimatedTimeShort: "minutes",
    minDeliveryCost: "Shipping ~ ",
    approx: "(Approx)",
    creditCardEndWith: "Credit Card ended with",
    payWithCard: "Payment with Card",
    payWithTuBolsillo: "Payment with Tu Bolsillo",
    edit: "Edit",
    cancelEdit: "Cancel",
    addPaymentMethod: "Add Payment Method",
    warningMessageBeforePay:
      "By pressing the button 'Pay' I accept this purchase and its delivery conditions",
    titleDeliveryConditions: "Delivery conditions",
    titleSelectPaymentMethod: "Payment Method",
    payRestWith1: "Pay remaining",
    payRestWith2: "with",
    titleResumeBeforePayment: "Complete purchase",
    newPaymentMethod: "Add new payment method",
    alertDeletePaymentMethod:
      "You are about to delete this payment method. Are you sure this is what you want?",
    alertSelectPaymentMethod:
      "Please select a payment method to continue your purchase",
    paymentMethodAdded: "Payment method added successfully",
    closeAddMethodDialog: "Close",
    paymentMethodCancelled: "Payment method cancelled",
    paypalAccountAdded: "Paypal account added",
    attention: "Attention",
    contacts: "Contacts",
    errors: {
      "no valid date.": "No valid date",
      "no valid value.": "No valid value",
      "No card number provided.": "No card number provided",
      "Credit card number is in invalid format.":
        "Credit card number is in invalid format",
      "Warning! This credit card number is associated with a scam attempt.":
        "Warning! This credit card number is associated with a scam attempt",
      "Credit card number has an inappropriate number of digits.":
        "Credit card number has an inappropriate number of digits",
      "Do Not Honor.": "Bank is unwilling to accept the transaction",
      "Insufficient Funds.":
        "The account did not have sufficient funds to cover the transaction amount",
      "Limit Exceeded.":
        "The attempted transaction exceeds the withdrawal limit of the account",
      "Cardholder's Activity Limit Exceeded.":
        "The attempted transaction exceeds the activity limit of the account",
      "Expired Card.": "	Card is expired",
      "Invalid Credit Card Number.": "Invalid credit card number",
      "Invalid Expiration Date.": "Invalid card expiration date",
      "No Account.": "Card number is not on file with the card-issuing bank",
      "Card Account Length Error.":
        "Card number does not include the proper number of digits",
      "Card Issuer Declined CVV.": "Invalid security code ",
      "Processor Declined – Possible Lost Card.":
        "The card used has likely been reported as lost, please contact your bank",
      "Processor Declined – Possible Stolen Card.":
        "The card used has likely been reported as stolen, please contact your bank",
      "Processor Declined – Fraud Suspected.":
        "Fraud suspected, please contact your bank",
      "Transaction Not Allowed.":
        "Bank is declining the transaction for unspecified reasons, possibly due to an issue with the card itself",
      "Duplicate Transaction.":
        "The transaction appears to be a duplicate of a previously submitted transaction and was declined to prevent charging the same card twice",
      "Invalid Transaction.": "Bank declined the transaction",
      "Declined – Updated Cardholder Available.":
        "The submitted card has expired or been reported lost and a new card has been issued",
      "Processor Does Not Support This Feature.":
        "Your account can't process transactions with the intended feature",
      "Card Type Not Enabled.":
        "Your account can't process the attempted card type",
      "Set Up Error – Card.": "There is a problem with the submitted card",
      "Processor Declined.":
        "Declined, your bank is unwilling to accept the transaction, please contact your bank",
      "Invalid Authorization Code.":
        "The authorization code was not found or not provided",
      "Declined – Call For Approval.":
        "Declined, The card used for this transaction requires customer approval, please contact your bank",
      "Declined – Call Issuer.":
        "Declined, The card-issuing bank has declined this transaction. Have the customer attempt the transaction again. If persist, please contact your bank",
      "Declined.":
        "Declined, your bank is unwilling to accept the transaction, please contact your bank",
      "Invalid Credit Plan.": "Invalid Credit Plan",
      "Credit Card Number does not match method of payment.":
        "Credit Card Number does not match method of payment",
      "Card reported as lost or stolen.": "Card reported as lost or stolen",
      "Address Verification Failed.":
        "PayPal was unable to verify that the transaction qualifies for Seller Protection because the address was improperly formatted",
      "Address Verification and Card Security Code Failed.":
        "Both the AVS and CVV checks failed for this transaction",
      "PayPal Business Account preference resulted in the transaction failing.":
        "PayPal Business Account preference resulted in the transaction failing",
      "PayPal Business Account Restricted.":
        "PayPal business account restricted, please contact PayPal support team",
      "Authorization Expired.": "The PayPal authorization is no longer valid",
      "PayPal Business Account Locked or Closed.":
        "PayPal Business Account Locked or Closed, please contact PayPal support team",
      "PayPal Buyer Revoked Pre-Approved Payment Authorization.":
        "PayPal buyer revoked pre-approved payment authorization",
      "PayPal Payee Account Invalid Or Does Not Have a Confirmed Email.":
        "PayPal payee account is invalid or does not have a confirmed email",
      "PayPal Payee Email Incorrectly Formatted.":
        "PayPal payee email incorrectly formatted",
      "PayPal Validation Error.":
        "PayPal can't validate this transaction, please contact PayPal support team",
      "Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment.":
        "PayPal account was declined, please contact PayPal support team",
      "Payer Account Is Locked Or Closed.":
        "PayPal account can't be used for transactions at this time, please retry later or contact PayPal support team",
      "PayPal Transaction Limit Exceeded.": "PayPal Transaction Limit Exceeded",
      "PayPal payment method is invalid.":
        "PayPal payment method has either expired or been canceled",
      "PayPal authorization amount limit exceeded.":
        "PayPal authorization amount limit exceeded",

      "Credit card type is not accepted by this merchant account.":
        "Credit card type is not accepted by this merchant account.",
      "CVV is required.": "CVV is required.",
      "CVV must be 4 digits for American Express and 3 digits for other card types.":
        "CVV must be 4 digits for American Express and 3 digits for other card types.",
      "Expiration date is required.": "Expiration date is required.",
      "Expiration date is invalid.": "Expiration date is invalid.",
      "Expiration year is invalid. It must be between 1975 and 2201.":
        "Expiration year is invalid.",
      "Expiration month is invalid.": "Expiration month is invalid.",
      "Expiration year is invalid.": "Expiration year is invalid.",
      "Credit card number is required.": "Credit card number is required.",
      "Credit card number is invalid.": "Credit card number is invalid.",
      "Credit card number must be 12-19 digits.":
        "Credit card number must be 12-19 digits.",
      "Credit card number is not an accepted test number.":
        "Credit card number is not an accepted test number.",
      "Duplicate card exists in the vault.":
        "Duplicate card exists in the vault.",
      "CVV verification failed.": "CVV verification failed.",
      "Credit card number is prohibited.": "Credit card number is prohibited.",
      "Required attribute is missing": "Required attribute is missing",
      "Incomplete PayPal account information.":
        "Incomplete PayPal account information.",
      "Invalid PayPal account information.":
        "Invalid PayPal account information.",
      "Pre-Approved Payment enabled PayPal account required for vaulting.":
        "Pre-Approved Payment enabled PayPal account required for vaulting.",
      "Error communicating with PayPal.": "Error communicating with PayPal.",
      "PayPal authentication expired.": "PayPal authentication expired.",
      "Amount is required.": "Amount is required.",
      "Attribute is not in the required format":
        "Attribute is not in the required format",
    },
  },
  PaymentSuccess: {
    contactName: "Receiver in Cuba",
    deliveryAddress: "Delivery address",
    pickUpAddress: "Pick up address",
    telephone: "Telephone",
  },
  Profile: {
    personalData: "Personal Data",
    favoriteAddresses: "My Addresses",
    logout: "Logout",
    cancel: "Cancel",
    save: "Save",
    fullname: "Full name",
    change: "Change",
    email: "Email",
    passwordMustMatch: "Password must match",
    mainPhone: "Main Phone",
  },
  UserAddress: {
    contactName: "Name of the Receiver in Cuba",
    contactNamePlaceholder: "Who will receive the order",
    telephoneFieldLabel: "Telephone",
    editingAddress: "Edit delivery address",
    deliveryAddress: "New delivery address",
    myUsualAddresses: "My Usual Addresses",
    addNew: "Add New",
    noAddressesFound: "No addresses to show",
    tag: "Tag",
    tagHint: "Specify your address (home, work,…)",
    details: "Address",
    mainStreet: "Main Street",
    typeToSearch: "Type to search...",
    typeToSearchHint:
      "Include your street name and number; also your location or neighborhood ",
    betweenStreet: "Between Street",
    andStreet: "And Street",
    building: "Home or Building Number",
    stairs: "Stair",
    number: "Apartment",
    province: "Province",
    municipality: "Municipality",
    location: "Neighborhood",
    additionalIndications: "Additional indications",
    additionalIndicationsHint:
      "Please, add any additional indications that may help the delivery drivers to find your address.",
    storeAddressInMyUsualAddresses: "Save this address in my usual directions.",
    requiredFields: "Required Fields",
    save: "Save",
    confirm: "Confirm",
    loading: "Loading",
    noMatchFound: "No matches found",
    noMatchesFoundFor: "No matches found for",
    tryAnotherSearchCriteria: "try another search criteria",
    userAddressFormBtnCancel: "Cancel",
    userAddressFormBtnSave: "Save",
    userAddressFormBtnConfirm: "Confirm",
  },
  PurchaseHistory: {
    title: "Purchase History",
    description:
      "Click on the order you want to repeat and the dishes, quantities and prices will automatically be loaded into the cart.\n Before paying, check that all the information is correct and the order will be processed immediately.",
    contactName: "Name",
    mostRecentlyFirst: "Most recent first",
    oldestFirst: "Oldest first",
    resumeRequest: "Resume request",
    total: "Total",
    purchaseAgain: "Purchase again",
    noPurchasesToShow: "No purchases to show",
    orderId: "Order:",
    deliveryAddress: "Delivery address",
    itemBy: " item By ",
    itemsBy: " items By ",
    more: "Show more",
    deliveredTo: "Delivered to:",
    date: "Date:",
    created: "Created",
    delivered: "Delivered",
    status: "Status:",
    resendOrder: "Resend order",
    fullOrderDialogTitle: "This is the complete summary of your order",
    orderPayType: "Order paid with:",
    deliveryDate: "Delivery Date",
  },
  CardManagement: {
    cardListTitle: "My credit cards",
    cardsNoFound: "Credit cards no found",
    addCardFormTitle: "Add credit card",
    editCardFormTitle: "Update card info",
    buttonAddLabel: "Save credit card",
  },
  Offer: {
    additionsTitle: "Additions",
    additionsActionAdd: "Add",
    variantTitle: "Variants",
    variantTitleNote: "Select one option",
  },
  OfferItem: {
    soldOut: "Sold out",
    maximumOfferOnStock:
      "You have added the maximum number of offers available.",
  },
  Footer: {
    support: "Support",
    frequentQuestions: "Frequent questions",
    testimonials: "Testimonials",
    help: "Help",
    legal: "Legal",
    termsOfUse: "Terms of use",
    privacyPolicy: "Privacy Policy",
    cookies: "Cookies",
    contact: "Contact",
    copyright: "Mercado Vip",
    termsAndConditions: "Terms and Conditions",
    joinTheTeam: "Join your business",
  },
  Banner: {
    text: "Due to Tropical Storm Laura, as of August 24 at 6PM the food delivery service will be affected. We apologize for any inconvenience.",
  },
  AvailabilityValidation: {
    alertTitle: "Attention!",
    alertSubtitle:
      "The following offers are not available and will be removed from the shopping cart:",
    offerMessage:
      "The following offers are not available and will be removed from the shopping cart:",
    offerNotAvailable:
      "The following products are not available and will be removed from the shopping cart:",
    offerInsufficientStock:
      "The requested quantity of the following products is not available, they will be removed from the shopping cart:",
    variantNotAvailable:
      "The following product specifications are not available:",
    additionsNotAvailable: "The following product add-ons are not available:",
    offerWithVariantsNotAvailable:
      "The following products will be removed from the shopping cart for containing specifications that are not available:",
    offerWithAdditionsNotAvailable:
      "The following products will be removed from the shopping cart for containing add-ons that are not available:",
    offerAddsMessage:
      "The following extras are not available. The foods that contain them will be removed from the shopping cart:",
    restaurantMessage:
      "The chosen restaurant is currently sold out, please choose another option. Sorry for the inconvenience.",
  },
  CustomerSatisfaction: {
    subtitle: "Your experience is very valuable to other users. Thanks a lot!",
    labelInputScore: "Your overall rating for this restaurant",
    labelInputScoreTag: "Click to rate",
    labelInputOpinion: "Your opinion helps us provide a better service",
    send: "Send",
    goBack: "Go Back",
    completeRatingMessage: "Your rating has been recorded",
    thanksMessage: "Thank you!",
    errorMessageTitle: "An error has occurred",
    errorMessage: "It is not possible to carry out the valuation of the order.",
  },
  Cart: {
    restaurantValidationMessage:
      "You have selected an offer from a different business than the existing offers in the shopping cart. This action will remove the previous selections. Do you wish to continue?",
    subtotal: "Subtotal",
    shipping: "Shipping",
    serviceTax: "Service Tax",
    total: "Total",
    completeRequest: "Complete request",
    myRequest: "My Request",
    serviceTaxInfo:
      "'Service Tax' is the comission for Mercado Vip, so this restaurant platforms can exists. It includes order processing and payments, support and maintenance",
  },
  Privacy: {
    headline: "Privacy Policy",
    title1: "PRIVACY POLICY OF MERCADO VIP",
    sub1: "About us?",
    p1_1: 'mercadovip.vip (the "Website") is operated by Recarga VIP, LLC, d/b/a Mercado VIP ("we" or "us"). We are registered in Panama and in the United States of America.',
    sub2: "What is the privacy statement of Mercado VIP?",
    p2_1: 'At Mercado VIP, we always respect our customers\' personal information and privacy and take this matter very seriously. We have worked very hard to earn the trust of our members and keeping it is our top priority. That is why we always comply with the obligations established under the data protection laws. We want you to be aware of our Privacy Statement so that you can understand exactly how we use the personal data you provide to us. We recommend that you read the Privacy Statement carefully. Please note that by visiting and clicking "Login" when registering on the Website, you agree to the terms of this Privacy Statement. Capitalized terms not defined here shall have the meaning given to them in the terms and conditions.',
    sub3: "What about other websites linked to mercadovip.vip?",
    p3_1: "Mercado VIP is a unique company, because we have partnerships with a variety of different network operators around the world. We will frequently post links to the websites of some of the partners however please note that Mercado VIP is not responsible for the content or privacy policies of these other websites.",
    title2: "INFORMATION COLLECTED",
    sub4: "What information does Mercado VIP collect?",
    p4_1: "There are two types of information we collect:",
    p4_2: '"Personal Data" - This is information that identifies you or can be used to identify you. We may collect and process the following personal data about you:',
    li4_1: "Your name;",
    li4_2: "Your email address;",
    li4_3: "Your billing address;",
    li4_4: "Details of your credit or debit card;",
    li4_5: "His phone number;",
    li4_6: "Your IP address;",
    li4_7:
      "Other information that you provide to us by filling in forms on our Website. This includes information you provide at the time of registering on our Website;",
    li4_8:
      "We may also ask for information if you report a problem with our Website;",
    li4_9: "If you contact us, we may keep a record of that correspondence;",
    li4_10:
      "The details of the transactions you carry out, and your visits to the Website.",
    li4_11:
      "The details of your balance for phone calls that were adquired by our Website.",
    p4_3: 'In this way we seek to provide you with a better user experience when using the services. For our part, we will save this data so that it is available when you use our services again. "Non-Personal Information" - Like most other websites, Mercado VIP collects statistical and analytical information. This is what we call Non-Personal Information. Unlike Personal Information, this information cannot be used to identify or contact you. Examples of Non-Personal Information include browser types and other anonymous statistical data involving the use of our Website. Why does Mercado VIP collect this Non-Personal Information? At Mercado VIP we are always looking to improve and help our members as much as possible, and the collection of this data is an important way to achieve it. We want to get a better understanding of where our members come from, what they like and what improvements they want from the VIP Marketplace. We are always looking to develop, grow and innovate. This data is a crucial part of the process.',
    title3: "USE OF YOUR PERSONAL INFORMATION",
    sub5: "How do we use your Personal Information?",
    p5_1: "We may use this information for the following:",
    li5_1:
      "To help you ship the product you have ordered and process any payments related to it;",
    li5_2:
      "So that you have your frequent contacts, presented in a more accessible way independently from the platform you are using, Website/APP;",
    li5_3:
      "To make it easier for you to process any payment related to such transaction;",
    li5_4:
      "To contact you if necessary in relation to the product you have purchased or to respond to any communication you have sent us;",
    li5_5:
      "To provide you with information about products or services that may be of interest to you;",
    li5_6: "To notify you about any changes to our Website or its Services;",
    li5_7:
      "To ensure that the content on our Website is presented in the most effective way for you and your computer;",
    li5_8: "To provide you with customer service;",
    li5_9: "For marketing analysis; and",
    li5_10: "For security and fraud prevention purposes.",
    title4: "COOKIES",
    sub6: "What is a Cookie?",
    p6_1: "A cookie is a small text file of letters and numbers that is stored in your computer's browser when you visit certain websites. A cookie allows a website to distinguish your computer from other users' computers.",
    sub7: "Do Cookies have my Personal Information?",
    p7_1: "Cookies generally do not contain Personal Information unless you have already entered it on the Website.",
    sub8: "What Type of Cookies Does the Website Use and For What Purposes?",
    li8_1:
      "Strictly necessary cookies. These allow you to remain logged in for the duration of your visit to the Website and allow you to perform transactions without having to log in again.",
    li8_2:
      "Functional Cookies. These allow the Website to remember certain details about the decisions you make (for example, the language with which you are browsing the Website) to offer you a better experience when using the Website.",
    li8_3:
      "Analytical Cookies. These allow us to recognize and calculate the number of visitors to the Website. They also allow us to calculate the time you spend on the Website and see how you arrived at the Website. They help Mercado VIP to improve the way the Website works.",

    sub9: "Can I block or delete Cookies?",
    p9_1: "Yes, you can if that's what you decide to do. Please note that this may make your use of the Website less convenient.",
    sub10: "How to disable Cookies?",
    p10_1:
      "Click here for information on how to disable cookies in some of the most popular browsers.",
    title5: "THIRD PARTIES",
    sub11: "Does Mercado VIP give Personal Information to third parties?",
    p11_1:
      "We will never disclose your Personal Information to a third party unless you have agreed to such or unless the third party requires it to process the personal data in connection with an ordered product. Your credit or debit card details are processed by a world leading online payment processing provider who stores your card details if you request it so that you do not have to enter your card each time you visit the Website. When the Mercado VIP service is used, we disclose the shipping data to the corresponding local operator in order to facilitate the shipment of the product. We may disclose your personal data if we are required to comply with any law, subpoena, court or regulatory order, or other legal requirement.",
    sub12: "Does Mercado VIP give Non-Personal Information to third parties?",
    p12_1:
      "We may provide Non-Personal Information to third parties in connection with the number of unique users who visit our Website, the demographic distribution of our Website users, or the activities of users on our Website.",
    sub13: "What can be learned from Non-Personal Information?",
    p13_1:
      "There are many useful things to learn from this information. For example, you can help potential advertisers, advertising service providers, business partners, sponsors, licensees, researchers, and other similar parties.",
    sub14: "What happens if Mercado VIP is bought by another company?",
    p14_1:
      "We reserve the right to transfer information (including your Personal Information) to a third party in the event of a sale, merger, liquidation, receivership, or transfer of all or substantially all of our company's assets. The third party only needs to use your personal data for the purposes that you provided to us in the first place. You will be notified in the event of such a transfer and given the opportunity to opt out.",
    sub15: "Can something go wrong with the information sent?",
    p15_1:
      "As you are aware, the nature of the Internet is such that we cannot guarantee the security of any information you submit to us. This is not unique to Mercado VIP, because no information sent over the Internet can be guaranteed to be 100% secure. Any company that tells you otherwise is saying nonsense. We do, however, take all steps reasonably possible to protect your Personal Information.",
    title6: "DIRECT MARKETING",
    sub16:
      "Will Mercado VIP use my Personal Information for direct marketing purposes?",
    p16_1:
      "When you have consented, we may use your Personal Information to send you information by SMS or email related to our products and services that may be of interest to you or products and services similar to those you have previously purchased from Mercado VIP.",
    sub17: "Can I cancel my subscription?",
    p17_1:
      "You will be provided the opportunity to unsubscribe at the time of registration. You can also unsubscribe at any time, free of charge, by using the unsubscribe option in the SMS or emails that Mercado VIP sends to you or by sending an email to info@mercadovip.vip",
    title7: "OTHER QUESTIONS",
    sub18: "Where is my Personal Information stored?",
    p18_1:
      "Your Personal Information is securely stored on our servers located in the United States of America.",
    sub19: "Can I update or delete my Personal Information?",
    p19_1:
      "Of course he can. Please tell us about any changes or inaccuracies in your Personal Information and we will update, correct or delete your Personal Information as requested. There is no charge for this and we will comply with your request as soon as possible and, in any event, within no more than 40 days of receipt of your request. Please contact us at info@mercadovip.vip.",
    sub20: "Can I access my Personal Information?",
    p20_1:
      "You may also request copies of your personal data held by us. We will provide you with a copy of the Personal Information in our possession as soon as possible and in any event within 40 days of your written request. We may request proof of identification to verify your access request. Please contact us at info@mercadovip.vip.",
    sub21: "Can Mercado VIP change its Privacy Statement?",
    p21_1:
      "Any changes to the Mercado VIP Privacy Statement will be clearly posted on our website. We do this because we always want our customers to know exactly what information we collect, how we use it, and under what circumstances, if any, we disclose it. If we ever decide to use personal data in a way that is different from what has been previously stated, we will notify you by email. You will have the option to decide whether or not we can use your information in this new way.",
    sub22: "How can I contact Mercado VIP?",
    p22_1:
      "If you have any questions about your Personal Information, this Privacy Statement or any complaint, please send us an email at info@mercadovip.vip.",
  },
  Terms: {
    headline: "Platform Terms and Conditions – Mercado Vip",
    p1_1: 'These Terms and Conditions of use (hereinafter the "Terms" and/or "Conditions", if applicable) regulate the access or use that you make, as a person, from anywhere in the world, the virtual platform Mercado Vip, made up of an application for mobile devices and a web page (hereinafter "the service" or "the services"). The service offered by Mercado Vip through its Platform (the "Service") consists of:',
    p1_2: '(i) provide its Users with the choice of food and beverages offered by the restaurants registered in Mercado Vip (the "Restaurants") by publishing information on the food products offered by said Restaurants and the conditions of their offer and,',
    p1_3: "(ii) bring the Users and the Restaurants closer together, online and through the Platform, so that the former can order and purchase online the food products offered by the Restaurants according to the available menu, by delivering to home.",
    p1_4: "(iii) With the acceptance of these Terms and Conditions, the User declares to know and accept that the Service consists, exclusively, in the intermediation for the commercialization of food products and that there will be no legal relationship of sale between Mercado Vip and the User with occasion of the orders of said products that the User makes through the Platform.",
    p1_5: '(iv) The User declares to know and accept that Mercado Vip does not participate in and that the Service does not involve the preparation, packaging, availability and physical delivery of the products, these aspects being the full responsibility of the Restaurant. Due to the foregoing, the User accepts that any claim for problems derived from the addition, fulfillment of the order, preparation and delivery of the food products purchased, must be managed directly with the respective Restaurant or through the "Send a Request" system of the Market. Vip, where we will help you solve your request directly with the Restaurant.',
    p1_6: "(v) Taking into account that Mercado Vip only makes available an electronic platform to put Users and Restaurants in contact, Mercado Vip will not be responsible for the information that Restaurants announce about their products or for additional information such as quantities, availability, time of delivery, prices, flavors, means and forms of payment available",
    sub2: "RECORD",
    p2_1: "To register with Mercado Vip you will need to have a Mercado Vip account and register through your phone number. To create your registration you must enter a valid phone number related to your account in Mercado Vip and you will receive a 4-digit PIN. You agree that the information you provided during the account registration process is true, accurate and complete. You understand that providing false, inaccurate or misleading information when creating or updating an account with Mercado Vip violates these Terms and may result in the immediate termination of your account with Mercado Vip. Immediate updating of any account information will be your responsibility. Please be careful not to disclose your password to third parties, either directly or indirectly. It is your responsibility to protect your password. Each account with Mercado Vip can be used by a single user and is not transferable. Mercado Vip is not responsible for activities that take place in your account, including the unauthorized use of your debit or credit card. If you are under 18 years of age, you must obtain authorization from a parent or legal guardian before creating an account with Mercado Vip and using the Services. If you are acting on behalf of another person, company or entity, you must be authorized to accept these Terms on behalf of such person, company or entity.",
    sub3: "OBLIGATIONS OF Mercado Vip",
    p3_1: "Make available a virtual space that allows Users to place purchase orders for food products advertised and marketed by the Restaurants. Make available to the User the means to pay for the value of the products, subject to their acceptance by the Restaurant.",
    p3_2: "Provide the interface and infrastructure of the Platform so that the Restaurants comply with the obligations.",
    p3_3: "Prior to closing the transaction, present the User with a summary of all the selected products. Said summary will include a description of each of the products selected on the Restaurant's site, individual price per unit and number of units, total price of the transaction, additional costs and expenses, if any.",
    sub4: "OBLIGATIONS OF RESTAURANTS",
    p4_1: "Make available to Users and keep updated certain, sufficient and clear information about the food products they offer, which will include information on qualities, characteristics, quantities and available presentations, in such a way that Users can know exactly the products purchased",
    p4_2: "Include images of the products offered that reflect the reality of the products to be delivered.",
    p4_3: "Present the price of the products offered by the Restaurants including all possible surcharges for taxes, additions, delivery service, etc., and any other applicable concept, discriminating each of said concepts.",
    sub5: "REFUND POLICY",
    p5_1: "For your satisfaction, we make sure that you always get food delivery according to your requirements. Our return and refund policy enhances our customers' confidence in our determination.",
    p5_2: "If you do not report any problems within 24 hours of receiving the order, it will be the responsibility of the consumer.",
    p5_3: "Our team makes sure you get the right food at your place. However, if you receive the wrong order, then you can simply contact us and let us know. We can make proper changes to transactions and provide the proper food.",
    p5_4: "We do not refund money if food has been eaten or handled.",
    p5_5: "Refunds are based on a case to case matter.",
    sub6: "CANCELLATIONS AND CHANGES",
    p6_1: "The User may not cancel the service or request its modification when the order is confirmed. We are unable to provide refunds for returned food.",
    sub7: "CONTACT US",
    p7_1: "Be sure to prepare all the details relevant to your complaint, including order numbers and account information, as you'll likely need to explain the problem up front.",
    sub8: "MODIFICATIONS TO THESE TERMS AND CONDITIONS",
    p8_1: "These Terms and Conditions of use of the Service may be modified at any time, totally or partially, in order to improve the Service. It is the responsibility of the Users to review the Terms and Conditions and any modifications that Mercado Vip makes to them.",
    p8_2: "The modifications to these Terms and Conditions will be effective as soon as they are published on the Platform, any User who does not agree with these modifications made to these Terms and Conditions must refrain from accessing, using or registering on the Platform and may Express your opposition to any of the modified terms, in writing and through the Platform, opposition that will generate the cancellation of your Registration.",
  },
} as Translation;
