import React from "react";

import "../../css/PaymentProcessStyle.scss";

import AtomIcon from "component/Icon/AtomIcon";
import CardIcon from "component/Icon/CardIcon";
import Switch from 'react-ios-switch';

export default function PayMethodRow({
  id,
  icon,
  ext,
  brand,
  text,
  ifCheckFunction,
  handleClick,
  handleDelete = () => null,
  disabled,
  styleClass,
  edit = false,
  useSwitch = false
}) {
  return (
    <li
      disabled={disabled}
      style={{ ...styleClass, background: disabled ? 'lightgray' : 'white', cursor: disabled ? 'default' : 'pointer' }}
      onClick={!edit ? handleClick : undefined}
      className={`payment-row ${ifCheckFunction(id) ? "payment-row--active" : ""}`}
    >
      <CardIcon icon={icon.toLowerCase()} ext={ext} width={40} height={24}></CardIcon>
      <div className="payment-row__text">
        <span>{text}</span>
        <span>{brand}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginRight: '.5em' }}>
        {edit
          ? <button className="button-link" onClick={handleDelete}>
            <AtomIcon icon="trash-white" width={50}></AtomIcon>
          </button>
          : useSwitch
            ? <Switch checked={ifCheckFunction(id)} disabled={disabled} offColor={disabled ? 'lightslategrey' : 'white'} onColor="#1F4ACB" style={{ marginRight: '1em' }} />
            : <AtomIcon icon="check-2" width={50}></AtomIcon>}
      </div>
    </li>
  );
}
