import React, { useEffect } from "react";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { useDispatch } from "react-redux";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";
import useTranslations from "../../translations";

export function Privacy() {
  const dispatch = useDispatch();
  const translation = useTranslations();

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(setLoadingMaskStatus(false));
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wrap container-fluid" style={{ textAlign: 'left' }}>
      <h1 style={{ textAlign: 'center' }}>{translation.Privacy.headline}</h1>

      <h2>{translation.Privacy.title1}</h2>

      <h3>{translation.Privacy.sub1}</h3>
      <p>{translation.Privacy.p1_1}</p>

      <h3>{translation.Privacy.sub2}</h3>
      <p>{translation.Privacy.p2_1}</p>

      <h3>{translation.Privacy.sub3}</h3>
      <p>{translation.Privacy.p3_1}</p>

      <h2>{translation.Privacy.title2}</h2>

      <h3>{translation.Privacy.sub4}</h3>
      <span>{translation.Privacy.p4_1}</span>
      <span>{translation.Privacy.p4_2}</span>
      <ul>
        <li>• {translation.Privacy.li4_1}</li>
        <li>• {translation.Privacy.li4_2}</li>
        <li>• {translation.Privacy.li4_3}</li>
        <li>• {translation.Privacy.li4_4}</li>
        <li>• {translation.Privacy.li4_5}</li>
        <li>• {translation.Privacy.li4_6}</li>
        <li>• {translation.Privacy.li4_7}</li>
        <li>• {translation.Privacy.li4_8}</li>
        <li>• {translation.Privacy.li4_9}</li>
        <li>• {translation.Privacy.li4_10}</li>
        <li>• {translation.Privacy.li4_11}</li>
      </ul>
      <p>{translation.Privacy.p4_3}</p>

      <h2>{translation.Privacy.title3}</h2>
      <h3>{translation.Privacy.sub5}</h3>
      <span>{translation.Privacy.p5_1}</span>
      <ul>
        <li>• {translation.Privacy.li5_1}</li>
        <li>• {translation.Privacy.li5_2}</li>
        <li>• {translation.Privacy.li5_3}</li>
        <li>• {translation.Privacy.li5_4}</li>
        <li>• {translation.Privacy.li5_5}</li>
        <li>• {translation.Privacy.li5_6}</li>
        <li>• {translation.Privacy.li5_7}</li>
        <li>• {translation.Privacy.li5_8}</li>
        <li>• {translation.Privacy.li5_9}</li>
        <li>• {translation.Privacy.li5_10}</li>
      </ul>

      <h2>{translation.Privacy.title4}</h2>
      <h3>{translation.Privacy.sub6_1}</h3>
      <p>{translation.Privacy.p6_1}</p>

      <h3>{translation.Privacy.sub7}</h3>
      <p>{translation.Privacy.p7_1}</p>

      <h3>{translation.Privacy.sub8}</h3>
      <ul>
        <li>• {translation.Privacy.li8_1}</li>
        <li>• {translation.Privacy.li8_2}</li>
        <li>• {translation.Privacy.li8_3}</li>
      </ul>

      <h3>{translation.Privacy.sub9}</h3>
      <p>{translation.Privacy.p9_1}</p>

      <h3>{translation.Privacy.sub10}</h3>
      <p>{translation.Privacy.p10_1}</p>


      <h2>{translation.Privacy.title5}</h2>

      <h3>{translation.Privacy.sub11}</h3>
      <p>{translation.Privacy.p11_1}</p>

      <h3>{translation.Privacy.sub12}</h3>
      <p>{translation.Privacy.p12_1}</p>

      <h3>{translation.Privacy.sub13}</h3>
      <p>{translation.Privacy.p13_1}</p>

      <h3>{translation.Privacy.sub14}</h3>
      <p>{translation.Privacy.p14_1}</p>

      <h3>{translation.Privacy.sub15}</h3>
      <p>{translation.Privacy.p15_1}</p>

      <h2>{translation.Privacy.title6}</h2>

      <h3>{translation.Privacy.sub16}</h3>
      <p>{translation.Privacy.p16_1}</p>
      <h3>{translation.Privacy.sub17}</h3>
      <p>{translation.Privacy.p17_1}</p>

      <h2>{translation.Privacy.title7}</h2>

      <h3>{translation.Privacy.sub18}</h3>
      <p>{translation.Privacy.p18_1}</p>
      <h3>{translation.Privacy.sub19}</h3>
      <p>{translation.Privacy.p19_1}</p>
      <h3>{translation.Privacy.sub20}</h3>
      <p>{translation.Privacy.p20_1}</p>
      <h3>{translation.Privacy.sub21}</h3>
      <p>{translation.Privacy.p21_1}</p>
      <h3>{translation.Privacy.sub22}</h3>
      <p>{translation.Privacy.p22_1}</p>
    </div>
  );
}
