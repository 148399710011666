/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./css/RestaurantDetailStyle.scss";
import "./components/BusinessCardList/css/BusinessCardListStyles.scss";
import { clearRestaurantDetail, loadBusinessDetail, loadBusinessOfferListByClassification } from "./store/restaurant-detail.actions";
import { default as _, forEach } from "lodash";
import PropTypes from "prop-types";
import BusinessCardList from "./components/BusinessCardList/BusinessCardList";
import DeliveryModeSwitch from "./components/DeliveryModeSwitch/DeliveryModeSwitch";
import { selectBusinessOffersList, selectRestaurantDetailLoaded, selectRestaurantDetailObject } from "./store/restaurant-detail.reducers";
import { Typography, Box, Tabs, Tab } from "@material-ui/core";
import useTranslation from "../../translations";
import { tConvertWithoutSeconds } from "../../utils/AuxiliarFunctions";
import ModalMenu from "./components/OfferItem/ModalMenu";
import PdfIcon from "component/Icon/PdfIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import { store } from "../../App";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";
import { addOfferToCart, deleteOfferToCart, updateOfferToCart } from "modules/cart/store/cart.actions";
import AddressesModal from "component/AddressesModal/AddressesModal";
import AddAddressModal from "component/AddAddressModal/AddAddressModal";
import { DELIVERY } from "constants/delivery";
import useRestaurant from "hooks/useRestaurant";
import { selectProfile } from "modules/profile/store/profile.reducers";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import MyPurchaseButton from "component/MyPurchaseButton/MyPurchaseButton";
import { ArrowBack } from "@material-ui/icons";
import { selectBDCart } from "modules/cart/store/cart.reducers";
import { hideAlert, showAlert } from "modules/alert/store/alert.actions";
import Alert from "modules/alert/Alert";
import { doFetchProfile } from "modules/profile/store/profile.actions";
import { selectTopBarAddress } from "component/FormAddAddress/store/form-add-address.reducers";
import useAddress from "hooks/useAddress";
import { isAuth } from "App";


const BusinessDetail = (props) => {
  const { match, history, location } = props;
  const translation = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const cartBD = useSelector(selectBDCart);
  const restaurantBySlug = useSelector(selectRestaurantDetailObject);
  const restaurantBySlugLoaded = useSelector(selectRestaurantDetailLoaded);
  const businessOffersList = useSelector(selectBusinessOffersList);
  const [restaurant, setRestaurant] = useState({});
  const [offersList, setOffersList] = useState([]);
  const [offersGroupByClassification, setOffersGroupByClassification] = useState([]);
  const [totalOffers, setTotalOffers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [classification, setClassification] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [pointer, setPointer] = useState(1);
  const counter = 25;
  const [modal, setModal] = useState(null);
  const [offerModalLoaded, setOfferModalLoaded] = useState(false);
  const businessCategorySelected = store.getState().filters.filters.businessCategory;
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [addressContinueWithModal, setAddressContinueWithModal] = useState(false);
  const [deliveryType, setDeliveryType] = useState(DELIVERY);
  const [restaurantDetails, setRestaurantDetails] = useState(undefined);
  const { dispatchValidateAddress } = useAddress();
  const { fixAddress, fetchDetails } = useRestaurant();
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const topBarAddressSelected = useSelector(selectTopBarAddress);


  useEffect(() => {
    // const address = getLocalStorageAddress();
    // if (!address?.userRef ) {
    //   setShowAddressForm(true);
    // } else {
    //   setSelectedAddress(address);
    // }

    if (match.params.slug) {
      if (isAuth()) {
        dispatch(doFetchProfile());
      }
      dispatch(loadBusinessDetail({ restaurantSlug: match.params.slug }));
    } else {
      const URL = businessCategorySelected?.slug ? `${businessCategorySelected.slug}` : '../';
      history.push(URL);
    }
  }, []);

  // useEffect(() => {
  //   if (user?.accountId) {
  //     dispatch(loadDeliveryAddress());
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (addresses?.length && !getLocalStorageAddress().userRef) {
  //     setShowAddressForm(true);
  //   }
  // }, [addresses]);

  useEffect(() => {
    const address = getLocalStorageAddress();
    if (topBarAddressSelected) {
      setSelectedAddress(topBarAddressSelected);
    } else if (isAuth() && !address?.userRef) {
      setShowAddressForm(true);
    }
  }, [topBarAddressSelected]);

  useEffect(() => {
    if (selectedAddress && restaurant?.id && restaurantDetails) {
      if (isAuth()) {
        if (!selectedAddress?.userRef) {
          setShowAddressForm(true);
        } else {
          selectedAddress.restaurantId = restaurant.id;

          dispatchValidateAddress({
            ...fixAddress(selectedAddress),
            restaurantId: restaurant.id,
            clientId: 1
          });
        }
      }
    }
  }, [restaurant, selectedAddress]);

  useEffect(() => {
    if (restaurantBySlugLoaded) {
      if (restaurantBySlug?.id) {
        setRestaurant({
          ...restaurantBySlug,
          openHours: tConvertWithoutSeconds(restaurantBySlug.open),
          closeHours: tConvertWithoutSeconds(restaurantBySlug.close)
        });
        dispatch(clearRestaurantDetail());
      } else {
        history.push('../');
      }
    }
  }, [restaurantBySlug]);

  useEffect(() => {
    if (restaurant?.id) {
      let DATA = {
        restaurantId: restaurant.id,
        pointer: pointer,
        counter: counter,
        offerClassification: classification,
        offers: null
      }
      if ((location.state?.offer) || match.params.offerSlug) {
        DATA.offers = [{ slug: match.params.offerSlug || location.state.offer }];
      }
      dispatch(loadBusinessOfferListByClassification(DATA));
      loadDeliveryDetails();
    }
  }, [restaurant]);

  useEffect(() => {
    if (businessOffersList?.list) {
      let offerClassificationList = [];
      let productClassifications = restaurant.productClassifications || [];

      productClassifications.forEach(pc => {
        let classificationObj = pc;
        let offersByClassification = [];

        businessOffersList.list.forEach(offer => {
          if (pc.id && offer.classification && offer.classification.id === pc.id) {
            offersByClassification.push(offer);
          }
        });

        classificationObj["offers"] = offersByClassification;
        offerClassificationList.push(classificationObj);
      });
      setOffersList(businessOffersList.list);
      setOffersGroupByClassification(offerClassificationList);
      setTotalOffers(businessOffersList.total);
      setTotalPages(businessOffersList.pageTotal);
      window.scrollTo(0, 0);
    }
  }, [businessOffersList]);

  useEffect(() => {
    if (offersList && offersList.length > 0 && !offerModalLoaded) {
      if ((location.state && location.state.offer) || match.params.offerSlug) {
        const offerBySlug = getOfferBySlug(match.params.offerSlug || location.state.offer);
        if (offerBySlug && offerBySlug.id) {
          openModal(offerBySlug);
          setOfferModalLoaded(true);
        }
      }
    }
  }, [offersList]);

  useEffect(() => { dispatch(setLoadingMaskStatus(false)); }, [cartBD]);

  // METHODS

  const getLocalStorageAddress = () => JSON.parse(localStorage.getItem('address'));

  const loadDeliveryDetails = async (address = null) => {
    if (!address) {
      address = JSON.parse(localStorage.getItem('address'));
    } else {
      localStorage.setItem('address', JSON.stringify(address));
    }

    if (restaurant) {
      const details = await fetchDetails(address, restaurant.id);
      setRestaurantDetails(details);
    }

    setSelectedAddress(address);
  }

  const fetchMoreData = () => {
    const page = pointer + 1;
    dispatch(loadBusinessOfferListByClassification({
      restaurantId: restaurant.id,
      pointer: page,
      counter: counter,
      offerClassification: classification ? [classification] : null,
      offers: null
    }, true));
    setPointer(page);
  };

  const getOfferBySlug = (offerSlug) => {
    if (offersList && offersList.length > 0) {
      for (let i = 0; i < offersList.length; i++) {
        let offerBySlug = _.find(offersList, { slug: offerSlug });
        if (offerBySlug && offerBySlug.id) {
          return offerBySlug;
        }
      }
    }
  }

  const openModal = (offer, edit = false) => setModal(
    <ModalMenu
      offer={offer}
      edit={edit}
      restaurant={restaurant}
      onClose={() => setModal(null)}
      handleOnAddItemToCart={(o) => validateBeforeAddToCart(o)}
    />
  );

  const getBusinessProductTypes = (productType, separator) => {
    let str = "";
    forEach(productType, (val, index) => {
      if (val.id !== null && val.id !== "null") {
        str += translation.Code === 'ES' ? val.nameEs : val.nameEn;
        if (index < productType.length - 1) {
          str += separator ? separator : " ";
        }
      }
      str += "";
    });
    return str;
  };

  const handleOnClickClassificationTab = (offerClassification) => {
    setPointer(1);
    setOffersList([]);
    setOffersGroupByClassification([]);
    setClassification(offerClassification);
    dispatch(loadBusinessOfferListByClassification({
      restaurantId: restaurant.id,
      pointer: 1,
      counter: counter,
      offerClassification: offerClassification ? [offerClassification] : null,
      offers: null
    }));
  };

  const validateBeforeAddToCart = async (data) => {
    if ((isAuth())) {
      if (getLocalStorageAddress()?.userRef) {
        addOrUpdate(data?.newItem || data);
      } else {
        setShowAddressForm(true);
        setAddressContinueWithModal(false);
      }
    } else {
      localStorage.setItem('backTo', ".." + props.location.pathname);
      history.push('/login');
    }
  };

  const addOrUpdate = (offer) => {
    if (cartBD?.items?.length > 0) {
      if (cartBD?.restaurantId === restaurant?.id) {
        const offerInCart = _.find(cartBD?.items, function (item) {
          let sameAdds = false;
          let sameVariants = false;
          if (item.offerId === offer.id) {
            if (item?.adds?.length === offer?.additionsSelected?.length) {
              if (item?.adds?.map(a => a.id) === offer?.additionsSelected?.map(a => a.id)) {
                sameAdds = true;
              }
            }
            if (item?.variants?.length === offer?.variantSelected?.length) {
              if (item?.variants?.map(a => a.id) === offer?.variantSelected?.map(a => a.id)) {
                sameVariants = true;
              }
            }
            if (sameAdds && sameVariants) {
              return item;
            }
          }
        });
        if (offerInCart?.id) {
          handleUpdate({ ...offerInCart, offerCount: offer?.offerCount + 1 });
        } else {
          handleAdd(offer);
        }
      } else {
        dispatch(showAlert(translation.Cart.restaurantValidationMessage, 'Cerrar', 'Continuar',
          () => hideAlert(), () => handleAdd(offer), { required: true }));
      }
    } else {
      handleAdd(offer);
    }
  };

  const updateOrDelete = (offer) => {
    const offerInCart = cartBD?.items.find(({ id }) => offer.id === id);
    if (offerInCart?.id) {
      if (offerInCart?.offerCount > 1) {
        handleUpdate({ ...offer, offerCount: offer?.offerCount - 1 });
      } else {
        handleDelete(offerInCart);
      }
    }
  };

  const handleAdd = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(addOfferToCart({
      accountId: user?.accountId,
      restaurantId: restaurant?.id,
      deliveryOrderType: deliveryType,
      items: [
        {
          offerCount: offer?.count,
          offerId: offer?.id,
          addsIds: offer?.additionsSelected?.map(a => a.id),
          variantsIds: offer?.variantSelected?.map(v => v.id),
        }
      ]
    }, restaurant));
  }

  const handleUpdate = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(updateOfferToCart({
      accountId: user?.accountId,
      restaurantId: cartBD?.restaurantId,
      deliveryOrderType: cartBD?.deliveryOrderType,
      items: [{
        id: offer?.id,
        offerCount: offer?.offerCount,
        addsIds: offer?.adds?.map(a => a.id),
        variantsIds: offer?.variants?.map(v => v.id),
      }]
    }));
  };

  const handleDelete = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(deleteOfferToCart({
      accountId: user?.accountId,
      restaurantId: cartBD?.restaurantId,
      items: [{ id: offer?.id }]
    }));
  };

  const handleOnContinueAddress = (address) => {
    setShowAddressForm(false);
    loadDeliveryDetails(address);
    addressContinueWithModal && openModal(addressContinueWithModal);
  };

  const handleOnSavedAddress = (address) => {
    setShowAddAddressForm(false);
    setAddressToEdit(address)
    setShowAddressForm(true);
  }

  const closeAddAddressForm = () => {
    setShowAddAddressForm(false);
    setShowAddressForm(true);
  }

  const handleUpsertAddress = (address) => {
    setShowAddressForm(false);
    setAddressToEdit(address);
    setShowAddAddressForm(true);
  }

  const handleTabChange = (val) => setTabValue(val);
  const getMainImage = (restaurant) => {
    const w = window.innerWidth;
    const size = w <= 768 ? 'Sm' : (w <= 1200 ? 'Md' : 'Lg');
    return restaurant[`img${size}`];
  }

  let refs = [];
  if (restaurant && restaurant.menu) {
    refs = restaurant.menu.map(() => React.createRef(null));
  }

  const SKELETON_OFFER = <section className="restaurant_detail__menu">
    <h2><Skeleton variant="rect" width={90} height={23} animation="wave" /></h2>
    <div className="card-list-2">
      <Skeleton variant="rect" width="100%" height={144} animation="wave" />
      <Skeleton variant="rect" width="100%" height={144} animation="wave" />
      <Skeleton variant="rect" width="100%" height={144} animation="wave" />
    </div>
  </section>

  return (
    <>
      <div style={{ 'columnGap': '0' }}> {/*className="grid--sidebar"*/}
        <Helmet>
          <title>{`Mercado Vip | ${restaurant.name}`}</title>
          <meta name="description" content={restaurant?.description?.slice(0, 160)} />
        </Helmet>

        <Alert />

        <AddressesModal showDialogForm={showAddressForm} setShowDialogForm={setShowAddressForm}
          handleContinue={handleOnContinueAddress} handleAdd={handleUpsertAddress} />

        <AddAddressModal showDialogForm={showAddAddressForm} setShowDialogForm={setShowAddAddressForm} onClose={closeAddAddressForm}
          edit={!addressToEdit} selectedAddress={addressToEdit} restaurant={restaurant} onSavedAddress={handleOnSavedAddress} />

        {/* RESTAURANT DETAILS SECTION */}
        <div className="restaurant_detail">
          {/* DESCRIPTION */}
          <div className="restaurant_detail__description_container">
            <div className="restaurant_detail__description">
              <div className="restaurant_detail__description_name">
                <h2>
                  {restaurant?.id ? <span className="restaurant_detail__description_horary">
                    <PdfIcon icon="system/clock--grey" ext="svg" width="16px" />
                    {`${restaurant.openHours} - ${restaurant.closeHours}`}
                  </span> : <Skeleton variant="rect" width={148} height={20} animation="wave" style={{ margin: "5px 0" }} />}

                  {restaurant?.id ? <span className="restaurant_detail__description_horary">
                    <PdfIcon icon="system/money-cash--grey" ext="svg" width="16px" />
                    {`$${restaurant.averagePrice ? restaurant.averagePrice.lowerPrice.toFixed(2) : (0).toFixed(2)}
                    - $${restaurant.averagePrice ? restaurant.averagePrice.higherPrice.toFixed(2) : (0).toFixed(2)}`}
                    {/*<span className="sep">{'\u00A0\u00A0\u00A0/\u00A0\u00A0\u00A0'}</span>*/}
                    <span className="bullet">•</span>
                    <PdfIcon icon="system/delivery--grey" ext="svg" width="16px" />
                    {`${restaurant.deliveryTime.deliveryTime} ${translation.Code === 'ES' ? restaurant.deliveryTime.unitEs : restaurant.deliveryTime.unitEn}`}
                  </span> : <Skeleton variant="rect" width={160} height={20} animation="wave" style={{ margin: "5px 0" }} />}
                </h2>
              </div>

              {restaurant ? (restaurant?.synthesis && <div className="restaurant_detail__description_text">
                <p className="restaurant_detail__text">{restaurant.synthesis}</p>
              </div>)
                : <Skeleton variant="rect" width="97%" height={20} animation="wave" style={{ margin: "5px 0" }} />}

              {restaurant ? (restaurant.description && <div className="restaurant_detail__description_text">
                <p className="restaurant_detail__text featured">
                  {restaurant.description}
                </p>
              </div>)
                : <Skeleton variant="rect" width="97%" height={20} animation="wave" style={{ margin: "5px 0" }} />}
            </div>

            {restaurant?.id ?
              <div className="restaurant_detail__description_image"
                style={{ "background": "linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(255, 0, 0, 0)), url(" + (getMainImage(restaurant) || "./images/picture/no-image.png") + ")" }}>
                <div className="step-delivery__actions mb-20">
                  <button className="button--primary button--primary-inverted-rounded" onClick={() => history.push('/')} >
                    <ArrowBack style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                    <span>{translation.Restaurant.back}</span>
                  </button>
                </div>
                <div className="main-text">
                  <h1>{restaurant.name}</h1>
                  <h3>{getBusinessProductTypes(restaurant.productTypes, " · ")}</h3>
                </div>
              </div>
              : <div>
                <Skeleton variant="rect" width="100%" height="12.5em" animation="wave" />
              </div>
            }
          </div>

          {restaurantDetails?.deliveryType ? <DeliveryModeSwitch defaultValue={deliveryType} onChange={setDeliveryType}
            restaurantDetails={restaurantDetails} deliveryType={restaurant.deliveryType} /> : null}

          {/* TABS */}
          <div className="menu_tabs">
            <div className="container">
              <div >
                {restaurant.productClassifications && restaurant.productClassifications.length > 0
                  ? <Tabs
                    value={tabValue}
                    onChange={(ev, val) => handleTabChange(val)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    component={"div"}
                  >
                    <Tab
                      href={""}
                      key={0}
                      label={translation.General.viewAll}
                      id={0}
                      component={"div"}
                      onClick={() => handleOnClickClassificationTab(null)}
                    />
                    {restaurant.productClassifications.map((classification, idx) => (
                      <Tab
                        href={""}
                        key={idx}
                        label={translation.Code === "ES" ? classification.nameEs : classification.nameEn}
                        id={classification.id}
                        component={"div"}
                        onClick={() => handleOnClickClassificationTab(classification.id)}
                      />
                    ))}
                  </Tabs>
                  : <div style={{ display: 'flex' }}>
                    <Skeleton variant="rect" width={160} height={48} animation="wave" style={{ marginRight: "5px" }} />
                    <Skeleton variant="rect" width={160} height={48} animation="wave" style={{ marginRight: "5px" }} />
                    <Skeleton variant="rect" width={160} height={48} animation="wave" style={{ marginRight: "5px" }} />
                    <Skeleton variant="rect" width={160} height={48} animation="wave" />
                  </div>
                }
              </div>
            </div>
          </div>

          {/* CARDS */}
          {modal}
          <div className="menu-list">
            <div className="container">
              {offersGroupByClassification && offersGroupByClassification.length > 0
                ? <InfiniteScroll
                  dataLength={offersList.length}
                  next={fetchMoreData}
                  hasMore={offersList.length < totalOffers && pointer <= totalPages}
                  loader={SKELETON_OFFER}
                  scrollableTarget="root">
                  {offersGroupByClassification.map((item, key) => {
                    if (item.offers && item.offers.length > 0) {
                      return (
                        <section
                          key={key}
                          ref={refs[key]}
                          id={`menu-${key}`}
                          className="restaurant_detail__menu"
                        >
                          <h2>{translation.Code === "ES" ? item.nameEs : item.nameEn}</h2>
                          <BusinessCardList category={item} restaurant={restaurant}
                            handleOnClickOffer={(offer) => openModal(offer)}
                            handleOnAddToCart={validateBeforeAddToCart}
                            handleOnRemoveFromCart={updateOrDelete}
                          />
                        </section>
                      )
                    }
                    return null;
                  })}
                </InfiniteScroll>
                : SKELETON_OFFER}
            </div>
          </div>
        </div>

        {/* CART SECTION */}
        <div className="restaurant-detail__car">
          {isAuth() && <MyPurchaseButton {...props} />}
        </div>
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default BusinessDetail;
