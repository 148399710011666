/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./css/CuisineTypeStyle.scss";
import {
  loadBusinessGroupByProductType, loadRestaurantsCarouselsList, showAllBusinessForProductType
} from "../../../../store/filter/filter.actions";
import { selectProductTypes } from "./store/cuisine-type.reducers";
import { selectDefaultPageCounter, selectFilters } from '../../../../store/filter/filter.reducers';
import Skeleton from '@material-ui/lab/Skeleton';
import { loadBusinessCategoriesWithFilters } from "../BusinessCategories/store/business-categories.actions";
import useTranslation from "../../../../translations";

const BUSINESS = "BUSINESS";
const PRODUCT = "PRODUCT";

const CuisineType = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const translation = useTranslation();
  const filters = useSelector(selectFilters);
  const productTypesList = useSelector(selectProductTypes);
  const defaultPageCounter = useSelector(selectDefaultPageCounter);
  const [previewBusiness] = useState(8);
  const [preview] = useState(location && location.state && location.state.preview ? location.state.preview : 4);
  const [productTypes, setProductTypes] = useState([]);
  const productTypeParent = document.getElementsByClassName('cuisine-type__body');

  useEffect(() => {
    dispatch(loadBusinessCategoriesWithFilters(filters));

    const queryParams = location.search;
    setTimeout(() => {
      const str = 'cat=';
      if (queryParams?.length && queryParams.includes(str)) {
        const params = queryParams.replaceAll('?', '').split('&');
        const param = params.find(p => p.startsWith(str)).replaceAll(str, '');
        const productTypes = [parseInt(param, 10)];
        searchByProductType(productTypes);
      }
    }, 2);
  }, []);

  useEffect(() => { setProductTypes(productTypesList); }, [productTypesList]);

  const isSelected = (item) => {
    if (item.type === PRODUCT) {
      const types = filters.productTypes || [];
      return types.includes(item.id);
    } else {
      const cat = filters.categories || [];
      return cat.includes(item.id);
    }
  }

  const selectBusinessCategory = (item) => {
    setProductTypes([]);
    // dispatch(loadAllProductTypes());
    dispatch(showAllBusinessForProductType(filters.productTypes && filters.productTypes.length > 0));
    dispatch(loadBusinessGroupByProductType(previewBusiness, {
      ...filters,
      businessCategory: null, //isSelected(item) ? null : item,
      categories: [], // isSelected(item) ? [] : [item.id],
      productTypes: []
    }, 1, defaultPageCounter));
  }

  const selectProductType = (item) => {
    let types = filters.productTypes || [];
    if (isSelected(item)) {
      types = types.filter(itemId => itemId !== item.id)
      setOldPlace(item.id);
    } else {
      types.push(item.id);
      setNewPlace(item.id);
    }
    searchByProductType(types);
  }

  const setOldPlace = (id) => {
    const data = productTypeParent[0]
    if (data.hasChildNodes) {
      for (var i = (data.children.length - 1); i >= 0; i--) {
        if (id === parseInt(data.children[i].getAttribute('name').substring(4))) {
          data.insertBefore(data.children[i], data.children[parseInt(data.children[i].getAttribute('data-name').substring(4))].nextSibling);
        }
      }
    }
  }

  const setNewPlace = (id) => {
    let data = productTypeParent[0]
    if (data?.hasChildNodes) {
      for (var i = (data.children.length - 1); i >= 0; i--) {
        if (id === parseInt(data.children[i].getAttribute('name')?.substring(4))) {
          data.insertBefore(data.children[i], data.firstChild);
          scrollTo(data.children[i]);
        }
      }
    }
  }

  const orderProductType = () => items.map((item) => (isSelected(item) ? setNewPlace(item.id) : null));

  const scrollTo = (el) => {
    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

    // check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = elLeft - elParentLeft;
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
    }
  }

  const searchByProductType = (types) => {
    const filtersAux = { ...filters, productTypes: types };
    dispatch(showAllBusinessForProductType(types && types.length > 0));
    if (types && types.length > 0) {
      dispatch(loadRestaurantsCarouselsList(preview, filtersAux, 1, defaultPageCounter));
    } else {
      dispatch(loadBusinessGroupByProductType(preview, filtersAux, 1, defaultPageCounter));
    }
  }

  const getSearchByProductTypeText = () => {
    let result = filters.productTypes.map((item) => (
      productTypes.map((prod, index) => (
        prod.id === item ? (translation.Code === "ES" ? String(' ' + productTypes[index]?.nameEs) : String(' ' + productTypes[index]?.nameEn)) : null
      )).join('')
    ))

    return String(result)
  }

  const regular = 'cuisine-type__body__item';
  const selected = `${regular} cuisine-type__body__item--selected`;

  const SKELETON_COLUMN = <div className="cuisine-skeleton__column">
    <Skeleton variant="rect" width={64} height={64} animation="wave" />
    <Skeleton variant="rect" width={64} height={23} animation="wave" />
  </div>;

  let items = [];
  // NOTE: Hide business category for the moment (its only one, restaurantes)
  // if (businessCategoriesList?.length) {
  //   items = businessCategoriesList.map(cat => ({ ...cat, name: translation.Code === "ES" ? cat.nameEs : cat.nameEn, type: BUSINESS }));
  // }
  if (productTypes?.length) {
    items = [...items, ...productTypes.map(prod => ({ ...prod, name: translation.Code === "ES" ? prod.nameEs : prod.nameEn, type: PRODUCT }))];
  } else if (items && items.length > 0) {
    items = [...items, 'skeleton'];
  }

  return (
    <div>
      {items ?
        // <Swiper {...params} ref={swiperRef}>
        <>
          <div className="cuisine-type__body">
            {items.map((item, index) => (
              item === 'skeleton'
                ? <div className="cuisine-skeleton__row">
                  {[...Array(5)].map(() => SKELETON_COLUMN)}
                </div>
                : <div className={isSelected(item) ? selected : regular} name={'cte-' + item.id} data-name={'cte-' + index} key={index}
                  onClick={() => item.type === BUSINESS ? selectBusinessCategory(item) : selectProductType(item)}>
                  <div className="crop-image">
                    <img src={item.type === BUSINESS ? item.icon : item.image} alt={item.name} />
                  </div>
                  <span>{isSelected(item) ? <FontAwesomeIcon icon={['fas', 'check']} style={{ marginRight: "4px", color: "#1e4acc" }} /> : null}{item.name}</span>
                </div>
            ))}
            {orderProductType()}
          </div>
          {filters.productTypes && filters.productTypes.length > 0 ?
            <div className="cuisine-type__search-info">
              <p>Resultados de: <span>{getSearchByProductTypeText()}</span></p>
            </div>
            : null}
        </>
        // </Swiper> 
        : <div className="cuisine-skeleton__row">
          {[...Array(10)].map(() => SKELETON_COLUMN)}
        </div>}
    </div>
  );
}

export default CuisineType;