import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './utils/fontawesome';


Sentry.init({
  dsn: "https://82dca91097a54ef8919389399f2ac457@o506399.ingest.sentry.io/5644753",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});

ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.unregister();