import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import useTranslations from "../../translations/useTranslations";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        flexGrow: 1,
        background: "transparent !important",
        marginBottom: "1em !important",
    },
    input: {
        '&::placeholder': {
            color: "gray",
            fontStyle: 'italic !important',
        },
        display: "flex",
        height: "39px !important",
        background: "transparent !important",
    },
    helperText: {
        fontSize: ".8em",
    },
    errorText: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "red",
    },
    lengthMessage: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "orange",
    }
}));

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#2c2c2c"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#2c2c2c"
        },
        "& .MuiOutlinedInput-root": {
            background: "transparent !important",
            "& fieldset": {
                borderRadius: 5
            },
            "&.Mui-focused fieldset": {
                borderColor: "#2c2c2c"
            }
        }
    }
})(TextField);

export default function CustomInput(props) {
    const classes = useStyles();
    const translation = useTranslations();
    const [showLengthMessage, setShowLengthMessage] = useState(false);
    const [lengthMessage, setLengthMessage] = useState('');

    useEffect(() => {
        let message = '';
        if (props.value && props.value.length > 0) {
            if (props.multiline) {
                if (props.value.length >= props.longLength) {
                    setShowLengthMessage(true);
                    message = translation.Validations.maxLength + " (" + props.longLength + ")";
                } else {
                    setShowLengthMessage(false);
                }
            } else {
                if (props.value.length >= props.standardLength) {
                    setShowLengthMessage(true);
                    message = translation.Validations.maxLength + " (" + props.standardLength + ")";
                } else {
                    setShowLengthMessage(false);
                }
            }
        }
        setLengthMessage(message);
        // eslint-disable-next-line
    }, [props.value]);

    function onChange(v) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    function onBlur(v) {
        if (props.onBlur) {
            props.onBlur(v);
        }
    }

    return (
        <div className={classes.root}>
            <CssTextField
                fullWidth
                id={props.id}
                value={props.value}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder}
                rows={props.rows}
                rowsMax={props.rowsMax}
                type={props.type}
                multiline={props.multiline}
                onChange={v => onChange(v)}
                onBlur={v => onBlur(v)}
                className={classes.textField}
                variant="outlined"
                label={props.fieldLabel}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    inputProps: {
                        maxLength: props.multiline ? props.longLength : props.standardLength,
                        classes: classes.input,
                        inputRef: props.innerRef,
                        children: props.children,
                        ...props.innerProps
                    },
                    endAdornment: props.endAdornment
                }}
            />
            {props.errorMessage
                ? <FormHelperText id="component-helper-text" className={classes.errorText}>
                    {props.errorMessage}
                </FormHelperText>
                : showLengthMessage
                    ? <FormHelperText id="component-helper-text" className={classes.lengthMessage}>
                        {lengthMessage}
                    </FormHelperText>
                    : props.helperText
                        ? <FormHelperText id="component-helper-text" className={props.helperTextStyle || classes.helperText}>
                            {props.helperText}
                        </FormHelperText>
                        : null}
        </div>
    );
}

CustomInput.defaultProps = {
    fieldLabel: "",
    type: "text",
    placeholder: null,
    helperText: null,
    helperTextStyle: null,
    error: false,
    errorMessage: null,
    autoFocus: false,
    multiline: false,
    standardLength: 100,
    longLength: 500,
};