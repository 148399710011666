import { TASA_PESO } from "../../constants";
import { Translation } from "./types";

export default {
  Code: "ES",
  ConnectedTo: "Conectado a id",
  Validations: {
    fieldRequired: "Campo Requerido",
    required: "Campo requerido",
    integer: "No es un entero válido",
    number: "Número inválido",
    email: "Dirección de correo inválida",
    password: "La contraseña debe tener más de 6 caracteres",
    us_phone_number: "Número de teléfono inválido",
    date: "Fecha seleccionada inválida",
    length: "Mínimo número de caracteres mayor que",
    maxLength: "Ha alcanzado el máximo número de caracteres permitidos",
    special_characters: "No se permiten caracteres especiales",
    form: "Existen errores en el formulario",
    incomplete_phone_number: "Número de teléfono incompleto",
    login: "Credenciales incorrectas",
  },
  Actions: {
    new: "Nuevo",
    close: "Cerrar",
    cancel: "Cancelar",
    accept: "Aceptar",
    apply: "Aplicar",
    save: "Guardar",
    continue: "Continuar",
  },
  General: {
    seeMore: "Ver más",
    viewAll: "Ver todos",
    viewRestaurants: "Ver Restaurantes",
    readFullArticle: "Read the full article",
    notAvailable: "No disponible",
    noMatchesFoundFor: "No se han encontrado resultados para",
    tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
    noResultList: "Sin resultados que mostrar",
    defaultAlertTitle: "Alerta",
  },
  Pagination: {
    labelRowsPerPage: "Filas por página",
  },
  TopBar: {
    linkDelivery: "A domicilio",
    linkHelp: "Ayuda",
    linkHome: "Home",
    linkCll: "Cuballama",
    linkRestaurant: "Restaurantes",
    linkReserve: "Reservar",
    btnLogin: "Regístrate o inicia sesión",
    btnLogout: "Cerrar Sesión",
    btnProfile: "Mi cuenta",
    linkPersonalData: "Datos personales",
    linkPreferredAddresses: "Direcciones preferidas",
    linkCardManagement: "Gestión de tarjetas",
    linkPurchaseHistory: "Histórico de pedidos",
    btnRegister: "Crear cuenta",
    btnMyProfile: "Mi Perfil",
    optionDelivery: "Domicilio",
    optionPickup: "Recogida",
    deliveryModeDetails: "Detalles de la entrega",
    searchPlaceholder: "Buscar...",
    search: "Buscar",
    cart: "Carrito",
  },
  Address: {
    selectAddress: "Coloque la dirección",
    deliverIn: "Entregar en",
    deliverAt: "Entregar a",
  },
  Home: {
    labelSelectMunicipality: "Dónde quieres que entreguemos",
    sectionBusinessCategoryTitle: "Encuentra todo lo que necesitas",
    sectionBusinessCategorySubtitle:
      "Pulsa sobre la tienda que desees y comienza a comprar",
    sectionHeaderMobileTitle: "Comida a domicilio en Cuba y más",
    tabTitleDefault: "Mercado Vip | Todo lo que necesitan en Cuba a un clic",
    tabTitleRestaurant: "Comida a domicilio en Cuba y mucho más! | Mercado Vip",
    tabTitleSupermarket: "Mercado Vip | Supermercado | Combos y más!",
    metaDefault:
      "Mercado Vip. Restaurantes, Supermercado y Tiendas con todo lo que necesiten tus familiares en Cuba con las mejores ofertas. ",
    metaRestaurant:
      "Con Mercado Vip los mejores platos cubanos, ahora hasta la puerta de tu casa. La mejor comida a domicilio en Cuba.",
    metaSupermarket:
      "Supermercado de Mercado Vip. Combos y muchó más!. Entrega en 1 o 2 días!",
    sectionHeaderTitleDefault: "Mercado Vip.",
    sectionHeaderTitleRestaurant:
      "Mercado Vip: La mejor comida a domicilio en Cuba y mucho más",
    sectionHeaderTitleSupermarket: "Sumpermercado.",
    sectionHeaderTitle1: "Elige qué van a comer hoy, ",
    sectionHeaderTitle2: "Tú invitas, nosotros entregamos",
    sectionHeaderSubtitle1: "Selecciona restaurante",
    sectionHeaderSubtitle2: "Crea la orden",
    sectionHeaderSubtitle3: "Reciben en casa",
    sectionHeaderHomeDeliveryOption: "Entrega a domicilio",
    sectionHeaderPickOrderOption: "Recoger pedido",
    sectionHeaderSearchButton: "Buscar",
    sectionFoodCategoryTitle: "¿Qué tipo de comida buscas?",
    sectionPopularsTitle: "Los más populares",
    sectionTrendingTitle: "Tendencias en La Habana",
    sectionSubscriptionTitle: "¡No te pierdas ni una!",
    sectionSubscriptionSubtitle:
      "Suscríbete a nuestro boletín para estar al día de las mejores promociones.",
    sectionSubscriptionButton: "Quiero suscribirme",
    sectionSubscriptionInputPlaceholder: "Correo",
    sectionPressTitle: "Mercado Vip en la prensa",
    defaultPageMessageTitle:
      "Envío de comida a domicilio en Cuba y de todo para tu familia",
    defaultPageMessageContent:
      "Mercado Vip es una solución todoterreno para cuidar de tu familia con entrega de comida a domicilio en Cuba hasta en el mismo día. Comida… y mucho más: además de los combos de alimentos, las cenas y los productos del agro, tenemos electrodomésticos, artículos de aseo, higiene y para el hogar. Para combatir desde los antojos hasta el apagón cuando se les antoje. Cuando estás en un apuro, pide la entrega de comida a domicilio en Cuba, y si no, también. Mercado Vip, una sola familia cubana.",
  },
  OfferCarousel: {
    title: "Ofertas especiales",
    seeAll: "Ver todas",
  },
  RestaurantCarousel: {
    since: "Desde",
    minute: "Min",
    title: "Comida a domicilio en La Habana",
    seeAll: "Ver todos",
  },
  PromotionsCarousel: {
    carouselTitle: "Ofertas de Hoy",
  },
  RestaurantList: {
    sectionFilterButton: "Filtrar",
    filterDialogHeader: "Filtros",
    filterTitle: "Estas filtrando por",
    eraseAll: "Borrar todo",
    noResult: "Sin resultados que mostrar",
    title: "Entrega a domicilio",
    restaurant: "restaurantes",
  },
  Filter: {
    sort: "Ordenar",
    atHome: "A Domicilio",
    pickup: "Recogida",
    promotion: "Promoción",
    dietIntolerance: "Dieta/Intolerancia",
    cuisineType: "Tipo de cocina",
    eraseAll: "Borrar todos",
    apply: "Aplicar",
    deliverAt: "Entregar en",
    restaurantName: "Nombre del restaurante",
    deliverTime: "Tiempo de entrega",
    bestScore: "Mejor puntuación",
    averagePrice: "Precio promedio",
  },
  Restaurant: {
    hours: "Horario",
    menuOfDay: "Menú del día",
    back: "Volver",
    atHome: "A domicilio",
    pickup: "Recogida",
    thisIsYourRequest: "Este es su pedido",
    viewMyRequest: "Ver mi pedido",
    backToMenu: "Ir al Menú",
    completeRequest: "Completar Pedido",
    evaluations: "valoraciones",
    more: "Más",
  },
  Login: {
    info: "Accede a tu cuenta o crea una nueva para poder finalizar el proceso de pago.",
    welcome: "Crea o accede a tu cuenta",
    indentifyWithCuballama:
      "Para acceder a tu cuenta o crear una nueva indícanos tu celular.",
    header:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    emailField: "Correo electrónico",
    emailHeader: "Correo electrónico del cliente",
    emailSubHeader:
      "Por favor introduzca una dirección de correo electrónico válida",
    backToLogin: "Volver a empezar",
    needToLogin: "Necesita autenticarse para acceder a esta acción",
    phoneNumberField: "Teléfono",
    passwordField: "Contraseña",
    pinHeader: "Validar teléfono",
    pinSubHeader: "Introduce el código de validación que te hemos enviado al",
    pin: "Pin",
    pinAction: "Validar",
    pinMsg: "Se ha procedido a enviar un codigo PIN a su telefono",
    pinNotReceived: "No recibiste el código?",
    pinHelper: "Solo 4 caracteres",
    verificationHeader: "Completa la verificación",
    verificationSubHeaderPart1: "Te hemos enviado un correo a ",
    verificationSubHeaderPart2:
      "Sigue las instrucciones del email para verificar tu cuenta.",
    btnSuccessfulVerification: "Lo he verificado",
    btnEmailNotReachable: "No tengo acceso al email",
    alertVerificationTitle: "Por favor, valida tu email",
    alertVerificationMessage:
      "Por tu seguridad te hemos enviado un correo electrónico para verificar que eres tú. Por favor, comprueba tu bandeja de entrada y pulsa el enlace que encontrarás adjunto. Una vez lo hagas vuelve a esta página y pulsa continuar para completar tu acceso.",
    rememberMe: "Recuérdame",
    haveYouForgotPassword: "¿Has olvidado tu contraseña?",
    loginAction: "Continuar",
    registerInvitation: "Te enviaremos un SMS para verificar tu teléfono",
    createItHere: "Créala aquí",
    phonePlaceHolder: "Prefijo con teléfono",
    wait: "Espera",
    seconds: "segundos",
    resendCode: "Reenviar código",
  },
  Register: {
    createCuballamaAccount: "Crea una cuenta Mercado Vip",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    info2:
      "En unos segundos recibirás tu PIN. Si has utilizado un teléfono móvil lo recibirás en un SMS, en caso contrario, por una llamada.",
    phoneField: "Teléfono",
    validateAction: "Validar Teléfono",
    alreadyRegistered: "¿Ya tienes una cuenta en Mercado Vip?",
    introPinOrGetACall:
      "Introduce el PIN que te hemos enviado por SMS o llamada al",
    modifyAction: "Modificar",
    pinReceived: "Pin recibido",
    password: "Contraseña",
    email: "Correo Electrónico",
    thisDataIsOptional: "Este dato es opcional",
    conditionPart1: "Al crear tu cuenta aceptas las",
    conditionPart2: "condiciones legales",
    conditionPart3: "y la",
    conditionPart4: "política de privacidad",
    conditionPart5: "de Mercado Vip",
    registerAction: "Crear Cuenta",
    resendCodeTitle: "¿No has recibido el PIN?",
    receivePinAgain: "Elige como deseas recibir el código",
    receivePinPerCall: "Por Teléfono",
    receivePinBySms: "Por SMS",
    receivePinByWhatsApp: "Por WhatsApp",
    talkToAnAgent: " Habla con un agente",
    cancel: "Cancelar",
  },
  RecoverPassword: {
    title1: "Identifícate con tu cuenta Mercado Vip",
    title2: "Identifícate con tu cuenta Mercado Vip",
    helperText:
      "En unos segundos recibirás tu PIN. Si has utilizado un teléfono móvil lo recibirás en un SMS, en caso contrario, por una llamada.",
    goBackToLogin: "¿Ya recuerdas la contraseña?",
    phoneField: "Teléfono",
    pinReceived: "Pin recibido",
    password: "Contraseña",
    sendPin: "Enviar PIN",
    usernameField: "Teléfono",
    modifyAction: "Modificar",
    setPasswordAction: "Cambiar contraseña",
    resendCodeTitle: "¿No has recibido el PIN?",
    getPinBySms: "Recibir PIN por SMS",
    getPinByCall: "Recibir PIN por llamada",
    talkToAnAgent: "Hablar con un agente",
  },
  Payment: {
    codes: {
      TRANSACTION_NOT_ALLOWED:
        "Transacción fallida, por favor inténtelo nuevamente",
      DECLINED: "Transacción declinada, por favor contacte con su Banco",
      PROCESSOR_DECLINED:
        "Transacción declinada, por favor contacte con su Banco",
      EXPIRED_CARD: "Tarjeta Expirada, por favor revise su método de pago",
      INVALID_TRANSACTION:
        "Transacción inválida, por favor inténtelo nuevamente",
      GATEWAY_REJECTED: "Transacción rechazada por autorización bancaria",
    },
    failureTitle: "No se ha podido procesar el pago",
    failureSubtitle: "Inténtalo de nuevo o selecciona otra forma de pago.",
    successTitle: "El pago se ha completado con éxito",
    successSubtitle: "A continuación mostramos los detalles de la compra.",
    viewMyRequest: "Ver mi pedido",
    thisIsMyRequest: "Este es su pedido",
    haveAnyDiscount: "¿Tienes un cupón de descuento?",
    couponApplied: "Cupón de descuento aplicado",
    invalidCoupon: "Cupón no válido",
    discountCode: "Código de descuento",
    apply: "Aplicar",
    clearCoupon: "Eliminar",
    subtotal: "Subtotal",
    shipping: "Coste de envío",
    freeShipping: "Gratis",
    taxes: "Tarifa de servicio",
    discount: "Descuento (Cupón)",
    bonus: "Descuento (Bonificación)",
    coupon: "Cupón",
    total: "Total",
    pending: "A calcular",
    daily: "Diario",
    modify: "Modificar",
    modifyRequest: "Modificar Pedido",
    continueCheckout: "Continuar con Checkout",
    deliveryData: "Datos de Entrega",
    deliveryDate: "Entrega",
    pay: "Pagar",
    addCard: "Guardar tarjeta de crédito",
    addAndPay: "Guardar tarjeta de crédito y pagar",
    evaluations: "valoraciones",
    addressNotAvailable: "Dirección no disponible.",
    validatingAddress: "Validando dirección . . .",
    invalidDeliveryAddress:
      "El restaurante seleccionado no realiza envíos a esta dirección.",
    validDeliveryAddress:
      "La dirección seleccionada para esta entrega es válida.",
    setYourAddress: "Indicanos tu dirección",
    addressNewAddress: "Añadir nueva dirección",
    addDeliveryAddress: "Adicionar dirección de entrega",
    setDeliveryAddress: "Seleccione dirección de entrega",
    deliveryAddress: "Dirección de entrega",
    address: "Dirección",
    deliveryAddressHint:
      "Incluye el nombre y el número de tu calle, así como la zona o barrio",
    municipality: "Municipio",
    city: "Ciudad",
    name: "Nombre",
    phone: "Teléfono",
    date: "Fecha",
    time: "Hora",
    labelPickup: "recogida",
    labelDelivery: "entrega",
    labelDateAndTimeOf: "Fecha y hora de ",
    clickToSelectDates: "Seleccione la fecha de entrega",
    deliveryWarning:
      "Asegúrese de que haya alguien para recepcionar el pedido en la fecha y hora señaladas.",
    confirmDateDialogTitle: "Este pedido será entregado el ",
    confirmDateDialogMessage: "¿Desea continuar?",
    confirmDateDialogButtonOk: "",
    confirmDateDialogButtonCancel: "",
    confirm: "Confirmar",
    confirmAndPay: "Confirmar y pagar",
    paymentInProgressMessage:
      "Estamos procesando tu pago. El proceso puede tomar hasta 1 minuto. No cierres esta pantalla hasta obtener una respuesta.",
    availableCredit: "Saldo Mercado Vip",
    cashPaymentOption: "Efectivo",
    cashPaymentOptionLabel: "Pagar en efectivo en la entrega",
    makeYourPaymentsWithCash: `Este pago se realizará en la moneda local CUP al cambio ${TASA_PESO} CUP x 1`,
    addCreditCard: "Adicionar Tarjeta de Crédito",
    goBack: "Volver",
    asap: "Lo antes posible",
    contactData: "Datos de contacto",
    discountedBalance: "Saldo descontado",
    remainingBalance: "Saldo restante",
    transactionId: "Id Transacción",
    creditCard: "Tarjeta de Crédito",
    paypalAccount: "Cuenta de Paypal",
    expirationDate: "Expiración",
    cart: "Carrito",
    paymentId: "Id de Pago",
    paymentDoneMessage: "Su pago ha sido realizado",
    paymentErrorMessage:
      "Su pago no se realizó debido a un error durante la transacción",
    paymentCancelMessage:
      "Ha cancelado la transacción de pago, puede volver al pago inicial, dando clic en el botón abajo",
    message: "Mensaje",
    restaurant: "Restaurante",
    estimatedDeliveryTime: "Entrega ~ ",
    acceptTerms:
      "Al continuar, usted COMPRENDE y ACEPTA que no es posible CANCELAR ni MODIFICAR su pedido.",
    estimatedTime: " Min Aproximados",
    delivery: "Entrega",
    pickUp: "Recogida",
    prices: "Precios",
    estimatedTimeShort: "minutos",
    minDeliveryCost: "Envío ~ ",
    approx: "(Aprox)",
    creditCardEndWith: "Tarjeta terminada en",
    payWithCard: "Pago con Tarjeta",
    payWithTuBolsillo: "Pago con Tu Bolsillo",
    edit: "Editar",
    cancelEdit: "Cancelar",
    addPaymentMethod: "Añadir Nueva",
    warningMessageBeforePay:
      "Al pulsar el botón 'Pagar' acepto el pago de la compra y sus condiciones de entrega",
    titleDeliveryConditions: "Condiciones de entrega",
    titleSelectPaymentMethod: "Forma de pago",
    payRestWith1: "Pagar",
    payRestWith2: "restantes con",
    titleResumeBeforePayment: "Completar la compra",
    newPaymentMethod: "Añadir nueva forma de pago",
    alertDeletePaymentMethod:
      "Esta a punto de borrar este método de pago. Está seguro que es esto lo que desea?",
    alertSelectPaymentMethod:
      "Por favor seleccione un método de pago para continuar con su compra",
    paymentMethodAdded: "Método de pago añadido",
    closeAddMethodDialog: "Cerrar",
    paymentMethodCancelled: "Método de pago cancelado",
    paypalAccountAdded: "Cuenta de Paypal añadida",
    attention: "Atención",
    contacts: "Contactos",
    errors: {
      "no valid date.": "Fecha no valida",
      "no valid value.": "Valor incorrecto",
      "No card number provided.": "Número de tarjeta requerido",
      "Credit card number is in invalid format.":
        "El número de tarjeta de crédito tiene formato incorrecto",
      "Warning! This credit card number is associated with a scam attempt.":
        "Atención! Este número de tarjeta de crédito esta asociado con intentos de estafa",
      "Credit card number has an inappropriate number of digits.":
        "El número de tarjeta de crédito tiene caracteres inapropiados",
      "Do Not Honor.":
        "Su banco no quiere aceptar la transacción actual, por favor contacte a su banco",
      "Insufficient Funds.": "La cuenta no tiene fondos suficientes",
      "Limit Exceeded.":
        "Los intentos de transacción han excedido el limite para esta cuenta",
      "Cardholder's Activity Limit Exceeded.":
        "Los intentos de transacción han excedido el limite para esta cuenta",
      "Expired Card.": "La tarjeta ya expiró",
      "Invalid Credit Card Number.": "Número de tarjeta de crédito no válido",
      "Invalid Expiration Date.": "Fecha de expiración no valido",
      "No Account.":
        "El número de tarjeta de crédito no es reconocido por su banco, por favor contacte a su banco para más información",
      "Card Account Length Error.": "Número de tarjeta con longitud incorrecta",
      "Card Issuer Declined CVV.": "Código de seguridad incorrecto",
      "Processor Declined – Possible Lost Card.":
        "Tarjeta declinada, posiblemente haya sido reportada como perdida, por favor contactar con el banco",
      "Processor Declined – Possible Stolen Card.":
        "Tarjeta declinada, posiblemente haya sido reportada como robada, por favor contactar con el banco",
      "Processor Declined – Fraud Suspected.":
        "Tarjeta declinada por sospecha de fraude, por favor contacte con su banco",
      "Transaction Not Allowed.":
        "Transacción no permitida por el banco, probablemente se deba a un problema con su tarjeta",
      "Duplicate Transaction.":
        "Transacción duplicada. Se cancela para evitar cobros duplicados",
      "Invalid Transaction.": "Su banco declinó la transacción",
      "Declined – Updated Cardholder Available.":
        "Tarjeta Declinada, esta expiró o fue reportada como perdida y se emitió una nueva como reemplazo",
      "Processor Does Not Support This Feature.":
        "Tu cuenta no permite procesar este tipo de transacción",
      "Card Type Not Enabled.": "Tipo de tarjeta no permitida",
      "Set Up Error – Card.": "Ocurrió un problema con la tarjeta emitida",
      "Processor Declined.":
        "Transacción declinada por el banco, por favor contacte con su banco",
      "Invalid Authorization Code.": "Código de autorizo no encontrado",
      "Declined – Call For Approval.":
        "Transacción declinada, la tarjeta usada requiere confirmación del cliente, por favor contacte con su banco",
      "Declined – Call Issuer.":
        "Transacción declinada por el banco que emitió la tarjeta. Intente nuevamente o de lo contrario contacte con su banco",
      "Declined.":
        "Transacción declinada por el banco, por favor contacte con su banco",
      "Invalid Credit Plan.": "Plan de crédito no valido",
      "Credit Card Number does not match method of payment.":
        "El número de tarjeta no coincide con el método de pago",
      "Card reported as lost or stolen.":
        "Esta tarjeta fue reportada como perdida o robada, por favor contacte con su banc",
      "Address Verification Failed.":
        "PayPal no pudo verificar porque la dirección no tiene formato correcto",
      "Address Verification and Card Security Code Failed.":
        "Los chequeos de AVS y CVV fallaron para esta transacción",
      "PayPal Business Account preference resulted in the transaction failing.":
        "PayPal business account resultó en una transacción fallida",
      "PayPal Business Account Restricted.":
        "PayPal business account restringida, por favor contacte al equipo de soporte de Paypal",
      "Authorization Expired.": "El código de autorización de PayPal expiró",
      "PayPal Business Account Locked or Closed.":
        "PayPal business account ha sido bloqueada o cerrada, por favor contacte al equipo de soporte de Paypal",
      "PayPal Buyer Revoked Pre-Approved Payment Authorization.":
        "PayPal revoco el pre-aprobado de esta cuenta",
      "PayPal Payee Account Invalid Or Does Not Have a Confirmed Email.":
        "Su cuenta de PayPal no es válida o no tiene un email confirmado",
      "PayPal Payee Email Incorrectly Formatted.":
        "Su cuenta de PayPal tiene el email con formato no válido",
      "PayPal Validation Error.":
        "PayPal no puede aprobar esta transacción, por favor contacte al equipo de soporte de Paypal",
      "Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment.":
        "Su cuenta de PayPal ha sido cancelada, por favor contacte al equipo de soporte de Paypal",
      "Payer Account Is Locked Or Closed.":
        "Su cuenta de PayPal no puede ser usada para transacciones en estos momentos, por favor intentelo nuevamente o contacte con el equipo de soporte de Paypal",
      "PayPal Transaction Limit Exceeded.":
        "Límite de transacción de PayPal excedido",
      "PayPal payment method is invalid.":
        "Esta cuenta de PayPal ha sido cancelada o bloqueada",
      "PayPal authorization amount limit exceeded.":
        "El límite de autorizo de PayPal ha sido excedido",

      "Credit card type is not accepted by this merchant account.":
        "Esta tarjeta de crédito no es aceptada por este comercio",
      "CVV is required.": "CVV requerido",
      "CVV must be 4 digits for American Express and 3 digits for other card types.":
        "CVV debe ser de 4 dígitos para American Express y 3 dígitos para el resto",
      "Expiration date is required.": "Fecha de expiración requerida",
      "Expiration date is invalid.": "Fecha de expiración no válida",
      "Expiration year is invalid. It must be between 1975 and 2201.":
        "El año en fecha de expiración no es válido",
      "Expiration month is invalid.":
        "El mes en fecha de expiración no es válida",
      "Expiration year is invalid.":
        "El año en fecha de expiración no es válida",
      "Credit card number is required.": "Número de tarjeta requerido",
      "Credit card number is invalid.": "Número de tarjeta no válido",
      "Credit card number must be 12-19 digits.":
        "Número de tarjeta debe tener de 12-19 dígitos",
      "Credit card number is not an accepted test number.":
        "Número de tarjeta no aceptado como número de prueba",
      "Duplicate card exists in the vault.": "Número de tarjeta duplicado",
      "CVV verification failed.": "CVV verificación fallida",
      "Credit card number is prohibited.": "Número de tarjeta prohibido",
      "Required attribute is missing": "Atributo requerido",
      "Incomplete PayPal account information.":
        "Información de PayPal incompleta",
      "Invalid PayPal account information.": "Información de PayPal no válida",
      "Pre-Approved Payment enabled PayPal account required for vaulting.":
        "Pre-aprobación de cuenta Paypal requerida para poder ser usada",
      "Error communicating with PayPal.": "Error comunicando con PayPal",
      "PayPal authentication expired.": "Autenticación de PayPal expirada",
      "Amount is required.": "Monto requerido",
      "Attribute is not in the required format":
        "Atributo con formato no válido",
    },
  },
  PaymentSuccess: {
    contactName: "Receptor en Cuba",
    deliveryAddress: "Dirección de entrega",
    pickUpAddress: "Dirección de recogida",
    telephone: "Teléfono",
  },
  Profile: {
    personalData: "Datos personales",
    favoriteAddresses: "Mis direcciones",
    logout: "Cerrar sesión",
    cancel: "Cancelar",
    save: "Guardar",
    fullname: "Nombre y Apellidos",
    change: "Cambiar",
    email: "Correo electrónico",
    passwordMustMatch: "Contraseña debe coincidir",
    mainPhone: "Teléfono principal",
  },
  UserAddress: {
    contactName: "Nombre del Receptor en Cuba",
    contactNamePlaceholder: "Quién recibirá el pedido",
    telephoneFieldLabel: "Teléfono",
    editingAddress: "Editar dirección de entrega",
    deliveryAddress: "Nueva dirección de entrega",
    myUsualAddresses: "Mis direcciones habituales",
    addNew: "Añadir Nueva",
    noAddressesFound: "No tienes direcciones añadidas",
    tag: "Etiqueta",
    tagHint: "Identifica tu dirección (casa, trabajo,…)",
    details: "Dirección",
    mainStreet: "Calle Principal",
    typeToSearch: "Escriba para buscar...",
    typeToSearchHint:
      "Incluye el nombre y el número de tu calle; así como la zona o barrio ",
    betweenStreet: "Entre Calle",
    andStreet: "Y Calle",
    building: "Número de Casa o Edificio",
    stairs: "Escalera",
    number: "Apartamento",
    province: "Provincia",
    municipality: "Municipio",
    location: "Barrio",
    additionalIndications: "Indicaciones adicionales",
    additionalIndicationsHint:
      "Por favor, añade cualquier indicación adicional que ayude al repartidor a encontrar tu dirección.",
    storeAddressInMyUsualAddresses:
      "Guardar esta dirección en mis direcciones habituales.",
    requiredFields: "Campos obligatorios",
    save: "Guardar",
    confirm: "Confirmar",
    loading: "Cargando",
    noMatchFound: "No se encontraron coincidencias",
    noMatchesFoundFor: "No se han encontrado resultados para",
    tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
    userAddressFormBtnCancel: "Cancelar",
    userAddressFormBtnSave: "Guardar",
    userAddressFormBtnConfirm: "Confirmar",
  },
  CardManagement: {
    cardListTitle: "Mis tarjetas de crédito",
    cardsNoFound: "No se encontraron tarjetas de crédito",
    addCardFormTitle: "Adicionar tarjeta de crédito",
    editCardFormTitle: "Actualizar datos de la tarjeta",
    buttonAddLabel: "Guardar tarjeta de crédito",
  },
  PurchaseHistory: {
    title: "Historial de pedidos",
    description:
      "Pulsa sobre el pedido que desees repetir y automáticamente se cargarán los platos, cantidades y precios en el carrito.\n Antes de pagar, verifica que todos los datos son correctos y la orden se tramitará de inmediato.",
    contactName: "Nombre",
    mostRecentlyFirst: "Más actuales primero",
    oldestFirst: "Más antiguos primero",
    resumeRequest: "Resumen del pedido",
    total: "Total",
    purchaseAgain: "Volver a pedir",
    noPurchasesToShow: "No tiene compras realizadas",
    orderId: "Orden:",
    deliveryAddress: "Dirección de entrega",
    itemBy: " artículo por ",
    itemsBy: " artículos por ",
    more: "Mostrar más",
    deliveredTo: "Entregado a:",
    date: "Fecha:",
    created: "Creado",
    delivered: "Entregado",
    status: "Estado:",
    resendOrder: "Repetir orden",
    fullOrderDialogTitle: "Este es el resumen completo de tu pedido",
    orderPayType: "Pedido pagado con:",
    deliveryDate: "Fecha de Entrega",
  },
  Offer: {
    additionsTitle: "Agregos",
    additionsActionAdd: "Agregar",
    variantTitle: "Variantes",
    variantTitleNote: "Seleccione una opción",
  },
  OfferItem: {
    soldOut: "Agotado",
    maximumOfferOnStock: "Ha añadido el máximo número de ofertas disponibles.",
  },
  Footer: {
    support: "Soporte",
    frequentQuestions: "Preguntas frecuentes",
    testimonials: "Testimonios",
    help: "Ayuda",
    legal: "Legal",
    termsOfUse: "Condiciones de uso",
    privacyPolicy: "Política de Privacidad",
    cookies: "Cookies",
    contact: "Contacto",
    copyright: "Mercado Vip",
    termsAndConditions: "Términos y Condiciones",
    joinTheTeam: "Une tu negocio",
    GATEWAY_REJECTED: "Transacción declinada por autorización del Banco",
  },
  Banner: {
    text: " Debido a la Tormenta Tropical Laura, a partir del 24 de Agosto a las 6PM se vera afectado el servicio de comida a domicilio. Rogamos nos disculpen por las molestias ocasionadas.",
  },
  AvailabilityValidation: {
    alertTitle: "¡Atención!",
    alertSubtitle:
      "Existen ofertas del carrito de compras que requieren de su atención:",
    offerMessage:
      "Las siguientes ofertas no están disponibles y serán removidas del carrito de compras:",
    offerNotAvailable:
      "Los siguientes productos no están disponibles y serán removidos del carrito de compras:",
    offerInsufficientStock:
      "No está dispone la cantidad solicitada de los siguientes productos, serán removidos del carrito de compras:",
    variantNotAvailable:
      "Las siguientes especificaciones de productos no están disponibles:",
    additionsNotAvailable:
      "Los siguientes agregos para productos no están disponibles:",
    offerWithVariantsNotAvailable:
      "Los siguientes productos serán removidos del carrito de compras por contener especificaciones que no están disponibles:",
    offerWithAdditionsNotAvailable:
      "Los siguientes productos serán removidos del carrito de compras por contener agregos que no están disponibles:",
    offerAddsMessage:
      "Los siguientes extras no están disponibles. Las comidas que los contengan serán eliminadas del carrito de compra:",
    restaurantMessage:
      "El restaurante elegido está agotado de momento, por favor elija otra opción. Disculpe las inconveniencias.",
  },
  CustomerSatisfaction: {
    subtitle:
      "Tu experiencia es muy valiosa para otros usuarios. ¡Muchas Gracias!",
    labelInputScore: "Tu puntuación general de este restaurante",
    labelInputScoreTag: "Haz clic para puntuar",
    labelInputOpinion: "Tu opinión nos ayuda a dar un mejor servicio",
    send: "Enviar",
    goBack: "Atrás",
    completeRatingMessage: "Su valoración ha sido registrada",
    thanksMessage: " ¡Muchas Gracias!",
    errorMessageTitle: "A ocurrido un error",
    errorMessage: "No es posible realizar la valoración de la orden.",
  },
  Cart: {
    restaurantValidationMessage:
      "Ha seleccionado una oferta de un negocio diferente al de las ofertas existentes en el carrito de compras. Esta acción eliminará las selecciones previas. ¿Desea continuar?",
    subtotal: "Subtotal",
    shipping: "Coste de envío",
    serviceTax: "Tarifa del servicio",
    total: "Total",
    completeRequest: "Completar pedido",
    myRequest: "Mi pedido",
    serviceTaxInfo:
      "'Tarifa del servicio' es la comisión que cobra Mercado Vip para que la plataforma de Restaurantes exista. Incluye procesamiento de órdenes y pagos, atención al cliente y mantenimiento.",
  },
  Privacy: {
    headline: "Política de Privacidad",
    title1: "POLÍTICA DE PRIVACIDAD DE MERCADO VIP",
    sub1: "¿Quiénes somos?",
    p1_1: 'mercadovip.vip (el "Sitio Web") es operado por Recarga VIP, LLC, que opera como Mercado VIP ("nosotros"). Estamos registrados en Panama y en los Estados Unidos de America.',
    sub2: "¿Qué es la declaración de privacidad de Mercado VIP?",
    p2_1: 'En Mercado VIP, siempre respetamos la información personal y privacidad de nuestros clientes y tomamos este asunto muy en serio. Hemos trabajado muy duro para ganar la confianza de nuestros miembros y mantenerla es nuestra principal prioridad. Es por eso que siempre cumplimos con las obligaciones que se establecen en el marco de las leyes de protección de datos. Queremos que conozca nuestra Declaración de Privacidad para que pueda saber exactamente cómo usamos los datos personales que usted nos proporciona. Le recomendamos que lea la Declaración de Privacidad con cuidado. Tenga en cuenta que al visitar y haciendo clic en "Iniciar sesión" al registrarse en el Sitio Web, usted acepta los términos de esta Declaración de Privacidad. Los términos en mayúsculas que no se definen aquí tendrán el significado que se les da en los términos y condiciones.',
    sub3: "¿Qué pasa con otros sitios web vinculados a mercadovip.vip?",
    p3_1: "Mercado VIP es una empresa única, porque tenemos alianzas con una variedad de diferentes operadores de redes de todo el mundo. Con frecuencia publicaremos enlaces a los sitios web de algunos de los socios sin embargo, tenga en cuenta que Mercado VIP no se hace responsable del contenido ni de las políticas de privacidad de estos otros sitios web.",
    title2: "INFORMACIÓN RECOPILADA",
    sub4: "¿Qué información recopila Mercado VIP?",
    p4_1: "Hay dos tipos de información que recopilamos:",
    p4_2: '"Datos Personales" - Esta es información que lo identifica o puede ser usada para identificarlo a usted. Podemos recopilar y procesar los siguientes datos personales acerca de usted:',
    li4_1: "Su nombre;",
    li4_2: "Su dirección de correo electrónico;",
    li4_3: "Su dirección de facturación;",
    li4_4: "Detalles de su tarjeta de crédito o débito;",
    li4_5: "Su número de teléfono;",
    li4_6: "Su dirección IP;",
    li4_7:
      "Otra información que nos proporcione mediante el llenado de los formularios en nuestro Sitio Web. Esto incluye la información que usted proporcione en el momento de registrarse en nuestro Sitio Web;",
    li4_8:
      "También podemos pedir información si notifica algún problema con nuestro Sitio Web;",
    li4_9:
      "Si usted nos contacta, podemos mantener un registro de esa correspondencia;",
    li4_10:
      "Los detalles de las transacciones que usted realice, y sus visitas al Sitio Web.",
    li4_11:
      "Detalles del Saldo para Llamadas Telefónicas adquirido a través de, y sus visitas al Sitio Web.",
    p4_3: 'De esta forma buscamos brindarle una mejor experiencia de usuario al momento del uso de los servicios. Por nuestra parte, nosotros guardaremos estos datos para que estén disponibles a la hora que usted use nuevamente nuestros servicios. "Información no Personal" - Como la mayoría de otros sitios web, Mercado VIP recopila información estadística y analítica. Esto es lo que llamamos Información no Personal. A diferencia de la Información de Personal, esta información no puede ser usada para identificar o contactar con usted. Ejemplos de Información no Personal incluyen los tipos de navegadores y otros datos estadísticos anónimos que impliquen el uso de nuestro Sitio Web. ¿Por qué Mercado VIP recopila esta Información no Personal? En Mercado VIP estamos siempre en busca de mejorar y ayudar a nuestros miembros lo más posible, y la recolección de estos datos es una manera importante de lograrlo. Queremos conseguir una mejor comprensión de donde vienen nuestros miembros, qué les gusta y qué mejoras quieren de Mercado VIP. Siempre estamos buscando a desarrollar, crecer e innovar. Estos datos son una parte crucial del proceso.',
    title3: "USO DE SU INFORMACIÓN PERSONAL",
    sub5: "¿Cómo usamos su Información Personal?",
    p5_1: "Podemos usar esta información para lo siguiente:",
    li5_1:
      "Para ayudarle a enviar el producto que ha solicitado y procesar cualquier pago relacionado con el mismo;",
    li5_2:
      "Para que tenga sus contactos frecuentes, presentados de una manera más accesible independiente desde la plataforma que está utilizando, Sitio Web/APP;",
    li5_3:
      "Para facilitarle el modo de procesar cualquier pago relacionado con dicha operación;",
    li5_4:
      "Para contactar con usted si es necesario en relación con el producto que usted ha comprado o para responder a cualquier comunicación que usted nos haya enviado;",
    li5_5:
      "Para brindarle información sobre productos o servicios que le puedan interesar;",
    li5_6:
      "Para notificarle acerca de cualquier cambio en nuestro Sitio Web o en sus Servicios;",
    li5_7:
      "Para asegurarnos que el contenido de nuestro Sitio Web es presentado de la forma más efectiva para usted y su computadora;",
    li5_8: "Para brindarle atención al cliente;",
    li5_9: "Para análisis de marketing; y",
    li5_10: "Para propósitos de seguridad y prevención de fraude.",
    title4: "COOKIES",
    sub6: "¿Qué es una Cookie?",
    p6_1: "Una cookie es un pequeño archivo de texto de letras y números que se almacenan en el navegador de su computadora cuando usted visita ciertos sitios web. Una cookie permite que un sitio web distinga su computadora de equipos de otros usuarios.",
    sub7: "¿Las Cookies tienen mi Información Personal?",
    p7_1: "Generalmente las cookies no contienen Información Personal a no ser que usted la haya introducido ya en el Sitio Web.",
    sub8: "¿Qué Tipo de Cookies Usa el Sitio Web y Con Qué Propósitos?",
    li8_1:
      "Cookies estrictamente necesarias. Estas te permiten permanecer registrado durante la duración de su visita al Sitio Web y le permite a usted realizar operaciones sin tener que iniciar sesión de nuevo.",
    li8_2:
      "Cookies Funcionales. Estas permiten que el Sitio Web para recuerde ciertos detalles sobre las decisiones que toma (por ejemplo, el lenguaje con el que está navegando el Sitio Web) para ofrecerle una mejor experiencia de uso del Sitio Web.",
    li8_3:
      "Cookies Analíticas. Estas nos permiten reconocer y calcular el número de visitantes al Sitio Web. También nos permiten calcular el tiempo que pasan en el Sitio Web y ver cómo llegó al mismo. Ayudan Mercado VIP a mejorar la forma de trabajar del Sitio Web.",
    sub9: "¿Puedo bloquear o borrar las Cookies?",
    p9_1: "Si usted puede si es lo que decide hacer. Tenga en cuenta que esto puede hacer su uso del Sitio Web menos conveniente.",
    sub10: "¿Cómo deshabilitar las Cookies?",
    p10_1:
      "De un clic aquí para obtener información acerca de cómo deshabilitar las cookies en algunos de los navegadores más populares.",
    title5: "TERCERAS PARTES",
    sub11: "¿Mercado VIP da Información Personal a terceros?",
    p11_1:
      "Nunca divulgaremos su Información Personal a terceros a menos que usted haya acordado tal o salvo que el tercero lo requiera para procesar los datos personales en relación con un producto solicitado. Los detalles de sus tarjetas de crédito o débito son procesados por un proveedor líder mundial de procesamiento de pagos en línea que almacena los datos de la tarjeta si usted lo solicita para que usted no tenga que introducirla cada vez que visite el Sitio Web. Cuando se utiliza el servicio de Mercado VIP, damos a conocer el los datos de envío al operador local correspondiente con el fin de facilitar el envío del producto. Podemos revelar sus datos personales si estamos obligados a cumplir con cualquier ley, una citación, una orden judicial o regulatoria, u otro requerimiento legal.",
    sub12: "¿Mercado VIP da Información no Personal a terceros?",
    p12_1:
      "Podemos proporcionar Información no Personal a terceros en relación con el número de usuarios únicos que visitan nuestro Sitio Web, la distribución demográfica de los usuarios de nuestro Sitio Web, o las actividades de los usuarios en nuestro Sitio Web.",
    sub13: "¿Qué se puede aprender de la Información no Personal?",
    p13_1:
      "Hay muchas cosas útiles que aprender de esta información. Por ejemplo, puede ayudar a potenciales anunciantes, proveedores de servicios de publicidad, socios comerciales, patrocinadores, concesionarios, investigadores y otras partes similares.",
    sub14: "¿Qué sucede si Mercado VIP es comprada por otra compañía?",
    p14_1:
      "Nos reservamos el derecho de transferir información (incluyendo su Información Personal) a un tercero en caso de una venta, fusión, liquidación, suspensión de pagos o transferencia de todo o sustancialmente todos los activos de nuestra empresa. El tercero sólo tiene que utilizar sus datos personales para los fines que usted proporcionó a nosotros en primer lugar. Usted será notificado en caso de dicha transferencia y se le concederá la oportunidad de no optar.",
    sub15: "¿Puede fallar algo con la información enviada?",
    p15_1:
      "Como usted sabrá, la naturaleza de Internet es tal que no podemos garantizar la seguridad de la información que usted nos envíe. Esto no es exclusivo de Mercado VIP, porque no hay información que se envíe a través de Internet que se puede garantizar que sea 100% segura. Cualquier empresa que le diga lo contrario está diciendo cosas sin sentido. Nosotros, no obstante tomamos todas las medidas razonablemente posibles para proteger su Información Personal.",
    title6: "MARKETING DIRECTO",
    sub16:
      "¿Usará Mercado VIP mi Información personal con propósitos de marketing directo?",
    p16_1:
      "Cuando usted lo ha consentido, podemos utilizar su Información Personal para enviarle información por SMS o por correo electrónico relacionada con nuestros productos y servicios que puedan ser de interés para usted o productos y servicios similares a los que ha comprado previamente en Mercado VIP.",
    sub17: "¿Puedo Cancelar mi suscripción?",
    p17_1:
      "Se le proporcionará la oportunidad de cancelar la suscripción en el momento del registro. También puede cancelar la suscripción en cualquier momento, de forma gratuita, mediante el uso de la opción para darse baja de los SMS o de los correos electrónicos que Mercado VIP envía a usted o enviando un correo electrónico a info@mercadovip.vip",
    title7: "OTRAS PREGUNTAS",
    sub18: "¿Dónde se guarda mi Información Personal?",
    p18_1:
      "Su Información Personal esta guardada con seguridad en nuestros servidores colocados en los Estados Unidos de América.",
    sub19: "¿Puedo actualizar o borrar mi Información Personal?",
    p19_1:
      "Por supuesto que puede. Por favor díganos sobre cualquier cambio o inexactitud en su información personal y actualizaremos, corregiremos o borraremos su Información Personal conforme a lo solicitado. No hay ningún cargo por este y cumpliremos con su solicitud a la mayor brevedad posible y, en cualquier caso, dentro de no más de 40 días de la recepción de su solicitud. Por favor, contacte con nosotros en info@mercadovip.vip.",
    sub20: "¿Puedo acceder mi Información Personal?",
    p20_1:
      "También puede solicitar copias de sus datos personales en poder de nosotros. Le proporcionaremos una copia de la Información Personal en nuestra posesión tan pronto como sea posible y en todo caso dentro de no más de 40 días de su solicitud por escrito. Podemos solicitar una prueba de identificación para verificar su solicitud de acceso. Por favor, contacte con nosotros en info@mercadovip.vip.",
    sub21: "¿Puede Mercado VIP cambiar su Declaración de Privacidad?",
    p21_1:
      "Cualquier cambio en la Declaración de Privacidad de Mercado VIP se publicará claramente en nuestro sitio web. Hacemos esto porque siempre queremos que nuestros clientes sepan exactamente qué información recopilamos, cómo la usamos y bajo qué circunstancias, si las hubiere, la revelamos. Si alguna vez decidimos utilizar los datos personales de una manera que es diferente de lo que previamente se ha indicado, se lo notificaremos por correo electrónico. Usted tendrá la opción de decidir si podemos o no utilizar su información de esta nueva forma.",
    sub22: "¿Cómo puedo contactar Mercado VIP?",
    p22_1:
      "Si usted tiene alguna pregunta acerca de su Información Personal, esta Declaración de Privacidad o cualquier queja, por favor envíenos un correo electrónico a info@mercadovip.vip.",
  },
  Terms: {
    headline: "Términos y Condiciones de Plataforma – Mercado Vip",
    p1_1: 'Los presentes Términos y Condiciones de uso (en adelante los “Términos” y/o “Condiciones”, en su caso) regulan el acceso o uso que usted haga, como persona, desde cualquier parte del mundo la plataforma virtual Mercado Vip, compuesta por una aplicación para dispositivos móviles y una página web (en adelante “el servicio” o “los servicios”). El servicio ofrecido por Mercado Vip a través de su Plataforma (el "Servicio"), consiste en:',
    p1_2: '(i) facilitar a sus Usuarios la elección de comidas y bebidas ofrecidas por los restaurantes registrados en Mercado Vip (los "Restaurantes") mediante la publicación de la información de los productos alimenticios ofrecidos por dichos Restaurantes y las condiciones de su ofrecimiento y,',
    p1_3: "(ii) acercar, vía on-line y a través de la Plataforma, a los Usuarios y a los Restaurantes para que los primeros puedan hacer pedidos y compras online de los productos alimenticios ofrecidos por los Restaurantes de acuerdo con el menú disponible, mediante la entrega a domicilio.",
    p1_4: "(iii) Con la aceptación de estos Términos y Condiciones, el Usuario declara conocer y aceptar que el Servicio consiste, exclusivamente, en la intermediación para la comercialización de productos alimenticios y que no existirá ninguna relación jurídica de compraventa entre Mercado Vip y el Usuario con ocasión de los pedidos de dichos productos que el Usuario realice a través del Plataforma.",
    p1_5: '(iv) El Usuario declara conocer y aceptar que Mercado Vip no participa en y que el Servicio no involucra, la preparación, empaque, disponibilidad y entrega física de los productos, siendo estos aspectos responsabilidad íntegra del Restaurante. Por lo anterior, el Usuario acepta que cualquier reclamo por problemas derivados de la adición, del cumplimiento del pedido, preparación y entrega de los productos alimenticios adquiridos, deberá ser gestionado directamente con el respectivo Restaurante o a través del sistema "Enviar una Solicitud" de Mercado Vip, dónde te ayudaremos a resolver tú solicitud directamente con el Restaurante.',
    p1_6: "(v) Teniendo en cuenta que Mercado Vip únicamente pone a disposición una plataforma electrónica para poner en contacto Usuarios y Restaurantes, Mercado Vip no será responsable por la información que los Restaurantes anuncien de sus productos ni por información adicional como cantidades, disponibilidades, tiempo de entrega, precios, sabores, medios y formas de pago disponible",
    sub2: "REGISTRO",
    p2_1: "Para el registro de Mercado Vip usted necesitará tener una cuenta en Mercado Vip y se registra a través de su número telefónico. Para crear su registro debe ingresar un número de teléfono válido relacionado con su cuenta en Mercado Vip y recibirá un PIN de 4 dígitos. Usted acepta que la información que proporcionó durante el proceso de registro de la cuenta es veraz, exacta y completa. Usted entiende que proporcionar información falsa, inexacta o engañosa al crear o actualizar una cuenta con Mercado Vip viola estos Términos y puede resultar en la cancelación inmediata de su cuenta con Mercado Vip. La actualización inmediata de cualquier información de la cuenta será su responsabilidad. Tenga cuidado de no revelar su contraseña a terceros, tanto directa como indirectamente. Es su responsabilidad proteger su contraseña. Cada cuenta con Mercado Vip puede ser utilizada por un solo usuario y no es transferible. Mercado Vip no se responsabiliza por actividades que se realizan en su cuenta, incluido el uso no autorizado de su tarjeta de débito o crédito. Si usted es menor de 18 años, debe obtener autorización de uno de sus padres o tutor legal antes de crear una cuenta con Mercado Vip y hacer uso de los Servicios. Si usted actúa en nombre de otra persona, empresa o entidad, debe estar autorizado a aceptar estos Términos en nombre de dicha persona, empresa o entidad.",
    sub3: "OBLIGACIONES DE Mercado Vip",
    p3_1: "Poner a disposición un espacio virtual que le permita a los Usuarios hacer pedidos de compra de productos alimenticios anunciados y comercializados por los Restaurantes. Poner a disposición del Usuario los medios para pagar el valor de los productos, sujeto a la aceptación de los mismos por parte del Restaurante.",
    p3_2: "Proveer la interfaz e infraestructura de la Plataforma para que los Restaurantes cumplan con las obligaciones.",
    p3_3: "Previo al cierre de la transacción, presentar al Usuario un resumen de todos los productos seleccionados. Dicho resumen incluirá una descripción de cada uno de los productos seleccionados en el sitio del Restaurante, precio individual por unidad y número de unidades, precio total de la transacción, costos y gastos adicionales, si los hubiere.",
    sub4: "OBLIGACIONES DE LOS RESTAURANTES",
    p4_1: "Poner a disposición de los Usuarios y mantener actualizada información cierta, suficiente y clara sobre los productos alimenticios que ofrezcan, que comprenderá información sobre calidades, características, cantidades y presentaciones disponibles, de tal manera que los Usuarios puedan conocer con exactitud los productos adquiridos",
    p4_2: "Incluir imágenes de los productos ofrecidos que reflejen la realidad de los productos que se entregarán.",
    p4_3: "Presentar el precio de los productos ofrecidos por los Restaurantes incluyendo todos los posibles recargos por impuestos, adiciones, servicio de domicilio, etc., y cualquier otro concepto aplicable, discriminando cada uno de dichos conceptos.",
    sub5: "POLÍTICA DE REEMBOLSO",
    p5_1: "Para su satisfacción, nos aseguramos de que siempre reciba la entrega de alimentos de acuerdo con sus requisitos. Nuestra política de devolución y reembolso mejora la confianza de nuestros clientes en nuestra determinación.",
    p5_2: "Si no informa cualquier problema dentro de las 24 horas posteriores a la recepción del pedido, será responsabilidad del consumidor.",
    p5_3: "Nuestro equipo se asegura de que reciba la comida adecuada en su lugar. Sin embargo, si recibe el pedido equivocado, entonces, simplemente puede ponerse en contacto con nosotros y háganoslo saber. Podemos hacer cambios adecuados en las transacciones y proporcionar la comida adecuada.",
    p5_4: "No reembolsamos dinero si los alimentos han sido comidos o manipulados.",
    p5_5: "Los reembolsos se basan en un asunto de caso a caso.",
    sub6: "CANCELACIONES Y CAMBIOS",
    p6_1: "El Usuario no podrá cancelar el servicio o solicitar su modificación cuando se confirma la orden. No podemos proporcionar reembolsos por la devolución de la comida.",
    sub7: "CONTÁCTENOS",
    p7_1: "Asegúrese de preparar todos los detalles relevantes para su queja, incluidos los números de pedido y la información de la cuenta, ya que probablemente tendrá que explicar el problema desde el principio.",
    sub8: "MODIFICACIONES DE ESTOS TÉRMINOS Y CONDICIONES",
    p8_1: "Los presentes Términos y Condiciones de uso del Servicio, podrán ser modificados en cualquier momento, total o parcialmente, con el fin de mejorar el Servicio. Es responsabilidad de los Usuarios revisar los Términos y Condiciones y las eventuales modificaciones que Mercado Vip haga a los mismos.",
    p8_2: "Las modificaciones a los presentes Términos y Condiciones serán efectivas tan pronto como sean publicadas en el Plataforma, todo Usuario que no esté de acuerdo con estos las modificaciones efectuadas a los presentes Términos y Condiciones deberá abstenerse de acceder, utilizar o registrarse en el Plataforma y podrá manifestar su oposición a cualquiera de los términos modificados, por escrito y a través de la Plataforma, oposición que generará la cancelación de su Registro.",
  },
} as Translation;
