import Axios from "axios";
import {
  tokenRefreshed,
  tokenFailure,
} from "../../modules/login/store/login.actions";
import { store } from "../../App";
import { post } from "../services";
import { DEFAULT_CONFIG } from "../../config/Config";
import { addToStorage, removeFromStorage } from "../../utils/AuxiliarFunctions";
import { browserHistory } from "../../utils/History";

const storeTokenAndStamp = ({
  access_token,
  token_type,
  refresh_token,
  expires_in,
  scope,
  solicit_email,
  deeplink,
}) => {
  console.log("storing token");
  addToStorage("token", {
    access_token,
    token_type,
    refresh_token,
    expires_in,
    scope,
    solicit_email,
    deeplink,
  });
  const stamp = new Date().getTime() + Number(expires_in) * 1000;
  addToStorage("stamp", stamp);
};

const removeAuthFromStorage = () => {
  console.log("removing token");
  removeFromStorage("token");
};

const handleTokenFailure = (error) => {
  const msg = "Inicie sesión para continuar.";
  console.log(`${msg}: ${JSON.stringify(error)}`);
  store.dispatch(tokenFailure(msg));
  removeAuthFromStorage();
  browserHistory.push("../login");
  return msg;
};

const getAuthToken = () => {
  const clientRefreshToken = store.getState().auth.refresh_token;
  if (clientRefreshToken) {
    post(`account/refresh`, {
      refresh_token: clientRefreshToken,
      grant_type: "refresh_token",
      client_id: DEFAULT_CONFIG.client_id,
    })
      .then((result) => {
        if (result?.data) {
          console.log(`Refresh promise response: ${JSON.stringify(result)}`);
          const newAuthData = result?.data || {};
          storeTokenAndStamp(newAuthData);
          store.dispatch(tokenRefreshed(result));
          console.log("token refreshed!!");
          console.log("Return refresh promise");
          return newAuthData;
        } else {
          return handleTokenFailure(null);
        }
      })
      .catch((error) => {
        return handleTokenFailure(null);
      });
  }
};

const authMiddleware = (store) => (next) => (action) => {
  if (typeof action === "function") {
    Axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          console.log(`Error: ${error.response.status}, trying refresh...`);

          getAuthToken()
            .then((data) =>
              Axios({
                ...originalRequest,
                retry: true,
                Authorization: `Bearer ${data.access_token}`,
              })
            )
            .catch((error) => {
              handleTokenFailure(error);
            });
        }
      }
    );
  }
  return next(action);
};

export default authMiddleware;
