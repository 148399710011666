import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autosuggest from 'react-autosuggest';

import "./css/SearchStyle.scss";

import useTranslation from "translations";
import PdfIcon from "component/Icon/PdfIcon";

import { selectDefaultPageCounter, selectFilters, selectDefaultFilters } from "store/filter/filter.reducers";
import { clearRestaurantCarouselsListStates, loadRestaurantsCarouselsList } from "store/filter/filter.actions";
import { selectSuggestions } from "./store/reducers";
import { loadBannerMessages } from "./store/actions";
import { store } from "../../App";
import { showAllBusinessForProductType } from "../../store/filter/filter.actions";
import { browserHistory } from "../../utils/History";


export default function Search({ location, grown = false }) {
  const translation = useTranslation();
  const history = browserHistory;
  const filters = useSelector(selectFilters);
  const defaultFilters = useSelector(selectDefaultFilters);
  const defaultPageCounter = useSelector(selectDefaultPageCounter);
  const suggestions = useSelector(selectSuggestions);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [grow, setGrow] = useState(grown);
  const [value, setValue] = useState("");
  const [preview] = useState(location && location.state && location.state.preview ? location.state.preview : 4);
  const businessCategorySelected = store.getState().filters.filters.businessCategory;

  // how many restaurants it will fetch at a time

  const searchByButton = (ev) => {
    search(ev, { suggestion: suggestions[0] });
  }
  const clearSelection = (ev) => {
    setValue("");
    // search nothing
    search(ev, { suggestion: { label: '', classification: 'search' } });
  }
  const search = (event, { suggestion }) => {
    if (suggestion) {
      const { label, id, classification } = suggestion;

      if (classification === 'restaurant') {
        history.replace(`/${id}`);
        history.go(0)
      } else {
        if (window.location.pathname !== '/') {
          history.replace(`/`);
          history.go(0)
        }
        // Filter but respect the province, muinicipality and locality
        const { municipalityid, provinceid, localityid } = filters;
        dispatch(clearRestaurantCarouselsListStates());
        dispatch(showAllBusinessForProductType(suggestion?.label));
        dispatch(loadRestaurantsCarouselsList(preview,
          {
            ...defaultFilters,
            businessCategory: businessCategorySelected,
            provinceid, municipalityid, localityid,
            [classification]: [label],
            classificationLabel: label,
          },
          1, defaultPageCounter, false, true));
      }
    }
  };

  useEffect(() => {
    setValue(filters.classificationLabel || '');
  }, [filters]);

  useEffect(() => {
    if (inputRef.current && grow) {
      inputRef.current.focus();
      inputRef.current.click();
      inputRef.current.onblur = function () { setTimeout(() => setGrow(false), 500); };
      // inputRef.current.setAttribute('tabIndex', '-1');
    }
  }, [grow])

  const onChange = (event, { newValue }) => setValue(newValue);

  // tells autosuggestion how it suppose to draw every row of suggestion list
  const renderSuggestion = (suggestion/*, {query, isHighlighted}*/) => {
    // isHighlighted = suggestion === query;
    return <div className="suggestion__inner">
      {suggestion.classification === 'search'
        ? <PdfIcon width="24" height="24" icon="system/search_black" alt="loc" ext="svg" />
        : <div className="avatar"><img src={suggestion.image} alt="restaurant"></img></div>}

      <div className="suggestion-label">
        <div>{suggestion.label}</div>
        <PdfIcon width="16" height="16" icon="system/chevron_right" alt="loc" ext="svg" />
      </div>
    </div>
  };

  return (
    <li className={`${!grow ? 'bs' : 'd8'} bt bu af`} style={{ padding: 0 }}>
      <div className={`${!grow ? 'bs' : 'd8'} bt bu`}>
        {!grow ?
          <button className="button-search" onClick={() => setGrow(!grow)}>
            <PdfIcon width="24" height="24" icon="system/search_black" alt="loc" ext="svg" />
            <span>{translation.TopBar.search}</span>
          </button> :
          <div className="autosuggest-container">
            <Autosuggest
              suggestions={suggestions}
              focusInputOnSuggestionClick={false}
              onSuggestionsFetchRequested={({ value }) => dispatch(loadBannerMessages(value))}
              onSuggestionsClearRequested={ev => search(ev, { suggestion: '' })}
              onSuggestionSelected={search}
              highlightFirstSuggestion={true}
              getSuggestionValue={(suggestion) => suggestion.label}
              renderSuggestion={renderSuggestion}
              inputProps={{ placeholder: translation.TopBar.searchPlaceholder, value, onChange, ref: inputRef }}
            />
            {value.length > 0 ? <button onClick={clearSelection}>
              <PdfIcon width="24" height="20" icon="system/cancel" alt="loc" ext="svg" />
            </button> : null}
            <button onClick={searchByButton} tabIndex="1" autoFocus="autoFocus">
              <PdfIcon width="24" height="20" icon="system/search_black" alt="loc" ext="svg" />
            </button>
          </div>
        }
      </div>
    </li>
  );
};

