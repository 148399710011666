import React from "react";
import "./css/FooterStyle.scss";
import useTranslations from "../../translations/useTranslations";

import { browserHistory } from "../../utils/History";

function Footer() {

  const goTolegal = () => browserHistory.push("../legal");
  const goToPrivacy = () => browserHistory.push("../privacidad");

  const translations = useTranslations();
  return (
    <footer className="page-footer">
      <div className="wrap container-fluid">
        <div className="row">
          <div className="col-xs start">
            <p>&copy; {1900 + new Date().getYear()} {translations.Footer.copyright}</p>
          </div>
          <div className="col-xs end">
            <p>
              <button className="button-link tag" onClick={goToPrivacy}>{translations.Footer.privacyPolicy}</button>
              <span> | </span>
              <button className="button-link tag" onClick={goTolegal}>{translations.Footer.termsAndConditions}</button>
              {/* <span> | </span>
              <a className="tag" href="mailto:alacarta@tamtos.com">{translations.Footer.joinTheTeam}</a> */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
