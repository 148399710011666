/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./css/CheckoutStyles.scss";
import * as _ from "lodash";
import es_ES from "date-fns/locale/es";
import en_US from "date-fns/locale/en-US";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomInput from "component/CustomInput/CustomInput";
import { registerLocale } from "react-datepicker";
import useTranslations from "translations";
import CustomSelect from "component/CustomSelect/CustomSelect";
import { ArrowBack, ArrowForwardIos, CheckBox, CheckBoxOutlineBlank, Contacts, LocationOn, WatchLater } from "@material-ui/icons";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Voucher from "./components/Voucher";
import DeliveryModeSwitch from "modules/BusinessDetail/components/DeliveryModeSwitch/DeliveryModeSwitch";
import { deliveryAddresses, selectTopBarAddress } from "component/FormAddAddress/store/form-add-address.reducers";
import { validateFieldByArray } from "utils/AuxiliarFunctions";
import { selectBDCart, selectCartRestaurant } from "modules/cart/store/cart.reducers";
import { selectProfile } from "modules/profile/store/profile.reducers";
import { payOrder } from "modules/Pay/store/pay.actions";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { loadDeliveryDateList, loadDeliveryTimeList, loadServerCurrentDate, storeBrowserOrder, setBDOrder } from "./store/checkout.actions";
import { setDeliveryAddressOnTopBarSelected } from "component/FormAddAddress/store/form-add-address.actions";
import { selectBrowserOrder, selectDeliveryDate, selectDeliveryDateList, selectDeliveryTimeOptions, selectOrderFromBD } from "./store/checkout.reducers";
import useRestaurant from "hooks/useRestaurant";
import AddressesModal from "component/AddressesModal/AddressesModal";
import AddAddressModal from "component/AddAddressModal/AddAddressModal";
import Alert from "modules/alert/Alert";
import CheckoutErrorAlert from "./components/CheckoutErrorAlert";
import useCheckout from "../../hooks/useCheckout";
import { hideAlert, showAlert } from "modules/alert/store/alert.actions";

registerLocale("en-US", en_US);
registerLocale("es-ES", es_ES);

const monthNames = {
  es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const CheckoutOrder = (props) => {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const user = useSelector(selectProfile);
  const cartBD = useSelector(selectBDCart);
  const bdOrder = useSelector(selectOrderFromBD);
  const addresses = useSelector(deliveryAddresses);
  const restaurant = useSelector(selectCartRestaurant);
  const browserOrder = useSelector(selectBrowserOrder);
  const dateFromServer = useSelector(selectDeliveryDate);
  const topBarAddressSelected = useSelector(selectTopBarAddress);
  const restaurantAvailableDates = useSelector(selectDeliveryDateList);
  const restaurantAvailableTimes = useSelector(selectDeliveryTimeOptions);
  const [validSteps, setValidSteps] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [showLocation, setShowLocation] = useState(null);
  const [discountCoupon, setDiscountCoupon] = useState(null);
  const [showDateAndTime, setShowDateAndTime] = useState(null);
  const [showDiscountCoupon, setShowDiscountCoupon] = useState(null);
  const [errorsSteps, setErrorsSteps] = useState({});
  const [backendErrors, setBackendErrors] = useState(null);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [havanaActualDate, setHavanaActualDate] = useState('');
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const { fetchDetails } = useRestaurant();
  const [restaurantDetails, setRestaurantDetails] = useState(undefined);
  const localStorageAddress = JSON.parse(localStorage.getItem('address'));
  const { buildAddress, dispatchValidateOrder } = useCheckout();
  const topBarSearchOptionSelected = JSON.parse(localStorage.getItem('address'));


  useEffect(() => {
    dispatch(loadServerCurrentDate());
    dispatch(loadDeliveryDateList(cartBD?.restaurantId));

    let DELIVERY_ORDER = {
      coupon: null,
      restaurantId: cartBD?.restaurantId,
    };
    let ORDER = { ...DELIVERY_ORDER, address: localStorageAddress };
    if (!browserOrder?.deliveryOrderType) {
      ORDER.deliveryOrderType = "DELIVERY";
    }
    dispatch(storeBrowserOrder(ORDER));

    async function loadData() {
      await loadDeliveryDetails();
      backendValidation();
    }

    loadData();
  }, []);

  useEffect(() => {
    if (!browserOrder?.deliveryOrderType) {
      dispatch(storeBrowserOrder({
        ...browserOrder,
        coupon: null,
        restaurantId: cartBD?.restaurantId,
        deliveryOrderType: "DELIVERY",
      }));
    }
    validateStepForm();
  }, [browserOrder]);

  useEffect(() => {
    if (browserOrder?.deliveryOrderType && cartBD?.items
      && browserOrder?.deliveryDay && browserOrder?.deliveryHour
      && browserOrder?.address?.userRef) {
      backendValidation();
    }
    validateStepForm();
  }, [browserOrder?.address, browserOrder?.receiver, browserOrder?.deliveryDay]);

  useEffect(() => {
    if (topBarAddressSelected && !browserOrder?.address) {
      dispatch(storeBrowserOrder({
        ...browserOrder,
        address: topBarAddressSelected,
        receiver: topBarAddressSelected?.contactName,
        receiverPhone: topBarAddressSelected?.telephoneById?.number
      }));
    }
  }, [topBarAddressSelected]);

  useEffect(() => {
    if (addresses?.length && !browserOrder?.address?.userRef) {
      openLocation();
    }
  }, [browserOrder?.address]);

  useEffect(() => {
    user?.accountId && dispatch(storeBrowserOrder({ ...browserOrder, accountId: user.accountId }))
  }, [user]);
  useEffect(() => { preLoadAddressFromTopBarFilter() }, [addresses, browserOrder?.address]);
  useEffect(() => { dateFromServer && setHavanaActualDate(dateFromServer) }, [dateFromServer]);
  useEffect(() => { setEnableButton(validSteps && acceptTerms) }, [validSteps, acceptTerms]);
  useEffect(() => {
    restaurantAvailableDates?.length > 0 && dispatch(storeBrowserOrder({
      ...browserOrder,
      deliveryDay: browserOrder?.deliveryDay || restaurantAvailableDates[0],
      deliveryHour: browserOrder?.deliveryHour || null
    }))
  }, [restaurantAvailableDates]);

  useEffect(() => {
    restaurantAvailableTimes?.length > 0 && dispatch(storeBrowserOrder({
      ...browserOrder,
      deliveryHour: browserOrder?.deliveryHour || restaurantAvailableTimes[0]
    }))
  }, [restaurantAvailableTimes]);

  useEffect(() => {
    if (browserOrder?.deliveryDay?.date) {
      const [d, m,] = browserOrder?.deliveryDay?.date.split('-');
      setSelectedDay(parseInt(d, 10));
      setSelectedMonth(monthNames[translation.Code.toLowerCase()][parseInt(m, 10) - 1]);
      dispatch(loadDeliveryTimeList(browserOrder?.deliveryDay?.date.split('-').reverse().join('-'), cartBD?.restaurantId));
    }
  }, [browserOrder?.deliveryDay]);

  useEffect(() => {
    if (bdOrder?.result && bdOrder?.code === 0) {
      setIsErrorAlert(null);
      setShowLocation(false);
      setBackendErrors(null);
      setErrorAlertMessage(null);
      dispatch(storeBrowserOrder({
        ...browserOrder,
        ...bdOrder?.result,
      }));
      props.history.push({ state: null });
    }

    dispatch(setLoadingMaskStatus(false));
  }, [bdOrder]);

  const backendValidation = () => {
    if (!backendErrors && !props?.location?.state?.backendErrors) {
      dispatch(setLoadingMaskStatus(true));
      dispatchValidateOrder({ ...browserOrder, restaurantId: cartBD.restaurantId });
    }
  }

  const loadDeliveryDetails = async () => {
    const address = JSON.parse(localStorage.getItem('address'));
    if (address && restaurant) {
      const details = await fetchDetails(address, cartBD?.restaurantId);
      setRestaurantDetails(details)
    }
  }

  const handleChangeDeliveryOrderType = (type) => {
    setShowLocation(!browserOrder?.address);
    setErrorsSteps({});
    setBackendErrors(null);
    props.history.push({ state: null });
    dispatch(storeBrowserOrder({ ...browserOrder, deliveryOrderType: type }));
  }

  const handleSelectDeliveryAddress = selected => {
    dispatch(storeBrowserOrder({
      ...browserOrder,
      address: selected,
      receiver: selected?.contactName,
      receiverPhone: selected?.telephoneById?.number
    }));
    localStorage.setItem('address', JSON.stringify(selected));
    setErrorsSteps({});
    setShowLocation(false);
    setBackendErrors(null);
    props.history.push({ state: null });
    validateStepForm();
  };

  const handleSelectDeliveryDate = dateSelected => {
    setBackendErrors(null);
    props.history.push({ state: null });
    dispatch(storeBrowserOrder({ ...browserOrder, deliveryDay: dateSelected, deliveryHour: '' }));
  };

  const handleSelectDeliveryTimeline = (selected) => {
    setErrorsSteps({});
    setBackendErrors(null);
    props.history.push({ state: null });
    setShowDateAndTime(false);
    dispatch(storeBrowserOrder({ ...browserOrder, deliveryHour: selected }));
  };

  const preLoadAddressFromTopBarFilter = () => {
    if (topBarSearchOptionSelected?.id && addresses?.length > 0) {
      let address = browserOrder?.address;
      if (!address) {
        address = topBarSearchOptionSelected;
      }
      if (address?.id !== topBarSearchOptionSelected?.id) {
        dispatch(setDeliveryAddressOnTopBarSelected(address));
        dispatch(storeBrowserOrder({
          ...browserOrder,
          address,
          receiver: address?.contactName,
          receiverPhone: address?.telephoneById?.number
        }));
      } else {
        const existAddress = addresses.find(({ id }) => topBarSearchOptionSelected?.id === id);
        if (existAddress) {
          handleSelectDeliveryAddress(existAddress);
        }
      }
    }
  };

  const openLocation = () => setShowLocation(true);
  const openDateAndTime = () => setShowDateAndTime(!showDateAndTime);
  const handleChangeDiscountCoupon = (value) => setDiscountCoupon(value);
  const openDiscountCoupon = () => setShowDiscountCoupon(!showDiscountCoupon);

  const applyDiscount = () => {
    const order = { ...browserOrder, coupon: discountCoupon.replace(' ', '') };
    dispatch(storeBrowserOrder(order));
    dispatchValidateOrder(order);
    setShowDiscountCoupon(false);
  };

  const clearDiscount = () => {
    setBackendErrors(null);
    setDiscountCoupon(null);
    setShowDiscountCoupon(false);
    props.history.push({ state: null });
    dispatch(storeBrowserOrder({ ...browserOrder, coupon: null }));
  };

  const editPurchase = () => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(setBDOrder(null));
    dispatch(storeBrowserOrder(null));
    props.history.push({ pathname: `/${cartBD?.restaurantSlug}` });
  };

  const continueToPay = () => {
    if (_.isEqual(havanaActualDate, browserOrder?.deliveryDay?.date.split('-').reverse().join('-'))) {
      handleStepDeliveryNext();
    } else {
      dispatch(
        showAlert(
          `${translation.Payment.confirmDateDialogTitle}
            ${translation.Code === "ES" ? `${selectedDay} ${selectedMonth}` : `${selectedMonth} ${selectedDay}`}
            ${translation.Payment.confirmDateDialogMessage}
          `,
          "Aceptar",
          "Cancelar",
          () => {
            dispatch(hideAlert());
            handleStepDeliveryNext();
          },
          () => {
            dispatch(hideAlert());
          },
          { required: true }
        )
      );
    }
  }

  const handleStepDeliveryNext = () => {
    dispatch(setLoadingMaskStatus(true));
    if (browserOrder?.total > 0) {
      if (!browserOrder.accountId && browserOrder?.address?.userRef) {
        browserOrder.accountId = browserOrder?.address?.userRef;
      }
      props.history.push({ pathname: "/prv/pay" });
    } else {
      const { taxes, receiver, subtotal, shipping, accountId, restaurantId, receiverPhone, discountByTalon, deliveryOrderType } = browserOrder;
      dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
      dispatch(payOrder({
        address: buildAddress(browserOrder),
        taxes, receiver, subtotal, shipping, accountId, restaurantId, receiverPhone, discountByTalon, deliveryOrderType,
        totalCost: browserOrder?.total,
        discount: browserOrder?.discountByCoupon,
        deliveryHour: browserOrder?.deliveryHour?.hour,
        deliveryDay: browserOrder?.deliveryDay?.date.split('-').reverse().join('-'),
        paymentFlowCode: browserOrder?.discountByCoupon ? 'DISCOUNT_COUPON' : 'CLL2',
        payment: {
          orderId: 0,
          required: false,
          paymentMethodId: null,
          accountId: browserOrder.accountId,
        },
        paymentTransactionid: null,
      }));
    }
  };

  const validateStepForm = () => {
    let errors = {};
    let address = validateFieldByArray(browserOrder?.address, ["required"], translation.Validations["required"]);
    if (address) {
      errors.fullAddress = address
    }
    let deliveryDay = validateFieldByArray(browserOrder?.deliveryDay, ["required"], translation.Validations["required"]);
    if (deliveryDay) {
      errors.deliveryDay = deliveryDay
    }
    let deliveryHour = validateFieldByArray(browserOrder?.deliveryHour, ["required"], translation.Validations["required"]);
    if (deliveryHour) {
      errors.deliveryHour = deliveryHour
    }
    if (backendErrors) {
      errors = { ...errors, ...backendErrors };
    }
    setErrorsSteps(errors);
    setValidSteps(_.isEmpty(errors));
  };

  const handleUpsertAddress = (address) => {
    setShowLocation(false);
    setAddressToEdit(address)
    setShowAddAddressForm(true);
  }

  const closeAddAddressForm = () => {
    setShowAddAddressForm(false);
    setShowLocation(true);
  }

  const handleOnSavedAddress = (address) => {
    setShowAddAddressForm(false);
    setAddressToEdit(address)
    setShowLocation(true);
  }

  const isDelivery = browserOrder?.deliveryOrderType === 'DELIVERY';

  return (
    <div className="checkout">
      {isErrorAlert && <CheckoutErrorAlert show={isErrorAlert} errorAlertMessage={errorAlertMessage} />}
      <Alert />
      {/* <MyPurchaseButton {...props} /> */}
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="grid">
          <div className="steps new__steps">
            <div className="step new__step">
              <div className="new__step__header-wrapper">
                <div className="step-delivery__actions mb-20">
                  <button className="button--primary button--primary-inverted" onClick={editPurchase} >
                    <ArrowBack style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                    <span>{translation.Payment.goBack}</span>
                  </button>
                </div>

                <Typography variant="h5" component="h2" className="subtitle mb-20">{cartBD?.restaurantName}</Typography>

                {/** ORDER TYPE BUTTON*/}
                <div className="selector">
                  {restaurantDetails?.deliveryType && <DeliveryModeSwitch defaultValue={browserOrder.deliveryOrderType}
                    restaurantDetails={restaurantDetails} onChange={handleChangeDeliveryOrderType} />}
                </div>

                {/** ADDRESS - CONTACT */}
                <div className="step-delivery__container">
                  <div onClick={openLocation} className="new__button">
                    {isDelivery
                      ? <LocationOn style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
                      : <Contacts style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
                    }
                    <div className="new__button__address">
                      <p>{isDelivery ? (browserOrder?.address?.name || translation.Payment.deliveryAddress) : (browserOrder?.receiver || translation.Payment.contactData)}</p>
                      <p><span>{isDelivery ? (browserOrder?.address?.details || null) : (browserOrder?.receiverPhone || null)}</span></p>
                    </div>
                    <ArrowForwardIos style={{ alignSelf: 'center', color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showLocation ? "rotate(90deg)" : "" }} component="svg" />
                  </div>

                  <AddressesModal required showDialogForm={showLocation} setShowDialogForm={setShowLocation}
                    handleContinue={handleSelectDeliveryAddress} handleAdd={handleUpsertAddress}
                  />
                  <AddAddressModal showDialogForm={showAddAddressForm} setShowDialogForm={setShowAddAddressForm} onClose={closeAddAddressForm}
                    edit={!addressToEdit} selectedAddress={addressToEdit} restaurant={restaurant} onSavedAddress={handleOnSavedAddress} />
                </div>
                <div className="spacer" />

                {/** DATE & TIME */}
                <div onClick={openDateAndTime} className="new__button" style={{ marginTop: '1.8em' }}>
                  <WatchLater style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
                  <p>
                    {restaurantAvailableDates && browserOrder?.deliveryDay ? translation.Code === "ES"
                      ? browserOrder?.deliveryDay?.labelEs : browserOrder?.deliveryDay?.labelEn
                      : translation.Payment.labelDateAndTimeOf}
                    {restaurantAvailableTimes && browserOrder?.deliveryHour ? ", " + browserOrder?.deliveryHour?.label : null}
                  </p>
                  <ArrowForwardIos style={{ color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showDateAndTime ? "rotate(90deg)" : "" }} component="svg" />
                </div>
                {showDateAndTime && <div className="step-delivery__sections">
                  <div className="field-group">
                    <div className="field-group__half">
                      <div className="field-group">
                        {restaurantAvailableDates ? <CustomSelect
                          required
                          id="deliveryDateText"
                          isClearable={false}
                          errorMessage={errorsSteps["deliveryDateText"]}
                          fieldLabel={translation.Payment.date}
                          value={browserOrder?.deliveryDay}
                          options={restaurantAvailableDates}
                          onChange={date => handleSelectDeliveryDate(date)}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => translation.Code === "ES" ? option.labelEs : option.labelEn}
                        /> : <Skeleton variant="rect" width="100%" height={39} animation="wave" />}
                      </div>
                      <div className="field-group">
                        {restaurantAvailableTimes ? <CustomSelect
                          required
                          id="deliveryHour"
                          isClearable={false}
                          errorMessage={errorsSteps["deliveryHour"]}
                          fieldLabel={translation.Payment.time}
                          value={browserOrder?.deliveryHour}
                          options={restaurantAvailableTimes}
                          onChange={time => handleSelectDeliveryTimeline(time)}
                          getOptionValue={option => option.hour}
                          getOptionLabel={option => option.label}
                        /> : <Skeleton variant="rect" width="100%" height={39} animation="wave" />}
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="spacer" />

                {/** DISCOUNT COUPON */}
                <div onClick={openDiscountCoupon} className="new__button">
                  <p>{browserOrder?.coupon ? `${translation.Payment.couponApplied} - ${browserOrder?.coupon}` : translation.Payment.haveAnyDiscount}</p>
                  <ArrowForwardIos style={{ color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showDiscountCoupon ? 'rotate(90deg)' : '' }} component="svg" />
                </div>
                {showDiscountCoupon && <div className="coupon">
                  <div className="grid--small-form">
                    <CustomInput
                      id="couponMessage"
                      errorMessage={errorsSteps["couponMessage"]}
                      fieldLabel={translation.Payment.coupon}
                      placeholder={translation.Payment.discountCode}
                      value={browserOrder?.coupon}
                      onChange={evt => handleChangeDiscountCoupon(evt.target.value)}
                    />
                    {browserOrder?.coupon ?
                      <button className="button--primary button--max button--plus" onClick={() => clearDiscount()}>
                        <h3><strong>{translation.Payment.clearCoupon}</strong></h3>
                      </button> :
                      <button className="button--primary button--max button--plus" onClick={() => applyDiscount()}
                        disabled={!discountCoupon}>
                        <h3><strong>{translation.Payment.apply}</strong></h3>
                      </button>}
                  </div>
                </div>}
                <div className="spacer" />
              </div>

              <Voucher browserOrder={browserOrder} edit={editPurchase} />

              <div className="new__step__footer">
                <div className="new__step__footer__container">
                  <div className="step-delivery__checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlank component={"svg"} style={{ color: "#2c2c2c" }} />}
                          checkedIcon={<CheckBox style={{ color: "#2c2c2c" }} component={"svg"} />}
                          checked={acceptTerms}
                          onChange={() => setAcceptTerms(!acceptTerms)}
                          value="acceptTerms"
                        />
                      }
                      label={translation.Payment.acceptTerms}
                    />
                  </div>

                  <div className="step-delivery__button-next">
                    {browserOrder?.total !== undefined ? <button onClick={continueToPay} disabled={!enableButton} className="button--primary button--large">
                      <span>{translation.Payment.pay}</span>
                      <span className="bullet__white">{` • `}</span>
                      <span className="cart-total">
                        {` $${parseFloat(browserOrder?.total).toFixed(2)}`}
                      </span>
                    </button> : <Skeleton variant="rect" width="300px" height={52} animation="wave" />}
                  </div>
                </div>
              </div>
            </div>˜
          </div>
        </div>
      </div>
    </div >
  )
};

export default CheckoutOrder;