/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import "./css/PaymentProcessStyle.scss";

import useTranslations from "translations/useTranslations";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { selectCartTotal } from "modules/cart/store/cart.reducers";

import StepSelectPaymentMethod from "./components/StepSelectPaymentMethod/StepSelectPaymentMethod";
import { TU_BOLSILLO, CASH, BAMBORA } from "./paymentType";
import {
  clearPaymentStore, doFetchTuBolsilloCredit, fetchCll2PaymentMethods, chargeCll2Payment, doFetchCll2ClientToken, doCashPayment,
} from "./store/payment.actions";
import { selectCouponPaymentFailure, selectCouponPaymentSuccess, selectCouponPayment } from "./store/delivery.reducers";
import { notifyError } from "modules/notification/store/notification.actions";
import { getDeviceDetectInfo, loadCountryCode } from "core/util/network";
import { selectBrowserOrder } from "modules/Checkout/store/checkout.reducers";
import { payOrder } from "../Pay/store/pay.actions";
import { TASA_PESO } from "../../constants";
import { showAlert } from "modules/alert/store/alert.actions";


export default function PaymentProcess({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const translation = useTranslations();
  const [deviceInfo, setDeviceInfo] = useState({});
  const browserOrder = useSelector(selectBrowserOrder);
  const couponPaymentSuccess = useSelector(selectCouponPaymentSuccess);
  const couponPaymentFailure = useSelector(selectCouponPaymentFailure);
  const couponPaymentResult = useSelector(selectCouponPayment);
  const cartTotal = useSelector(selectCartTotal);
  const [activeStep, setActiveStep] = useState(0);
  const STEP_SELECT_PAYMENT_METHOD = 0;

  const resizeAndfetchPaymentData = () => {
    dispatch(clearPaymentStore());
    dispatch(doFetchTuBolsilloCredit());
    dispatch(fetchCll2PaymentMethods());
    dispatch(doFetchCll2ClientToken());
  }

  useEffect(() => {
    if (Object.keys(deviceInfo).length === 0) {
      (async () => {
        let { country, address } = await loadCountryCode();
        const info = getDeviceDetectInfo();
        const deviceId = localStorage.getItem('__cll_brid');
        setDeviceInfo({
          ...deviceInfo,
          ip: address,
          country,
          ...info,
          deviceId,
        });
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!browserOrder || !browserOrder.offers || browserOrder.offers.length === 0) {
      // const checkoutInfo = JSON.parse(localStorage.getItem('checkout'));
      // if (!checkoutInfo) {
      history.push({ pathname: "/prv/checkout" });
      // } else {
      //   setDeviceInfo(deliveryInfo);
      //   resizeAndfetchPaymentData();
      // }
    } else {
      resizeAndfetchPaymentData();
    }
  }, []);

  useEffect(() => {
    history.location.pathname === '/prv/pay' && setActiveStep(STEP_SELECT_PAYMENT_METHOD);
  }, []);
  useEffect(() => window.scrollTo(0, 0), [activeStep]);

  useEffect(() => {
    couponPaymentSuccess && couponPaymentResult && history.push({ pathname: "/prv/pay_success" });
  }, [couponPaymentSuccess]);

  useEffect(() => {
    couponPaymentFailure && history.push({ pathname: "/prv/pay_error" });
  }, [couponPaymentFailure]);

  const handlePayment = (method, paymentMethod = null, newMethod = null) => {
    // const checkoutInfo = JSON.parse(localStorage.getItem('checkout'));
    // cannot pay without any of the following delivery infos
    if (!browserOrder || !browserOrder.accountId || !browserOrder.deliveryDay ||
      !browserOrder.deliveryHour || !browserOrder.restaurantId || !browserOrder.total ||
      (browserOrder.deliveryOrderType === 'DELIVERY' && !browserOrder.address.id) ||
      (browserOrder.deliveryOrderType === 'PICKUP' && (!browserOrder.receiver || !browserOrder.receiverPhone))) {
      history.push({ pathname: "/prv/checkout" });
      return null;
    }

    let DATA = {
      accountId: browserOrder.accountId,
      receiverPhone: browserOrder.receiverPhone,
      receiver: browserOrder.receiver,
      deliveryOrderType: browserOrder.deliveryOrderType,
      subtotal: browserOrder.subtotal,
      shipping: browserOrder.shipping,
      taxes: browserOrder.taxes,
      discountByTalon: browserOrder.discountByTalon,
      totalCost: browserOrder.total,
      deliveryDay: browserOrder.deliveryDay,
      deliveryHour: browserOrder.deliveryHour,
      payment: {
        accountId: browserOrder.accountId,
        paymentMethodId: method,
        orderId: 0,
        required: false
      },
      restaurantId: browserOrder.restaurantId,
    }

    dispatch(payOrder(DATA));

    const paymentRequest = { deviceInfo, delivery: { ...browserOrder, payment: { billTypeNomid: 1 } } };

    switch (method) {
      case BAMBORA: {
        dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
        dispatch(chargeCll2Payment(BAMBORA, parseFloat(cartTotal).toFixed(2), paymentMethod ? paymentMethod.id : null, null, paymentRequest, newMethod));
        break;
      }
      case TU_BOLSILLO: {
        // dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
        // dispatch(doTuBolsilloPayment(parseFloat(cartTotal).toFixed(2), paymentRequest, history));
        break;
      }
      case CASH: {
        dispatch(showAlert(() => {
          dispatch(doCashPayment(parseFloat(cartTotal * TASA_PESO).toFixed(2), paymentRequest, history))
        },
          `Este pedido debe ser pagado al mensajero de dicho restaurante en el domicilio indicado en EFECTIVO`));
        break;
      }
      default: {
        dispatch(notifyError("Debe seleccionar método de pago"));
        return false;
      }
    }
  };

  const handleBack = () => history.push({ pathname: '/prv/checkout' });

  return (
    <div className="container med-container">
      <div className="steps new__steps">
        <div className={classes.root}>
          <div class="step new__step">
            <StepSelectPaymentMethod
              history={history}
              translation={translation}
              cartTotal={parseFloat(cartTotal).toFixed(2)}
              handleBack={handleBack}
              handleNext={handlePayment}
              handleAddAndNext={handlePayment}
            />
          </div>
        </div>
      </div>

      {/*<div className="grid--sidebar">
        <div className="steps">
          <div className={classes.root}>
            <div className="step">
              <StepSelectPaymentMethod
                history={history}
                translation={translation}
                cartTotal={parseFloat(cartTotal).toFixed(2)}
                handleBack={handleBack}
                handleNext={handlePayment}
                handleAddAndNext={handlePayment}
              />
            </div>
          </div>
        </div>
        {fullScreenSize ? <div className="cart-resume-sidebar"><CartResume showCoupon={false} /></div> : null} 
      </div>*/}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const useStepIconStyles = makeStyles({
  root: {
    color: "#707a85",
    fontWeight: 600,
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#2c2c2c"
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "50%",
    border: "1.5px solid #2c2c2c",
    backgroundColor: "#ffffff"
  },
  completed: {
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2c2c2c"
  },
  icon: {},
});

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active /*, completed*/ } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      <div className={classes.circle}>
        <span className={classes.icon}>{props.icon}</span>
      </div>
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};
