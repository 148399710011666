/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { browserHistory } from "../../utils/History";
import InfiniteScroll from 'react-infinite-scroll-component';
import './css/RestaurantCarouselsListStyles.scss';
import 'swiper/swiper.scss';
import useTranslations from "../../translations/useTranslations";
import CuisineType from '../../modules/home/component/CuisineType/CuisineType';
import PromotionsCarousel from "../../component/PromotionsCarousel/PromotionsCarousel";
import {
  selectDefaultPageCounter, selectFilters, selectRestaurants, selectDefaultFilters, selectRestaurantsTotal,
  selectIsLoading, selectBusinessListByProductType, selectBusinessTotalByProductType, selectShowAllBusinessByProductType
} from '../../store/filter/filter.reducers';
import {
  loadRestaurantsCarouselsList, loadBusinessGroupByProductType, showAllBusinessForProductType
} from '../../store/filter/filter.actions';
import BusinessCarouselList from "./component/BusinessCarouselList/BusinessCarouselList";
import Search from '../../modules/Topbar/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { store } from "../../App";
import ListGroupByProductType from "./component/ListGroupByProductType/ListGroupByProductType";
import { loadBusinessCategoriesWithFilters } from "../home/component/BusinessCategories/store/business-categories.actions";
import { loadAllProductTypes } from "../home/component/CuisineType/store/cuisine-type.actions";
import { selectBusinessCategories } from "../home/component/BusinessCategories/store/business-categories.reducers";
import Alert from 'modules/alert/Alert';
import { setLoadingMaskStatus } from 'component/Mask/store/mask.actions';
import Address from 'modules/Topbar/Address';

const OFFSET = -200;
const scrollToRef = (root, ref) => root.scrollTo(0, ref.current.offsetTop + OFFSET);

export const HomeBusinessList = (props) => {
  const history = browserHistory;
  const { location } = props;
  const dispatch = useDispatch();
  const translation = useTranslations();
  const filters = useSelector(selectFilters);
  const defaultFilters = useSelector(selectDefaultFilters);
  const defaultPageCounter = useSelector(selectDefaultPageCounter);
  const restaurantsCarouselsList = useSelector(selectRestaurants);
  const isLoading = useSelector(selectIsLoading);
  const restaurantsCarouselsListTotal = useSelector(selectRestaurantsTotal);
  const restaurantsByProductTypeList = useSelector(selectBusinessListByProductType);
  const restaurantsByProductTypeListTotal = useSelector(selectBusinessTotalByProductType);
  const showAllBusinessByProductType = useSelector(selectShowAllBusinessByProductType);
  const listRef = useRef(null);
  const [restaurantsTotal, setRestaurantsTotal] = useState(0);
  const [restaurantsByProductTypeTotal, setRestaurantsByProductTypeTotal] = useState(0);
  const [restaurants, setRestaurants] = useState(null);
  const [restaurantsByProductType, setRestaurantsByProductType] = useState(null);
  const [mobileSize, setMobileSize] = useState(false);
  const [headerMessage, setHeaderMessage] = useState(translation.Home.sectionHeaderTitleRestaurant);
  const [pointer, setPointer] = useState(1);
  const [fixedFilters, setFixedFilters] = useState({});
  const [preview] = useState(location && location.state && location.state.preview ? location.state.preview : 4);
  const [previewBusiness] = useState(8);
  const businessCategorySelected = store.getState().filters.filters.businessCategory;
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    if (!isRedirect && location.search !== '?redirect=true') {
      // dispatch(loadProductTypeByCategory(categoriesSelected));
      dispatch(loadAllProductTypes());
      dispatch(loadBusinessCategoriesWithFilters(filters));
      setFixedFilters({ ...filters });
      // dispatch(setLoadingMaskStatus(true));
      try {
        search(JSON.parse(localStorage.getItem('address')));
      } catch (e) {
        search(null);
      }
    } else {
      setIsRedirect(true);
    }
  }, []);

  useEffect(() => {
    setFixedFilters({ ...filters });
    const root = document.getElementById('root');
    if (listRef.current && filters.classificationLabel?.length) {
      setTimeout(() => { scrollToRef(root, listRef); }, 500);
    }
    if (businessCategorySelected?.id) {
      if (businessCategorySelected.id === 1) {
        // document.getElementsByTagName('meta')["seo"].content = translation.Home.metaRestaurant;
        // document.title = translation.Home.tabTitleRestaurant;
        setHeaderMessage(translation.Home.sectionHeaderTitleRestaurant);
      } else if (businessCategorySelected.id === 2) {
        // document.getElementsByTagName('meta')["seo"].content = translation.Home.metaSupermarket;
        // document.title = translation.Home.tabTitleSupermarket;
        setHeaderMessage(translation.Home.sectionHeaderTitleSupermarket);
      } else {
        // document.getElementsByTagName('meta')["seo"].content = translation.Home.metaRestaurant;
        // document.title = translation.Home.tabTitleRestaurant;
        setHeaderMessage(translation.Home.sectionHeaderTitleRestaurant);
      }
    } else {
      // document.getElementsByTagName('meta')["seo"].content = translation.Home.metaRestaurant;
      // document.title = translation.Home.tabTitleRestaurant;
      setHeaderMessage(translation.Home.sectionHeaderTitleRestaurant);
    }
  }, [filters]);

  // useEffect(() => {
  //   if (businessCategoriesList?.length && props.match.params.businessCategorySlug) {
  //     if (!businessCategorySelected?.id || !businessCategorySelected?.slug) {
  //       const URL = `/${props.match.params.businessCategorySlug}`;
  //       let BC = businessCategoriesList.filter(cat => cat.slug === URL);
  //       if (BC?.length) {
  //         const cat = [BC[0].id];
  //         const newFilters = { ...filters, businessCategory: BC[0], categories: cat };
  //         dispatch(loadProductTypeByCategory(cat));
  //         dispatch(showAllBusinessForProductType(filters.productTypes?.length));
  //         dispatch(loadBusinessGroupByProductType(previewBusiness, newFilters, 1, defaultPageCounter));
  //       }
  //     }
  //   }
  // }, [businessCategoriesList]);

  useEffect(() => { !isLoading && dispatch(setLoadingMaskStatus(false)); }, [isLoading]);
  useEffect(() => { restaurantsCarouselsList && setRestaurants(restaurantsCarouselsList); }, [restaurantsCarouselsList]);
  useEffect(() => { restaurantsCarouselsListTotal && setRestaurantsTotal(restaurantsCarouselsListTotal); }, [restaurantsCarouselsListTotal]);
  useEffect(() => { restaurantsByProductTypeList && setRestaurantsByProductType(restaurantsByProductTypeList); }, [restaurantsByProductTypeList]);
  useEffect(() => { restaurantsByProductTypeListTotal && setRestaurantsByProductTypeTotal(restaurantsByProductTypeListTotal); }, [restaurantsByProductTypeListTotal]);

  const search = (place) => {
    if (place) {
      const { municipalityid, provinceid, localityid } = place;
      const auxFilter = {
        ...defaultFilters,
        municipalityid,
        provinceid,
        localityid,
        businessCategory: businessCategorySelected,
        categories: [] // categoriesSelected,
      }
      setFixedFilters({ ...auxFilter });
      dispatch(showAllBusinessForProductType(auxFilter.productTypes && auxFilter.productTypes.length > 0));
      if (showAllBusinessByProductType) {
        dispatch(loadRestaurantsCarouselsList(preview, auxFilter, 1, defaultPageCounter));
      } else {
        dispatch(loadBusinessGroupByProductType(previewBusiness, auxFilter, 1, defaultPageCounter));
      }
    }
  }
  const fetchMoreData = () => {
    const page = pointer + 1;
    setPointer(page);
    if (showAllBusinessByProductType) {
      dispatch(loadRestaurantsCarouselsList(preview, fixedFilters, page, defaultPageCounter, true));
    } else {
      dispatch(loadBusinessGroupByProductType(previewBusiness, fixedFilters, page, defaultPageCounter, true));
    }
  };
  const isMobile = () => window.innerWidth < 1200;
  const handleResize = () => {
    const isMobile = window.innerWidth < 1200;
    setMobileSize(isMobile);
  };

  const showAllBusinessFromProductTypeSelected = (productType) => {
    let types = [productType];
    const filtersAux = { ...filters, productTypes: types };
    dispatch(showAllBusinessForProductType(true));
    dispatch(loadRestaurantsCarouselsList(preview, filtersAux, 1, defaultPageCounter));
  };

  const SKELETON_ROW = <div className="skeleton-row">
    {[...Array(2)].map((_, index) => <div key={index} className="restaurants-skeleton">
      <div className="restaurants-skeleton__header">
        <div className="restaurants-skeleton__header__title">
          <Skeleton variant="rect" width={100} height={23} animation="wave" />
        </div>
        <div className="restaurants-skeleton__header__subtitle">
          <Skeleton variant="rect" width={150} height={13} animation="wave" style={{ marginBottom: '5px' }} />
          {!isMobile() ? <div className="restaurants-skeleton__header__nav">
            <Skeleton variant="rect" width={37.31} height={38} animation="wave" />
            <Skeleton variant="rect" width={37.31} height={38} animation="wave" />
          </div> : null}
        </div>
      </div>
      <div className="restaurants-skeleton__row">
        {[...Array(5)].map((_, index) =>
          <Skeleton key={index} variant="rect" width={277.5} height={228.38} animation="wave" />)}
      </div>
      <div style={{ height: '40px', width: '100%' }} />
    </div>)}
  </div>

  return (
    <div className="restaurant-carousels-list">
      <Alert />
      {/* DIRECTIONS FOR MOBILE */}
      {mobileSize ? <div className="wrap container">
        <ul className="address-wrap">
          <Address history={history} />
        </ul>
      </div> : null}
      {/* {mobileSize ? null : <PromotionsCarousel {...props} />} */}
      {mobileSize ? <div className="wrap container h1-container">
        <h1>{translation.Home.sectionHeaderMobileTitle}</h1>
      </div> : null}
      <section id="cuisine-section" className="section">
        <CuisineType {...props} translation={translation} />
      </section>

      {/* SEARCH FOR MOBILE */}
      {mobileSize ? <div className="wrap container">
        <ul className="search-wrap">
          <Search grown={true} />
        </ul>
      </div> : null}
      {mobileSize ? <PromotionsCarousel {...props} /> : null}
      {mobileSize ? null : <div className="wrap container h1-container"><h1>{headerMessage}</h1></div>}

      <div ref={listRef}>
        {isLoading
          ? SKELETON_ROW
          : showAllBusinessByProductType
            ? restaurants && restaurants.length > 0
              ? <div id="scrollableDiv" className="wrap container-fluid" style={{ marginTop: '2em' }}>
                <InfiniteScroll
                  dataLength={restaurants.length}
                  next={fetchMoreData}
                  hasMore={restaurants.length < restaurantsTotal}
                  loader={SKELETON_ROW}
                  scrollableTarget="root">
                  {restaurants.map((data, k) => {
                    let menu = data.menu[0] || {};
                    let offers = menu.offers || [];
                    if (offers && offers.length > 0) {
                      return <BusinessCarouselList key={k} {...props} restaurant={data} offers={offers}
                        mobileSize={mobileSize} />;
                    }
                    return null;
                  })}
                </InfiniteScroll>
              </div>
              : <div className="not-found-container not-found-wrapper">
                <h1>{translation.RestaurantList.noResult}</h1>
              </div>
            : restaurantsByProductType && restaurantsByProductType.length > 0
              ? <div id="scrollableDiv" className="wrap container-fluid scroll-fluid">
                <InfiniteScroll
                  dataLength={restaurantsByProductType.length}
                  next={fetchMoreData}
                  hasMore={restaurantsByProductType.length < restaurantsByProductTypeTotal}
                  loader={SKELETON_ROW}
                  scrollableTarget="root">
                  {restaurantsByProductType.map((item, k) => {
                    if (item.businesses && item.businesses.length > 0) {
                      return <ListGroupByProductType key={k} {...props} data={item} mobileSize={mobileSize}
                        showAll={showAllBusinessFromProductTypeSelected}
                      />;
                    }
                    return null;
                  })}
                </InfiniteScroll>
              </div>
              : <div className="not-found-container not-found-wrapper">
                <h1>{translation.RestaurantList.noResult}</h1>
              </div>
        }
      </div>

      <div className="default_page_message">
        <h2>{translation.Home.defaultPageMessageTitle}</h2>
        <span>{translation.Home.defaultPageMessageContent}</span>
      </div>
    </div>
  )
};