import React, { useEffect, useState } from "react";
import "./css/PaymentProcessSuccessStyle.scss";
import firebase from "firebase";
import segment from "utils/segment";
import useTranslations from "../../translations/useTranslations";
import { cleanCart } from "../cart/store/cart.actions";
import { selectPayResult } from "../Pay/store/pay.reducers";
import { clearPaymentStore } from "./store/payment.actions";
import { notifyOrderCreated } from "./store/delivery.actions";
import { selectCouponPayment } from "./store/delivery.reducers";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { useSelector, useDispatch } from "react-redux";
import { setBDOrder, storeBrowserOrder } from "modules/Checkout/store/checkout.actions";
import { selectCashResult, selectBraintreeResult, selectTuBolsilloResult, selectCll2Result } from "./store/payment.reducers";
import { TASA_PESO } from "../../constants";

export default function PaymentProcessSuccess(props) {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const cll2Result = useSelector(selectCll2Result);
  const cashResult = useSelector(selectCashResult);
  const paySuccessResponse = useSelector(selectPayResult);
  const braintreeResult = useSelector(selectBraintreeResult);
  const tuBolsilloResult = useSelector(selectTuBolsilloResult);
  const couponPaymentResult = useSelector(selectCouponPayment);
  const [payResult, setPayResult] = useState(null);

  useEffect(() => {
    dispatch(setLoadingMaskStatus(false));
    dispatch(cleanCart());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paySuccessResponse && paySuccessResponse.detailorder) {
      setPayResult(paySuccessResponse);
    }
    // eslint-disable-next-line
  }, [paySuccessResponse]);

  useEffect(() => {
    if (couponPaymentResult && couponPaymentResult.purchase) {
      dispatch(notifyOrderCreated(couponPaymentResult.purchase.orderId));
    }
    // eslint-disable-next-line
  }, [couponPaymentResult]);

  useEffect(() => {
    if (payResult) {
      sendAnalytics();
    }
    // eslint-disable-next-line
  }, [payResult]);


  const sendAnalytics = () => {
    const firebaseAnalytics = firebase.analytics();
    const firebaseData = {
      transaction_id: null,
      affiliation: 'www.cuballama.com/mercado',
      currency: 'USD',
      value: null,
      items: []
    };
    const facebookPixelData = {
      currency: 'USD',
      value: null,
      content_type: 'product',
      contents: []
    };
    const segmentAnalyticsData = {
      currency: 'USD',
      orderId: null,
      total: null,
      items: []
    };
    const gtagData = {
      send_to: "AW-792986928/93KVCNng--0BELCKkPoC",
      value: null,
      currency: 'USD',
      orderId: null,
      items: [],
    };
    let USER_ID = null;

    if (tuBolsilloResult) {
      firebaseData.transaction_id = tuBolsilloResult.purchase.orderId;
      firebaseData.value = tuBolsilloResult.purchase.totalCost;

      facebookPixelData.value = tuBolsilloResult.purchase.totalCost;
      facebookPixelData.contents = tuBolsilloResult.purchase.offersInfo;

      USER_ID = tuBolsilloResult.purchase.accountId;
      segmentAnalyticsData.orderId = tuBolsilloResult.purchase.orderId;
      segmentAnalyticsData.total = tuBolsilloResult.purchase.totalCost;
      segmentAnalyticsData.items = tuBolsilloResult.purchase.offersInfo;

      gtagData.orderId = tuBolsilloResult.purchase.orderId;
      gtagData.value = tuBolsilloResult.purchase.totalCost;
      gtagData.items = tuBolsilloResult.purchase.offersInfo;
    } else if (cashResult) {
      firebaseData.transaction_id = cashResult.purchase.orderId;
      firebaseData.value = cashResult.purchase.totalCost;

      facebookPixelData.value = cashResult.purchase.totalCost;
      facebookPixelData.contents = cashResult.purchase.offersInfo;

      USER_ID = cashResult.purchase.accountId;
      segmentAnalyticsData.orderId = cashResult.purchase.orderId;
      segmentAnalyticsData.total = cashResult.purchase.totalCost;
      segmentAnalyticsData.items = cashResult.purchase.offersInfo;

      gtagData.orderId = tuBolsilloResult.purchase.orderId;
      gtagData.value = tuBolsilloResult.purchase.totalCost;
      gtagData.items = tuBolsilloResult.purchase.offersInfo;
    } else if (braintreeResult) {
      firebaseData.transaction_id = braintreeResult.purchase.orderId;
      firebaseData.value = braintreeResult.purchase.totalCost;

      facebookPixelData.value = braintreeResult.purchase.totalCost;
      facebookPixelData.contents = braintreeResult.purchase.offersInfo;

      USER_ID = braintreeResult.purchase.accountId;
      segmentAnalyticsData.orderId = braintreeResult.purchase.orderId;
      segmentAnalyticsData.total = braintreeResult.purchase.totalCost;
      segmentAnalyticsData.items = braintreeResult.purchase.offersInfo;

      gtagData.orderId = braintreeResult.purchase.orderId;
      gtagData.value = braintreeResult.purchase.totalCost;
      gtagData.items = braintreeResult.purchase.offersInfo;
    } else if (cll2Result) {
      firebaseData.transaction_id = cll2Result.purchase.orderId;
      firebaseData.value = cll2Result.purchase.totalCost;

      facebookPixelData.value = cll2Result.purchase.totalCost;
      facebookPixelData.contents = cll2Result.purchase.offersInfo;

      USER_ID = cll2Result.purchase.accountId;
      segmentAnalyticsData.orderId = cll2Result.purchase.orderId;
      segmentAnalyticsData.total = cll2Result.purchase.totalCost;
      segmentAnalyticsData.items = cll2Result.purchase.offersInfo;

      gtagData.orderId = cll2Result.purchase.orderId;
      gtagData.value = cll2Result.purchase.totalCost;
      gtagData.items = cll2Result.purchase.offersInfo;
    } else if (couponPaymentResult) {
      firebaseData.transaction_id = couponPaymentResult.purchase.orderId;
      firebaseData.value = couponPaymentResult.purchase.totalCost;

      facebookPixelData.value = couponPaymentResult.purchase.totalCost;
      facebookPixelData.contents = couponPaymentResult.purchase.offersInfo;

      USER_ID = couponPaymentResult.purchase.accountId;
      segmentAnalyticsData.orderId = couponPaymentResult.purchase.orderId;
      segmentAnalyticsData.total = couponPaymentResult.purchase.totalCost;
      segmentAnalyticsData.items = couponPaymentResult.purchase.offersInfo;

      gtagData.orderId = couponPaymentResult.purchase.orderId;
      gtagData.value = couponPaymentResult.purchase.totalCost;
      gtagData.items = couponPaymentResult.purchase.offersInfo;
    } else if (payResult) {
      firebaseData.transaction_id = payResult.orderId;
      firebaseData.value = payResult.detailorder.totalCost;

      facebookPixelData.value = payResult.detailorder.totalCost;
      facebookPixelData.contents = payResult.detailorder.offersInfo;

      USER_ID = payResult.accountId;
      segmentAnalyticsData.orderId = payResult.orderId;
      segmentAnalyticsData.total = payResult.detailorder.totalCost;
      segmentAnalyticsData.items = payResult.detailorder.offersInfo;

      gtagData.orderId = payResult.orderId;
      gtagData.value = payResult.detailorder.totalCost;
      gtagData.items = payResult.detailorder.offersInfo;
    }

    firebaseAnalytics.logEvent(firebase.analytics.EventName.PURCHASE, firebaseData);
    segment.track('Purchased', segmentAnalyticsData, { userId: USER_ID });
    window.gtag('config', 'AW-792986928');
    window.gtag('event', 'conversion', gtagData);
  }

  const done = () => {
    dispatch(setBDOrder(null));
    dispatch(storeBrowserOrder(null));
    dispatch(clearPaymentStore());
    props.history.push({ pathname: `/` });
  }

  const payResultHtml = payResult ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{payResult?.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`$${parseFloat(payResult?.detailorder?.totalCost).toFixed(2)}`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{payResult?.detailorder?.restaurantName}</td>
      </tr>
      <tr>
        <td>{translation.PaymentSuccess.contactName}:</td>
        <td className="right">{payResult?.detailorder?.contactName}</td>
      </tr>
      <tr>
        <td>{translation.PaymentSuccess.deliveryAddress}:</td>
        <td className="right">{payResult?.detailorder?.deliveryAddress}</td>
      </tr>
      <tr>
        <td>{translation.PaymentSuccess.telephone}:</td>
        <td className="right">{payResult.detailorder.telephone}</td>
      </tr>
      <tr>
        <td>
          {payResult?.detailorder?.deliveryOrderType === "DELIVERY" ? translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${payResult.detailorder.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  const tuBolsilloResultHtml = tuBolsilloResult ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{tuBolsilloResult.purchase.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`$${tuBolsilloResult.purchase.totalCost}`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{tuBolsilloResult.restaurant.name}</td>
      </tr>
      {tuBolsilloResult.purchase && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{tuBolsilloResult.purchase.contactName}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{tuBolsilloResult.purchase.receiver}</td>
        </tr>}
      {tuBolsilloResult.purchase && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.deliveryAddress}:</td>
          <td className="right">{tuBolsilloResult.purchase.deliveryAddress}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.pickUpAddress}:</td>
          <td className="right">{tuBolsilloResult.restaurant.restaurantAddress}</td>
        </tr>}
      {tuBolsilloResult.purchase && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{tuBolsilloResult.purchase.telephone}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{tuBolsilloResult.purchase.receiverPhone || '-'}</td>
        </tr>}
      <tr>
        <td>
          {tuBolsilloResult.purchase && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
            && tuBolsilloResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
            translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${tuBolsilloResult.purchase.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  const braintreeResultHtml = braintreeResult ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{braintreeResult.purchase.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`$${braintreeResult.purchase.totalCost}`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{braintreeResult.restaurant.name}</td>
      </tr>
      {braintreeResult.purchase && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{braintreeResult.purchase.contactName}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{braintreeResult.purchase.receiver}</td>
        </tr>}
      {braintreeResult.purchase && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.deliveryAddress}:</td>
          <td className="right">{braintreeResult.purchase.deliveryAddress}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.pickUpAddress}:</td>
          <td className="right">{braintreeResult.restaurant.restaurantAddress}</td>
        </tr>}
      {braintreeResult.purchase && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{braintreeResult.purchase.telephone}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{braintreeResult.purchase.receiverPhone || '-'}</td>
        </tr>}
      <tr>
        <td>
          {braintreeResult.purchase && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
            && braintreeResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
            translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${braintreeResult.purchase.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  const cll2ResultHtml = cll2Result ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{cll2Result.purchase.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`$${cll2Result.purchase.totalCost}`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{cll2Result.restaurant.name}</td>
      </tr>
      {cll2Result.purchase && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{cll2Result.purchase.contactName}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{cll2Result.purchase.receiver}</td>
        </tr>}
      {cll2Result.purchase && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.deliveryAddress}:</td>
          <td className="right">{cll2Result.purchase.deliveryAddress}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.pickUpAddress}:</td>
          <td className="right">{cll2Result.restaurant.restaurantAddress}</td>
        </tr>}
      {cll2Result.purchase && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{cll2Result.purchase.telephone}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{cll2Result.purchase.receiverPhone || '-'}</td>
        </tr>}
      <tr>
        <td>
          {cll2Result.purchase && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
            && cll2Result.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
            translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${cll2Result.purchase.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  const cashResultHtml = cashResult ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{cashResult.purchase.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`${cashResult.purchase.totalCost * TASA_PESO} CUP`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{cashResult.restaurant.name}</td>
      </tr>
      {cashResult.purchase && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{cashResult.purchase.contactName}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{cashResult.purchase.receiver}</td>
        </tr>}
      {cashResult.purchase && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.deliveryAddress}:</td>
          <td className="right">{cashResult.purchase.deliveryAddress}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.pickUpAddress}:</td>
          <td className="right">{cashResult.restaurant.restaurantAddress}</td>
        </tr>}
      {cashResult.purchase && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{cashResult.purchase.telephone}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{cashResult.purchase.receiverPhone || '-'}</td>
        </tr>}
      <tr>
        <td>
          {cashResult.purchase && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
            && cashResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
            translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${cashResult.purchase.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  const couponPaymentResultHtml = couponPaymentResult ? (
    <tbody>
      <tr>
        <td>{translation.Payment.paymentId}:</td>
        <td className="right">{couponPaymentResult.purchase.orderId}</td>
      </tr>
      <tr>
        <td>{translation.Payment.total}:</td>
        <td className="right">{`$${couponPaymentResult.purchase.totalCost}`}</td>
      </tr>
      <tr>
        <td>{translation.Payment.restaurant}:</td>
        <td className="right">{couponPaymentResult.restaurant.name}</td>
      </tr>
      {couponPaymentResult.purchase && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{couponPaymentResult.purchase.contactName}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.contactName}:</td>
          <td className="right">{couponPaymentResult.purchase.receiver}</td>
        </tr>}
      {couponPaymentResult.purchase && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.deliveryAddress}:</td>
          <td className="right">{couponPaymentResult.purchase.deliveryAddress}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.pickUpAddress}:</td>
          <td className="right">{couponPaymentResult.restaurant.restaurantAddress}</td>
        </tr>}
      {couponPaymentResult.purchase && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
        && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{couponPaymentResult.purchase.telephone}</td>
        </tr> :
        <tr>
          <td>{translation.PaymentSuccess.telephone}:</td>
          <td className="right">{couponPaymentResult.purchase.receiverPhone || '-'}</td>
        </tr>}
      <tr>
        <td>
          {couponPaymentResult.purchase && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid
            && couponPaymentResult.purchase.deliveryOrderTypeNomByDeliveryOrderTypeNomid.id === 1 ?
            translation.Payment.delivery : translation.Payment.pickUp}:
        </td>
        <td className="right">{`${couponPaymentResult.purchase.deliveryDate} ${translation.Payment.approx}`}</td>
      </tr>
    </tbody>
  ) : undefined;

  return (
    <div className="payment-success">
      <div className="account-container">
        <div className="basic-card medium relative mb-20">
          <div className="d-flex justfy-content-center flex-column">
            <div className="expanded-block">
              <img className="card-icon" src={"images/icons/system/circle-check-green.svg"} alt="" />
              <div className="FlexColumn">
                <h4>{translation.Payment.successTitle}</h4>
                <p>{translation.Payment.successSubtitle}</p>
                <div className="resume">
                  <div className="spacer" />
                  <table>
                    {payResultHtml}
                    {tuBolsilloResultHtml}
                    {cashResultHtml}
                    {braintreeResultHtml}
                    {cll2ResultHtml}
                    {couponPaymentResultHtml}
                  </table>
                  <div className="spacer mb-0" />
                </div>
              </div>
            </div>
            <div className="d-flex justfy-content-center fixed-block mt-20">
              <button className="button--primary button--max button--plus"
                style={{ height: "54px", fontSize: "1.2em", marginBottom: "15px" }} onClick={() => done()}>
                {translation.Actions.accept}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
