/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { useDispatch } from "react-redux";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";
import useTranslations from "../../translations";

export function TermsAndConditions() {
  const dispatch = useDispatch();
  const translation = useTranslations();

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(setLoadingMaskStatus(false));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wrap container-fluid" style={{ textAlign: 'left' }}>
      <h1 style={{ textAlign: 'center' }}>{translation.Terms.headline}</h1>
      <p>{translation.Terms.p1_1}</p>
      <p>{translation.Terms.p1_2}</p>
      <p>{translation.Terms.p1_3}</p>
      <p>{translation.Terms.p1_4}</p>
      <p>{translation.Terms.p1_5}</p>
      <p>{translation.Terms.p1_6}</p>
      <h2>{translation.Terms.sub2}</h2>
      <p>{translation.Terms.p2_1}</p>

      <h2>{translation.Terms.sub3}</h2>
      <p>{translation.Terms.p3_1}</p>
      <p>{translation.Terms.p3_2}</p>
      <p>{translation.Terms.p3_3}</p>

      <h2>{translation.Terms.sub4}</h2>
      <p>{translation.Terms.p4_1}</p>
      <p>{translation.Terms.p4_2}</p>
      <p>{translation.Terms.p4_3}</p>

      <h2>{translation.Terms.sub5}</h2>
      <p>{translation.Terms.p5_1}</p>
      <p>{translation.Terms.p5_2}</p>
      <p>{translation.Terms.p5_3}</p>
      <p>{translation.Terms.p5_4}</p>
      <p>{translation.Terms.p5_5}</p>

      <h2>{translation.Terms.sub6}</h2>
      <p>{translation.Terms.p6_1}</p>

      <h2>{translation.Terms.sub7}</h2>
      <p>{translation.Terms.p7_1}</p>

      <h2>{translation.Terms.sub8} </h2>
      <p>{translation.Terms.p8_1}</p>
      <p>{translation.Terms.p8_2}</p>
    </div>
  );
}