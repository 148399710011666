import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add, Clear, Edit } from "@material-ui/icons";
import Skeleton from '@material-ui/lab/Skeleton';
import { Dialog, DialogContent, DialogTitle, makeStyles, Slide, Typography } from "@material-ui/core";

import "../../css/PaymentProcessStyle.scss";
import "./css/StepSelectPaymentMethodStyle.scss";

import { showAlert } from "modules/alert/store/alert.actions";
import { TU_BOLSILLO, CASH, BAMBORA } from "../../paymentType";
import PayMethodRow from "./PayMethodRow";
import { selectTuBolsilloCredit, selectErrorAddPaymentMethod, selectCll2PaymentMethods } from "../../store/payment.reducers";
import {
  clearErrorAddPaymentMethod,
} from '../../store/braintree/braintree.actions';
import UpsertCreditCard from "../../../Pay/components/UpsertCreditCard";
import { selectCartRestaurant } from "../../../cart/store/cart.reducers";
import { doDeleteCll2PaymentMethod } from "modules/payment/store/payment.actions";

import PdfIcon from "component/Icon/PdfIcon";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { selectBrowserOrder } from "modules/Checkout/store/checkout.reducers";
import { storeBrowserOrder } from "modules/Checkout/store/checkout.actions";
import { TASA_PESO } from "../../../../constants";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  dialog: {
    padding: '1em 2em 3em',
  },
  title: {
    textAlign: 'center',
    marginBottom: '1em'
  },
  subtitle: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepSelectPaymentMethod({ btClient, history, translation, cartTotal, handleBack, handleNext, handleAddAndNext }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const restaurant = useSelector(selectCartRestaurant);
  const browserOrder = useSelector(selectBrowserOrder);
  const tubolsilloCredit = useSelector(selectTuBolsilloCredit);
  const paymentMethods = useSelector(selectCll2PaymentMethods);
  const errorAddPaymentMethod = useSelector(selectErrorAddPaymentMethod);

  const [editMethods, setEditMethods] = useState(false);
  const [showNewMethodDialog, setShowNewMethodDialog] = useState(false);
  const notEnoughCreditInTuBolsillo = tubolsilloCredit < cartTotal;
  const [method, setMethod] = useState(false);
  const [disablePayButton, setDisablePayButton] = useState(false);
  const [allowCashPayment, setAllowCashPayment] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // dispatch(sendToCheckAvailability());
    // 
    // if (!localStorage.getItem('checkout')) {
    //   history.push({ pathname: "/prv/checkout" });
    // }
    if (!browserOrder || !browserOrder.deliveryOrderType) {
      history.push({ pathname: "/prv/checkout" });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restaurant && restaurant.restaurantInfoById) {
      const info = restaurant.restaurantInfoById;
      setAllowCashPayment(info.allowCashPayment || false);
    }
    // eslint-disable-next-line
  }, [restaurant]);

  const addCreditCardAndPay = (newMethod) => handleAddAndNext(BAMBORA, null, newMethod);

  // const handlePaypal = async () => {
  // try {
  //   const payload = await ppClient.tokenize({flow: 'vault'});
  //   dispatch(addCardToVault(btClient, payload.nonce, (email, paymentMethods) => {
  //     setShowNewMethodDialog(false);
  //     handlePayment(`paypal-${email}`, paymentMethods);
  //   }));
  // } catch (e) {
  //   switch (e.code) {
  //     case 'PAYPAL_POPUP_CLOSED':
  //       console.error('Customer closed PayPal popup.');
  //       break;
  //     case 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED':
  //       dispatch(notifyError('Error durante el proceso de tokenización de su cuenta Paypal'))
  //       console.error('PayPal tokenization failed. See details:', e.details);
  //       break;
  //     case 'PAYPAL_FLOW_FAILED':
  //       dispatch(notifyError('Error durante el flujo de cuenta Paypal'))
  //       console.error('Unable to initialize PayPal flow. Are your options correct?', e.details);
  //       break;
  //     default:
  //       dispatch(notifyError(`Error Paypal: ${e.details}`))
  //       console.error('Error!', e);
  //   }
  // }
  // };

  // icon hover in back btn
  const [hovering, setHovering] = useState(false);
  const [backIcon, setBackIcon] = useState('system/arrow_back--blue');

  useEffect(() => setBackIcon(`system/arrow_back--blue${hovering ? '_hover' : ''}`), [hovering])

  // const categoryInCart = useSelector(selectCategoryInCart);
  const editPurchase = () => {
    // if (categoryInCart && categoryInCart.slug) {
    // dispatch(setLoadingMaskStatus(true));
    // history.push({ pathname: `/${restaurant.slug}` });
    // }
    dispatch(setLoadingMaskStatus(true));
    dispatch(storeBrowserOrder(null));
    history.push({ pathname: `/${restaurant.slug}` });
  };

  const handleOpenAddPaymentMethod = () => {
    dispatch(clearErrorAddPaymentMethod());
    setShowNewMethodDialog(true);
  }

  const handlePayment = (method, paymentMethods) => {
    setDisablePayButton(true);
    if ([CASH, TU_BOLSILLO].includes(method)) {
      handleNext(method, null, null);
    } else {
      handleNext(BAMBORA, paymentMethods.find(({ id }) => method === id), null);
    }
  };

  const handleDeleteMethod = (id) => {
    dispatch(showAlert(translation.Payment.alertDeletePaymentMethod, '', '', '', () => {
      dispatch(doDeleteCll2PaymentMethod(id));
      setEditMethods(false)
    }));
  }
  const getTotalToPay = () => {
    if (method === CASH) {
      return `${parseFloat(cartTotal * TASA_PESO).toFixed(2)} CUP`;
    }
    return cartTotal ? `$${parseFloat(cartTotal).toFixed(2)}` : `${cartTotal}`
  }

  return (
    <div>
      <div className="step-delivery__actions mb-20">
        <button className="button--primary button--primary-inverted-rounded back-btn"
          onClick={editPurchase}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          <PdfIcon icon={backIcon} ext="svg" width="20px" />
          <span>{translation.Restaurant.back}</span>
        </button>
      </div>
      <Typography variant="h5" component="h2" className={classes.title} style={{ fontWeight: '700' }}>
        {translation.Payment.titleSelectPaymentMethod}
      </Typography>
      <div className="step-delivery__actions" style={{ justifyContent: 'flex-end' }}>
        {paymentMethods ?
          <button className="button--primary button--primary-inverted-rounded" onClick={handleOpenAddPaymentMethod} style={{ marginRight: '10px' }}>
            <Add style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
            <span style={{ textTransform: 'initial' }}>
              {translation.Payment.addPaymentMethod}
            </span>
          </button>
          : <Skeleton variant="rect" width={110} height={42} animation="wave" className="creditcard-skeleton" style={{ borderRadius: '21px' }} />}
        {paymentMethods && paymentMethods.length > 0 ?
          <button className="button--primary button--primary-inverted-rounded" onClick={() => setEditMethods(!editMethods)}>
            <Edit style={{ fill: '#1e4acc', width: '20px', height: '20px' }} component="svg" />
            <span style={{ textTransform: 'initial' }}>
              {translation.Payment[editMethods ? 'cancelEdit' : 'edit']}
            </span>
          </button> : null}
      </div>

      <div className="step-delivery__sections">
        {paymentMethods ? <div className="payment-methods">
          <PayMethodRow
            id={TU_BOLSILLO}
            icon="tubolsillo"
            ifCheckFunction={(id) => method === id}
            text={`${translation.Payment.availableCredit} ($${parseFloat(tubolsilloCredit).toFixed(2)})`}
            handleClick={() => !notEnoughCreditInTuBolsillo ?
              setMethod(
                notEnoughCreditInTuBolsillo ? CASH : TU_BOLSILLO
              ) : null
            }
            disabled={notEnoughCreditInTuBolsillo || editMethods}
            styleClass={notEnoughCreditInTuBolsillo || editMethods ? { cursor: 'default' } : {}}
            edit={editMethods}
          />

          {paymentMethods?.map(({ id, lastFour, cardType, nameOnCard, paymentMethodType }) => {
            const icon = paymentMethodType === 'CARD' ? cardType.replace(/\s/g, "") : `paypal`;
            const text = paymentMethodType === 'CARD' ? `${translation.Payment.creditCardEndWith} ${lastFour}` : nameOnCard;
            return <PayMethodRow
              id={id}
              key={id}
              icon={icon}
              ifCheckFunction={(id) => editMethods || method === id}
              text={text}
              handleClick={() => setMethod(id)}
              handleDelete={() => handleDeleteMethod(id)}
              styleClass={editMethods ? { cursor: 'default' } : {}}
              edit={editMethods}
            />
          })}

          {allowCashPayment ?
            <PayMethodRow
              id={CASH}
              disabled={editMethods}
              handleClick={() => setMethod(CASH)}
              ifCheckFunction={(id) => !editMethods && method === id}
              icon="cash"
              text={`${translation.Payment.cashPaymentOptionLabel} (${parseFloat(cartTotal * TASA_PESO).toFixed(2)} CUP)`}
              styleClass={editMethods ? { cursor: 'default' } : {}}
              edit={editMethods}
            /> : null}
        </div>
          : <div className="payment-methods">
            <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
            <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
            <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
          </div>
        }
      </div>

      <Dialog disableEscapeKeyDown maxWidth="xs" TransitionComponent={Transition}
        fullWidth={true} open={showNewMethodDialog} onClose={() => setShowNewMethodDialog(false)}>
        <DialogTitle className="">
          {translation.Payment.newPaymentMethod}
          <button className="resend-pin-modal__header__close button--transparent" onClick={() => setShowNewMethodDialog(false)}>
            <Clear component="svg" />
          </button>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <UpsertCreditCard handleSubmit={addCreditCardAndPay} feedbackError={errorAddPaymentMethod} />
        </DialogContent>
      </Dialog>

      <div className={`button-wrapper button-wrapper--flex ${paymentMethods?.length > (window.innerWidth < 768 ? 4 : 8) ? 'button-wrapper--sticky' : ''}`}>
        <button
          className="button--outline button--large max-w-260"
          onClick={handleBack}
        >
          {translation.Payment.goBack}
        </button>
        <button
          className="button--primary button--large max-w-260"
          disabled={!method || disablePayButton}
          onClick={() => handlePayment(method, paymentMethods)}
        >
          {translation.Payment.pay} {getTotalToPay()}
        </button>
      </div>
    </div>
  );
}
